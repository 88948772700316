/**
 * Created by amine on 29/07/2019 V2.0
 */
(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");

    class PatientSummaryDialogCtrl {
        constructor($mdDialog, $q, patientService) {
            this.dialog = $mdDialog;
            this.patientService = patientService;

            this.patient = this.patient || null;
            this.localPromise = null;
            this.mainPromise = $q.defer().promise;
            this.downloading = false;
            this.initQuery = {
                start_date: _.get(this.initQuery, "start"),
                end_date: _.get(this.initQuery, "end"),
                activated: {
                    medical_file: true,
                    measurements: true,
                    visits: true,
                    consultations: true,
                    medical_prescriptions: true,
                    biology_prescriptions: true,
                    text_prescriptions: true,
                    procedure_prescriptions: true,
                    exams: true,
                    medical_certificates: true,
                    empty_list_message: true
                }
            };
            this.filterSubject = new BehaviorSubject(this.initQuery);
        }

        static get $inject() {
            return ["$mdDialog", "$q", "patientService"];
        }

        $onInit() {

        }

        exportDocument() {
            this.downloading = true;

            this.localPromise = this.patientService.downloadPatientSummary(this.patient.id, this.filterSubject.getValue())
                .then(() => this.downloading = false, _.noop);
        }

        filterData() {
            const dialog = require("../dialogs/summary-filters-dialog");

            this.dialog
                .show(_.assign(dialog, {locals: {query: _.cloneDeep(this.filterSubject.getValue())}}))
                .then(query => this.filterSubject.next(query), _.noop);
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: PatientSummaryDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        template: require("stand-alone/patient-summary/views/summary-dialog.tpl.html"),
    };

})();
