/**
 * Created by Hp on 10/07/2017.
 */
/**
 * Created by Hp on 16/05/2017.
 */
(function () {

    'use strict';

    var stockDocActions = {
        controller: stockDocActionsCtrl,
        controllerAs: "vm",
        bindings: {
            handleRemove: "=remove",
            prePrint: "=prePrint",
            handleSave: "=handleSave",
            validate: "=validate",
            invalidate: "=invalidate",
            stockDocument: "=ngModel",
            documentType: "=documentType",
        },
        template: tpl,
    };

    stockDocActionsCtrl.$inject = ["$element", "$attrs", "$translate", "$scope", "stockService", "$mdDialog"];

    function stockDocActionsCtrl($element, attrs, $translate, $scope, stockService, $mdDialog) {

        var vm = this;
        vm.$onInit = init;
        vm.cancel = goBack;
        vm.handleSubmit = handleSubmit;
        vm.removeDocument = removeDocument;
        vm.handleInvalidate = handleInvalidate;
        vm.handleValidate = handleValidate;
        var documentCls = !_.isUndefined(attrs['documentCls']) ? attrs['documentCls'] : null;
        var docPackage = !_.isUndefined(attrs['package']) ? attrs['package'] : null;

        function init() {
        }

        function handleSubmit(with_exit) {
            vm.handleSave['action'](with_exit);
        }

        function removeDocument() {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_warning'))
                    .ariaLabel('delete_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                if (!vm.prevent_remove) {
                    vm.prevent_remove = true;
                    vm.promise = stockService.deleteStockObject(vm.stockDocument.id, vm.handleRemove['viewName']).then(success);
                }
            });
            function success(data) {
                goBack();
            }
        }

        function goBack() {
            window.history.back();
        }

        function handleInvalidate() {
            if (!vm.prevent_invalidate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('invalidate_warning'))
                        .ariaLabel('invalidate_warning')
                        .ok('Ok')
                        .cancel('No')
                ).then(function () {
                    startInvalidate();
                });
            }

        }

        function startInvalidate() {
            vm.prevent_invalidate = true;
            vm.promise = stockService.allowDocInvalidate(vm.stockDocument.id, documentCls, vm.documentType, docPackage).then(allowCallback);
            function allowCallback(data) {
                switch (data.allow) {
                    case false:
                        $mdDialog.show(
                            $mdDialog.alert()
                                .parent($(document.body))
                                .clickOutsideToClose(true)
                                .title('')
                                .textContent($translate.instant('document_invalidate_impossible'))
                                .ariaLabel('document_invalidate_impossible')
                                .ok('Ok')
                        );
                        vm.prevent_invalidate = false;
                        break;
                    case true:
                        if (data.warning) {
                            $mdDialog.show(
                                $mdDialog.confirm()
                                    .parent($(document.body))
                                    .clickOutsideToClose(true)
                                    .title('')
                                    .textContent($translate.instant('warning_qte_under_min'))
                                    .ariaLabel('warning_qte_under_min')
                                    .ok('Ok')
                                    .cancel('No')
                            ).then(function () {
                                vm.promise = vm.invalidate.action().then(refresh);
                            });
                        }
                        else   vm.promise = vm.invalidate.action().then(refresh);
                        break;
                }
            }
        }

        function refresh(data) {
            vm.stockDocument = data;
            vm.prevent_invalidate = false;
            vm.prevent_validate = false;
            if(_.get(vm.validate, 'saveSuccess')){
                vm.validate.saveSuccess(data);
            }
        }

        function handleValidate() {
            if (!vm.prevent_validate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('validate_warning'))
                        .ariaLabel('validate_warning')
                        .ok('Ok')
                        .cancel('No')
                ).then(function () {
                    vm.prevent_validate = true;
                    vm.prePrint().then(success);
                });
            }
            function success(data) {
                vm.promise = vm.validate.action(data).then(refresh);
            }
        }
    }

    tpl.$inject = ["$attrs", "$element"];
    function tpl(attrs, $elem) {
        var printGroup = 'group="' + attrs['printGroup'] + '"';
        return ['<div class="mn-module-actions">',
            '<md-button class="mn-square-icon-button md-raised md-warn" aria-label="delete"' +
            'ng-click="vm.removeDocument()"' +
            'ng-if="vm.handleRemove.if">',
            '<md-icon md-font-icon="mdi-delete" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '<mn-print ng-if="vm.stockDocument.id" ' + printGroup + ' model="vm.stockDocument.id" pre-print="vm.prePrint()">',
            '</mn-print>',
            '<span flex></span>',
            '<md-button class="md-raised md-warn flex-right" aria-label="cancel_model" ng-click="vm.cancel()"' +
            '>',//ng-if="!vm.stockDocument.is_valid"
            '<span translate-once="cancel"></span>',
            '</md-button>',
            '<md-button class="md-raised md-primary" aria-label="invalidate" block-movement ng-if="vm.invalidate.if" ' +
            'ng-click="vm.handleInvalidate()" ng-disabled="vm.invalidate._disable">',
            '<md-icon md-font-icon="mdi-lock-open-outline" md-font-set="mdi">',
            '</md-icon>',
            '<span translate-once="invalidate"></span>',
            '</md-button>',
            '<md-button class="md-raised  mn-button-icon mn-success" block-movement aria-label="validate" ' +
            'ng-if="vm.validate.if"' +
            'ng-disabled="vm.validate._disable"' +
            'ng-click="vm.handleValidate()">' +
            '<md-icon md-font-icon="mdi-lock-outline" md-font-set="mdi">',
            '</md-icon>',
            '<span translate-once="validate">',
            '</span>',
            '</md-button>',
            '<md-button class="md-raised md-primary" aria-label="save" ng-click="vm.handleSubmit(true)" ng-if="vm.handleSave.if"' +
            'ng-disabled="vm.handleSave._disable">',
            '<span translate-once="save_and_quit">',
            '</span>',
            '</md-button>',
            '<md-button class="md-raised md-primary mn-button-icon" aria-label="save" block-movement type="submit" ng-click="vm.handleSubmit()"' +
            'ng-if="vm.handleSave.if"' +
            'ng-disabled="vm.handleSave._disable">',
            '<span translate-once="save"></span>',
            '</md-button>',
            '</div>'].join('');
    }

    module.exports = stockDocActions;
})();