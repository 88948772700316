/**
 * Created by amine on 26/03/2018.
 */
(function () {
    'use strict';

    class CustomVariableService {
        constructor($q, mnWebSocket, $http, $mdDialog) {
            this.$q = $q;
            this.mnWebSocket = mnWebSocket;
            this.$http = $http;
            this.$mdDialog = $mdDialog;
        }

        static get $inject() {
            return ["$q", "mnWebSocket", "$http", "$mdDialog"];
        }

        saveVariable(item) {
            let url = "/api/editor-custom-var/";
            let deferred = this.$q.defer();

            if (_.isNil(item.key)) item.key = `custom::${item.slug}`;

            if (!_.isNil(item.id)) url += `${item.id}/`;
            this.$http.post(url, item).then(() => deferred.resolve(response.data), deferred.reject)

            return deferred.promise;
        }
    }

    module.exports = CustomVariableService;
})();