/**
 * Created by Hp on 23/03/2017.
 */
(function () {

    'use strict';

    var mnArticle = {
        controller: MnArticleController,
        controllerAs: "vm",
        bindings: {
            article: "=ngModel",
            mnLabel: "=mnLabel",
            disabled: "=mnDisabled",
            onlyList: '=mnOnlyList',
            parentModel: "=parentModel",
            change: "<?mnChange"
        },
        template: tpl,
    };

    MnArticleController.$inject = ["$mdDialog", "lotService"];

    function MnArticleController($mdDialog, lotService) {

        var vm = this;
        vm.$onInit = init;
        vm.cancel = cancel;
        vm.searchArticle = searchArticle;
        vm.selectRow = selectRow;
        vm.enterCodes = enterCodes;
        vm.sumQte = sumQte;
        vm.handleChange = handleChange;

        function init() {
        }

        function searchArticle(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {is_deleted: {$ne: true}},
                    selectable: false,
                    selectRow: vm.selectRow
                }
            })).then(selectArticleCallBack);

            function selectArticleCallBack(data) {
                vm.article = data;
                vm.handleChange(vm.article);
            }
        }

        function selectRow(article) {

            $mdDialog.hide(article);
        }

        function enterCodes(ev) {
            if (vm.article['has_serial_number']) {
                $mdDialog.show(_.assign(require('stock/dialogs/serials-dialog.js'), {
                    locals: {
                        serials: _.cloneDeep(!_.isNil(vm.parentModel.serial_numbers) ? vm.parentModel.serial_numbers : []),
                        limit: vm.parentModel.qte,
                        mvt_type: vm.parentModel.type + '_SOURCE',
                        article: vm.parentModel.article,
                        lineWarehouse: vm.parentModel.warehouse,
                        line: vm.parentModel,
                    }
                })).then(function (data) {
                    vm.parentModel.serial_numbers = data;
                });
            }
            if (vm.article['has_lot_number']) {
                $mdDialog.show(_.assign(require('stock/dialogs/lots-dialog.js'), {
                    locals: {
                        lots: _.cloneDeep(!_.isNil(vm.parentModel.lot_numbers) ? vm.parentModel.lot_numbers : []),
                        limit: vm.parentModel.qte,
                        mvt_type: vm.parentModel.type + '_SOURCE',
                        mvt_code: vm.parentModel.number,
                        mvtDocument: vm.parentModel,
                        documentCls: 'Transformation',
                        line: vm.parentModel
                    }
                })).then(function (data) {
                    vm.parentModel.lot_numbers = data;
                });
            }

        }

        function cancel() {
            vm.article = null;

            vm.handleChange(vm.article);
        }

        function sumQte() {
            if (vm.article['has_serial_number']) {
                return vm.parentModel.serial_numbers.length;
            }
            if (vm.article['has_lot_number']) {
                lotService.lotsQteSum(vm.parentModel, vm.parentModel.type + '_SOURCE', _.cloneDeep(!_.isNil(vm.parentModel.lot_numbers) ? vm.parentModel.lot_numbers : []), vm.parentModel.number, 'Transformation')
            }
        }

        function handleChange() {
            _.isFunction(vm.change) ? vm.change(vm.article) : _.noop;
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        var required = $elem.is("[required]") ? " required " : "";
        var disabled = !_.isUndefined(attrs['mnDisabled']) ? ' ng-disabled="vm.disabled" ' : "";
        var is_disabled = $elem.is('[disabled]') ? " disabled " : "";
        var label = attrs['mnLabel'];
        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");
        return [
            '<md-input-container>',
            '<label translate-once="' + label + '"></label>',
            '<input ng-model="vm.article.code" disabled >',
            '<div class="mn-option-buttons flex-nogrow layout-row">',
            '<md-button class="md-icon-button" ng-click="vm.searchArticle($event)" aria-label="find article"' + required + ' ' + disabled + ' ' + is_disabled + '>',
            '<md-icon md-font-icon="mdi-magnify" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '<md-button  ng-class="{ \'md-primary\' :vm.parentModel.qte==vm.sumQte(),  \'md-warn\' :vm.parentModel.qte!=vm.sumQte()}"   ng-if="!vm.onlyList && vm.article && !vm.disabled && (vm.article.has_serial_number || vm.article.has_lot_number ) " class="md-icon-button" ng-click="vm.enterCodes($event)" aria-label="serial or lot article"' + required + ' ' + disabled + ' ' + is_disabled + '>',
            '<md-icon   md-font-icon="mdi-barcode" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '<md-button class="md-icon-button" ng-click="vm.cancel()" ng-if="vm.article && !vm.disabled">',
            '<md-icon  md-font-icon="mdi-close" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '</div>',
            '</md-input-container>',
        ].join('');
    }

    module.exports = mnArticle;

})();