/**
 * Created by Hp on 16/05/2017.
 */
(function () {

    'use strict';

    var linesHeader = {
        controller: LinesHeaderCtrl,
        controllerAs: "vm",
        bindings: {
            stockDocument: "=ngModel",
            documentType: "=documentType",
            readOnly: "=readOnly"
        },
        template: require('stock/views/document-lines-header.tpl.html'),
    };

    LinesHeaderCtrl.$inject = ["$mdDialog", "$attrs", "purchaseService", "serialService", "lotService", "$element", "warehouseService", "saleService"];

    function LinesHeaderCtrl($mdDialog, attrs, purchaseService, serialService, lotService, $element, warehouseService, saleService) {

        var vm = this;
        vm.$onInit = init;
        vm.selectArticle = selectArticle;
        vm.addEmptyLine = addEmptyLine;
        vm.selectCommand = selectCommand;
        vm.selectDeposition = selectDeposition;
        vm.selectReceipt = selectReceipt;
        vm.selectPriceRequest = selectPriceRequest;
        vm.selectInvoice = selectInvoice;
        vm.selectReturn = selectReturn;
        vm.selectConsignment = selectConsignment;
        vm.addCharge = addCharge;

        var mnModel = !_.isUndefined(attrs['mnModel']) ? attrs['mnModel'] : "";
        var defaultWarehouse = null;

        function init() {
            $element.addClass('layout-row');
            vm.promise = warehouseService.getDefault().then(function (data) {
                defaultWarehouse = data;
            });
        }

        function selectArticle(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: vm.documentType === 'OUT' ? {
                        interdict_sale: {$ne: true},
                        is_deleted: {$ne: true}
                    } : _.assign({interdict_purchase: {$ne: true}, is_deleted: {$ne: true}})
                }
                // removed in the  case of first inv
                // , mnModel === 'inventory' && vm.stockDocument.warehouse  ? {'article_store.warehouse':{'$eq':vm.stockDocument.warehouse.id}}:{}
            })).then(selectArticleCallBack);
        }

        function selectArticleCallBack(data) {
            switch (vm.documentType) {
                case 'OUT':
                    _.remove(data, {store_qte: 0});
                    break;
                // not allow select of article with null qte in the source store
                case 'WarehouseExchange':
                    _.remove(data, function (item) {
                        return _.isEmpty(_.filter(item['article_store'], function (store) {
                            return store.warehouse.id == vm.stockDocument.warehouse.id && store['store_qte'] > 0;
                        }))
                    });
                    break;
            }
            _.forEach(data, function (article) {
                refreshDocumentLines(article, "select");
            })
        }

        function refreshDocumentLines(_object, doc_source) {
            var line_not_exist = _.isEmpty(_.filter(vm.stockDocument.lines, function (line) {
                return line.code == _object.code || (!line.is_comment && line.article.id == _object.id);
            }));
            if (line_not_exist) {
                var new_line = {};
                if (mnModel == 'inventory') {
                    new_line = refreshInventoryLines(_object, doc_source);
                }
                else {
                    if (_object['store_qte'] <= 0 && vm.documentType == 'OUT') new_line = null;
                    else {
                        new_line = {
                            code: _object.code,
                            article: _.cloneDeep(_object),
                            qte: 1,
                            unit_price: vm.documentType == 'OUT' ? _object.sale_price : _object.last_purchase_price,
                            short_desc: _object.short_desc,
                            warehouse: vm.stockDocument.warehouse || defaultWarehouse,
                            tva: vm.documentType == 'OUT' ? _object.sale_tva : _object.purchase_tva,
                            is_comment: false,
                        };
                    }
                }
                if (!_.isNil(new_line)) vm.stockDocument.lines.push(new_line);
            }
        }

        function refreshInventoryLines(_object, doc_source) {
            var new_line = {
                code: _object.code,
                article: _.cloneDeep(_object),
                short_desc: _object.short_desc,
                warehouse: vm.stockDocument.warehouse,
                qte: 0,
                pmp_price: _object.pmp,
                is_comment: false
            };
            if (doc_source == 'select') {
                if (_object['has_serial_number']) serialService.getActiveSerial(_object.id).then(function (data) {
                    new_line.serial_numbers = _.filter(data, function (item) {
                        return item.warehouse.id == new_line.warehouse.id;
                    });
                });

                if (_object['has_lot_number']) lotService.getActiveLot({
                    article_id: _object.id,
                    store_id: new_line.warehouse.id
                }).then(function (data) {
                    new_line.lot_numbers = data/**_.filter(data, function (item) {
                        return item.warehouse.id == new_line.warehouse.id;
                    });**/
                }.bind(new_line));
            }
            return new_line;
        }

        function addEmptyLine() {
            vm.stockDocument.lines.push({is_comment: true});
        }

        function selectCommand(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-clipboard-text",
                    modalTitle: "orders_list",
                    modalModel: "stock.PurchaseForm",
                    search: {is_deleted: {$ne: true}, is_valid: {$ne: false}},
                }
            })).then(selectOrderCallBack);
        }

        function selectDeposition(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-clipboard-arrow-down",
                    modalTitle: "depositions_list",
                    modalModel: "stock.GoodDeposition",
                    search: {is_deleted: {$ne: true}, is_valid: {$ne: false}},
                }
            })).then(selectDepositionCallBack);
        }

        function selectOrderCallBack(data) {
            _.forEach(data, function (order) {
                vm.promise = purchaseService.getOrderNonReceiptedLines(order.number).then(refreshFromOrderLines);
            });
        }

        function selectDepositionCallBack(data) {
            if (vm.documentType == 'RETURN')
                _.forEach(data, function (deposition) {
                    vm.promise = purchaseService.getDepositionNonReceiptedLines(deposition.number).then(refreshFromDepositionLines);
                })
            else _.forEach(data, function (deposition) {
                vm.promise = purchaseService.getDepositionNonReturnedLines(deposition.number).then(refreshFromDepositionLines);
            })
        }

        function refreshFromOrderLines(data) {
            refreshFromDocLines(data, "PurchaseForm", false);
        }

        function refreshFromDepositionLines(data) {
            refreshFromDocLines(data, "GoodDeposition", true);
        }

        function refreshFromDocLines(data, src_doc_cls, is_deposition_line) {
            var doc_number = data[1];
            _.forEach(data[0], function (item) {
                var line_not_exist = _.isEmpty(_.filter(vm.stockDocument.lines, function (line) {
                    return !_.isNil(line.article) && line.code == item.code && line.article.id == item.article.id;
                }));

                if (line_not_exist) {
                    if (item['lot_numbers'].length > 0 && is_deposition_line) item.lot_numbers = [];
                    vm.stockDocument.lines.push(_.assign(item, {
                        src_doc_number: doc_number,
                        src_doc_cls: src_doc_cls
                    }));
                }
            })
        }

        function selectReceipt(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-receipt",
                    modalTitle: "receipts_list",
                    modalModel: "stock.PurchaseReceipt",
                    search: {is_deleted: {$ne: true}, is_valid: {$ne: false}},
                }
            })).then(selectReceiptCallBack);
        }

        function selectReceiptCallBack(data) {
            _.forEach(data, function (receipt) {
                vm.promise = purchaseService.getReceiptNonInvoicedLines(receipt.number).then(function (data) {
                    refreshFromDocLines(data, "PurchaseReceipt", false);
                });
            });
        }

        function selectPriceRequest(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-file-document",
                    modalTitle: "price_request_list",
                    modalModel: "stock.PriceRequest",
                    search: {is_deleted: {$ne: true}, is_valid: {$ne: false}},
                }
            })).then(function (data) {
                _.forEach(data, function (price_request) {
                    refreshFromRequest(price_request, "PriceRequest", false);
                });
            });

            function refreshFromRequest(price_request, src_doc_cls) {
                _.forEach(price_request.lines, function (item) {
                    var line_not_exist = _.isEmpty(_.filter(vm.stockDocument.lines, function (line) {
                        return !_.isNil(line.article) && line.code == item.code && line.article.id == item.article.id;
                    }));

                    if (line_not_exist) {
                        vm.stockDocument.lines.push(_.assign(item, {
                            src_doc_number: price_request.number,
                            src_doc_cls: src_doc_cls
                        }));
                    }
                })
            }
        }

        function selectInvoice(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-receipt",
                    modalTitle: "invoice_list",
                    modalModel: "stock.PurchaseInvoice",
                    search: {is_deleted: {$ne: true}, is_valid: {$ne: false}, provider: vm.stockDocument.provider.id},
                }
            })).then(function (data) {
                _.forEach(data, function (invoice) {
                    refreshFromRequest(invoice, "PurchaseInvoice", false);
                });
            });

            function refreshFromRequest(invoice, src_doc_cls) {
                _.forEach(invoice.lines, function (item) {
                    const line_not_exist = _.isEmpty(_.filter(vm.stockDocument.lines, function (line) {
                        return !_.isNil(line.article) && line.code === item.code && line.article.id === item.article.id;
                    }));

                    if (line_not_exist) {
                        vm.stockDocument.lines.push(_.assign(item, {
                            src_doc_number: invoice.number,
                            src_doc_cls: src_doc_cls
                        }));
                    }
                })
            }
        }

        function selectReturn(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-clipboard-arrow-left",
                    modalTitle: "returns_list",
                    modalModel: "stock.GoodReturn",
                    search: {is_deleted: {$ne: true}, is_valid: {$ne: false}, provider: vm.stockDocument.provider.id},
                }
            })).then(function (data) {
                _.forEach(data, function (retrn) {
                    refreshFromRequest(retrn, "GoodReturn", false);
                });
            });

            function refreshFromRequest(retrn, src_doc_cls) {
                _.forEach(retrn.lines, function (item) {
                    const line_not_exist = _.isEmpty(_.filter(vm.stockDocument.lines, function (line) {
                        return !_.isNil(line.article) && line.code === item.code && line.article.id === item.article.id;
                    }));
                    if (line_not_exist) {
                        vm.stockDocument.lines.push(_.assign(item, {
                            src_doc_number: retrn.number,
                            src_doc_cls: src_doc_cls
                        }));
                    }
                })
            }
        }

        function selectConsignment(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-file-export",
                    modalTitle: "consignment_list",
                    modalModel: "stock.Consignment",
                    search: {is_deleted: {$ne: true}, is_valid: {$ne: false}},
                }
            })).then(selectConsignmentCallBack);
            // function refreshFromConsignment(c, src_doc_cls) {
            //     _.forEach(c.lines, function (item) {
            //         var line_not_exist = _.isEmpty(_.filter(vm.stockDocument.lines, function (line) {
            //             return !_.isNil(line.article) && line.code == item.code && line.article.id == item.article.id;
            //         }));
            //         if (line_not_exist) {
            //             vm.stockDocument.lines.push(_.assignIn(item, {
            //                 src_doc_number: c.number,
            //                 src_doc_cls: src_doc_cls
            //             }));
            //         }
            //     })
            // }
        }

        function selectConsignmentCallBack(data) {
            _.forEach(data, function (consignment) {
                vm.promise = saleService.getConsignmentNonReturnedLines(consignment.number)
                    .then(function refreshFromConsignmentLines(data) {
                        refreshFromDocLines(data, "Consignment", true);
                    });
            });
        }

        function addCharge(ev) {
            $mdDialog.show(_.assign({}, require('stock/dialogs/charge-dialog'), {
                targetEvent: ev,
                locals: {
                    charges: vm.stockDocument.charges || []
                }
            })).then((data) => {
                if(data){
                    vm.stockDocument.charges=data;
                }
            })
        }

    }

    module.exports = linesHeader;
})();