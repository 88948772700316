(function () {
    "use strict";


    const {Subject} = require("rxjs");

    class SpeechRecognition {
        constructor() {
            this.isStopped = true;
            if ('webkitSpeechRecognition' in window) {
                this.recognition = new webkitSpeechRecognition();

                this.recognition.lang = "fr-FR";
                this.recognition.continuous = false;
                this.recognition.interimResults = true;

                this.recognition['onresult'] = (event) => {
                    this.subject.next(event);
                }

                this.recognition['onend'] = () =>  {
                    if (!this.isStopped) this.recognition.start();
                }
            }
            else this.recognition = false;
        }

        start() {
            if (this.isStopped) this.recognition.start();

            this.isStopped = false;
            this.subject = new Subject();
        }

        stop() {
            this.isStopped = true;
            this.recognition.stop();
            this.subject.complete();
        }
    }

    module.exports = SpeechRecognition;

})();
