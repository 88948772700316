/**
 * Created by amine on 20/05/2017.
 */
(function () {

    'use strict';

    module.exports = PeriodicActivityCtrl;

    PeriodicActivityCtrl.$inject = ["statesService", "system", "$translate", "$element"];

    function PeriodicActivityCtrl(statesService, system, $translate, $element) {
        let vm = this;
        const dateFormat = system['date_format'].js;

        vm.$onInit = init;
        vm.startChanged = startChanged;

        function init() {
            vm.filter = {
                is_deleted: {"$ne": true},
                delivery: {
                    start: moment().format(dateFormat),
                    end: moment().add(1, "month").format(dateFormat)
                }
            };
        }

        function startChanged() {
            vm.filter.delivery.end = moment(vm.filter.delivery.start, dateFormat).add(1, "M").format(dateFormat);
            vm.refresh()
        }

    }

})();