/**
 * Created by BETALOS on 16/03/2016.
 */
(function () {

    'use strict';

    module.exports = listService;

    listService.$inject = ["mnWebSocket", "$mdDialog", "$translate", "$http", "$q"];

    function listService(mnWebSocket, $mdDialog, $translate, $http, $q) {
        var self = this;

        self.get = get;
        self.list = list;
        self.handleList = handleList;
        self.reorderList = reorderList;
        self.autocompleteListSearch = autocompleteListSearch;


        self.removeItem = removeItem;

        function get(item) {
            var id = _.get(item, "id", item);
            return mnWebSocket.call("shared.List.get", {id: id})
        }

        function list(model, hidden) {
            return mnWebSocket.call('shared.List.list', {model_name: model, is_hidden: hidden})
        }

        function handleList(item) {
            return mnWebSocket.call('shared.List.handle_list', item);
        }

        function reorderList(items, model) {
            return mnWebSocket.call('shared.List.reorder', {items: items, model_name: model});
        }

        function autocompleteListSearch(model_name, query, fill, fill_by) {
            fill = _.isNil(fill) ? false : fill
            return mnWebSocket.call('shared.List.autocomplete_list', {
                model_name: model_name,
                query: query,
                fill: fill,
                fill_by: fill_by
            });
        }

        function removeItem(list, event) {
            var deferred = $q.defer();

            var confirm = $mdDialog.confirm()
                .title($translate['instant']('list_remove_confirm', list))
                .ariaLabel('remove plan confirm')
                .targetEvent(event)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove, deferred.reject);

            function remove() {
                var url = '/api/list/' + list.id + '/';

                $http.delete(url)
                    .then(deferred.resolve);
            }

            return deferred.promise;
        }
    }

})();