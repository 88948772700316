(function () {

    'use strict';

    module.exports = EntryReportGraphCtrl;

    EntryReportGraphCtrl.$inject = ["system", "statesService", "$element", "$translate"];

    function EntryReportGraphCtrl(system, statesService, $element, $translate) {
        var vm = this;

        var countGraph = null;

        vm.$onInit = init;
        vm.getData = _.mnDelay(getData, 400);

        var colors = {
            "entries_with_appointment": {border: "#424242", background: "#9E9E9E"},
            "entries_without_appointment": {border: "#FBC02D", background: "#FFEE58"},
        }

        function init() {
            vm.months = moment().localeData().months();

            vm.query = {
                month: moment().month() == 0 ? 12 : moment().month(),
                year: moment().year()
            };

            var context = $("#output-canvas", $element).get(0).getContext("2d");

            countGraph = new Chart(context, {
                type: 'bar',
                data: {
                    datasets: [],
                    labels: []
                },
                responsive: true,
            });

            getData();
        }

        function getData() {
            vm.promise = statesService.getEntryReport(vm.query)
                .then(success);

            function success(data) {
                countGraph.data.labels = getLabels(data);

                countGraph.data.datasets = _.concat(
                    getDataSet(data, "entries_with_appointment"),
                    getDataSet(data, "entries_without_appointment")
                );

                countGraph.update();
            }
        }

        function getLabels(data) {
            return _.map(data, function (item) {
                return moment(item.entry_time).format('ll');
            })
        }

        function getDataSet(data, type) {
            return {
                data: _.map(data, type),
                borderColor: colors[type].border,
                label: $translate['instant'](type),
                backgroundColor: colors[type].background,
            }
        }


    }

})();
