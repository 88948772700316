/**
 * Created by Hp on 02/03/2017.
 */
(function () {
    'use strict';

    module.exports = DeliveryFormCtrl;

    DeliveryFormCtrl.$inject = ["patientService", "$transition$", "$scope", "stockService", "system", "$state", "$mdToast", "$translate", "saleService", "$mdDialog","$q", "configService"];

    function DeliveryFormCtrl(patientService, $transition$, $scope, stockService, system, $state, $mdToast, $translate, saleService, $mdDialog,$q, configService) {

        var vm = this;

        var currentDelivery = $transition$.params('to')["deliveryId"];
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;
        vm.submit = submit;
        vm.invalidateDelivery = invalidateDelivery;
        vm.validateDelivery = validateDelivery;
        vm.isAllValid = isAllValid;

        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;
        vm.save = save;


        function init() {
            vm.is_new = !_.isNil(currentDelivery) ? false : true;
            vm.docType = 'OUT';
            $scope.$watch('vm.delivery.is_valid', readOnlyDelivery);
            vm.modelName="DeliveryForm";


            vm.promises = [ configService.get(["price_mode"], true)];


            vm.filter = {is_deleted: {"$ne": true}, is_draft: {"$ne": true}};

            if (!_.isNil(currentDelivery)) vm.promises.push(stockService.getStockDocument(currentDelivery, 'delivery-form'));
            else   vm.delivery = {  doc_date: moment().format(dateFormat), lines: []};
            vm.promise = $q.all(vm.promises).then(success);

            function success(data) {
                if (!_.isNil(currentDelivery)) vm.delivery = data[1];
                else vm.delivery.price_mode = data[0];
            }

        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success, error);
            }
            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else saveSuccess(data);
            }
            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.delivery = data;
            if (_.isNil(currentDelivery)) $state.go('app.stock.delivery-form', {deliveryId: data.id}, {location: 'replace'});
        }

        function invalidateDelivery() {
            return stockService.invalidateMovement(vm.delivery, 'DeliveryForm', 'OUT');
        }

        function validateDelivery(data) {
            return stockService.validateMovement(data, 'DeliveryForm', 'OUT');
        }

        function readOnlyDelivery() {
            vm.readOnly = (vm.delivery && vm.delivery.is_valid) ? vm.delivery.is_valid : false;
        }

        function isAllValid() {
            return vm.delivery ? stockService.isAllValid(vm.delivery, 'OUT',vm.modelName) : true;
        }

        function removePatient() {
            vm.delivery.patient = null;
        }

        function patientCallback(patient) {
            vm.promise = patientService.getMinimalPatient(patient.id, false)
                .then(doneCallback);
            function doneCallback(data) {
                vm.delivery.patient = data;
            }
        }

        function save() {
            return stockService.saveStockDocument(vm.delivery, "delivery-form", 'DeliveryForm');
        }
    }
})
();
