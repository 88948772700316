/**
 * Created by Betalos on 27/06/2017.
 */
(function () {

    'use strict';

    module.exports = {
        controller: mnTableMain,
        controllerAs: "vm",
        bindings: {
            event: "@",
            configKey: "@",
            actions: '<',
        },
        template: tpl, // or template
    };

    mnTableMain.$inject = ["tableTestService"];

    function mnTableMain(tableTestService) {
        var vm = this;

        vm.$onInit = init;
        vm.change = change;

        function init() {
            vm.singleActionsCount = _.chain(vm.actions).get('single', []).size().value();
            vm.singleActionsClass = _.format('actions-column-{0}', vm.singleActionsCount);

            vm.singleActions = _.get(vm.actions, 'single', []);
            vm.multipleActions = _.get(vm.actions, 'multiple', []);

            vm.query = {
                page: 1
            }

            var columnChangeSubscriber = tableTestService.columnChangeSubject.subscribe(handleColumnChange);

            vm.promise = tableTestService.getColumns(vm.event, vm.configKey)
                .then(success);

            function success(data) {
                vm.columnMenu = _.get(data, 'columns', []);
                vm.columnTr = _.filter(vm.columnMenu, ['hidden', false]);

                _.assign(vm.query, _.pick(data, 'limit'));
            }


            function handleColumnChange() {
                vm.columnTr = _.filter(vm.columnMenu, ['hidden', false]);
            }
        }

        function change() {
        }
    }

    tpl.$inject = ['$element', '$attrs', '$translate'];

    function tpl(elem, attrs, $translate) {
        var template = $(require('../views/table-main.html'));

        var paginationLabel = {
            page: $translate['instant']('page'),
            rowsPerPage: $translate['instant']('rowsPerPage'),
            of: $translate['instant']('of')
        }

        $('md-table-pagination', template)
            .attr('md-limit-options', '[5, 10, 15, 20]')
            .attr('md-label', JSON.stringify(paginationLabel));


        return template.html();
    }

})();