/**
 * Created by amine on 22/03/2017.
 */
(function () {
    'use strict';

    const angular = require('angular');

    const mnHumanSkeleton = require('./components/human-skeleton');
    const TraumatoContainerCtrl = require('./controllers/TraumatoContainerCtrl');

    const mnPregnancyBlock = require('./components/pregnancy-block');
    const pregnancyService = require('./services/pregnancyService');

    const ophthalmicService = require('./services/ophthalmicService');
    const ophthalmicPrescription = require('./components/ophthalmic-prescription');

    const vaccinationService = require('./services/vaccinationService');
    const vaccinationCalendar = require('./components/vaccination-calendar-block');
    const vaccinationAgeInterval = require('./components/vaccination-age-interval');

    const mnHurExam = require('./components/hur-exam');

    // const observationBlock = require('./components/observation-block');
    const observationService = require('./services/observationService');
    const ptBlock = require('./components/prothrombin-time-block');
    const ptService = require('./services/ptService');

    const hurService = require('./services/hurService');

    const pathologyBlock = require('./components/pathology-block');
    const pathologyService = require('./services/pathologyService');

    angular
        .module("medinet")

        .controller('TraumatoContainerCtrl', TraumatoContainerCtrl)
        .component('mnHumanSkeleton', mnHumanSkeleton)

        .component('mnPregnancyBlock', mnPregnancyBlock)
        .service('pregnancyService', pregnancyService)

        .service('ophthalmicService', ophthalmicService)
        .component('mnOphthalmicPrescription', ophthalmicPrescription)

        .service('vaccinationService', vaccinationService)
        .component('mnAgeInterval', vaccinationAgeInterval)
        .component('mnVaccinationCalendar', vaccinationCalendar)

        .service('observationService', observationService)

        .component('mnPtBlock', ptBlock)
        .service('ptService', ptService)

        .component('mnHurExam', mnHurExam)
        .service('hurService', hurService)

        .component('mnPathologyBlock', pathologyBlock)
        .service('pathologyService', pathologyService)
})();