(function () {
    'use strict';


    module.exports = getWidthOfText;

    function getWidthOfText($element) {
        let txt = $element.text();
        let fontName = $element.css('font-name');
        let fontSize = $element.css('font-size');

        if (getWidthOfText.c === undefined) {
            getWidthOfText.c = document.createElement('canvas');
            getWidthOfText.ctx = getWidthOfText.c.getContext('2d');
        }
        getWidthOfText.ctx.font = fontSize + ' ' + fontName;
        return getWidthOfText.ctx.measureText(txt).width;
    }

})();