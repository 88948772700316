/**
 * Created by amine on 28/06/2019.
 */
(function () {

    'use strict';

    class PathologiesDialogCtrl {
        constructor($mdDialog, pathologyService) {
            this.dialog = $mdDialog;
            this.service = pathologyService;
            this.model = this.model ? this.model : {};
        }

        static get $inject() {
            return ["$mdDialog", "pathologyService"];
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.service.savePathology(this.model)
                .then(data => this.dialog.hide(data), () => this.dialog.cancel())
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: PathologiesDialogCtrl,
        template: require("../views/pathologies-form.tpl.html"),
    };

})();