/**
 * Created by Hp on 24/03/2017.
 */
(function () {

    'use strict';

    let mnDocumentComment = {
        controller: MnDocumentCommentController,
        controllerAs: "vm",
        bindings: {
            stockDocument: "=ngModel",
            documentCls: "=documentCls",
            // mnFileCls: "=mnFileCls",
            // fileContext: "=fileContext",

        },
        template: require('stock/views/document-comment.tpl.html'),
    };

    MnDocumentCommentController.$inject = ["$scope", "system", "$translate", "stockService", "$mdDialog", "$anchorScroll", "$location", "$timeout"];

    function MnDocumentCommentController($scope, system, $translate, stockService, $mdDialog, $anchorScroll, $location, $timeout) {
        let vm = this;

        let dateFormat = system['date_format'].js;
        let timeFormat = system['time_format'].js;
        $anchorScroll.yOffset = 40;
        vm.$onInit = init;

        vm.newCommentClick = newCommentClick;
        vm.update = update;
        vm.privatize = privatize;
        vm.remove = remove;
        vm.handleCommentFocus = handleCommentFocus;

        vm.formatDate = function (d) {
            return moment(d, dateFormat).format("ddd DD MMMYYYY") || d;
        };
        vm.formatTime = function (t) {
            return moment(t, timeFormat).format('hh:mm') || t;
        };

        function init() {
            vm.comment = {};
            vm.comments = [];
            $scope.$watch("vm.comments", handleComments);
            $scope.$watch("vm.stockDocument['id']", loadComments);
        }

        function loadComments() {
            vm.promise = stockService.getDocumentComments(vm.documentCls, _.get(vm.stockDocument, 'id')).then(data => {
                vm.comments = data;
            });
        }

        function handleComments() {
            let grouped = _.groupBy(vm.comments, 'comment_date');
            vm.groupedComments = _.map(_.keys(grouped), (k) => {
                return _.zipObject(["comment_date", "details"], [k, groupComments(grouped[k])])
            });
            if(!_.isEmpty(vm.groupedComments)){
                let scroll_idx=`anc_${_.size(vm.groupedComments)-1}_${_.chain(vm.groupedComments).last().get('details').size().value()-1}` ;
                let elem=document.getElementById(scroll_idx) ;
                if (elem){
                    $anchorScroll(scroll_idx);
                }
            }
        }

        function groupComments(c) {
            let grouped = _.groupBy(c, 'comment_time');
            return _.map(_.keys(grouped), (k) => {
                return _.zipObject(["comment_time", "details"], [k, grouped[k]])
            });
        }

        function newCommentClick(ev) {
            ev.stopPropagation();
            if (!_.isEmpty(_.get(vm.comment, 'content'))) {
                vm.comment = _.assign(vm.comment, {
                    title: `${$translate.instant('comment_note')} ${moment().format(dateFormat)} ${moment().format(timeFormat)}`,
                    comment_date: moment().format(dateFormat),
                    comment_time: moment().format(timeFormat),
                    doc_model: vm.documentCls,
                    doc_id: _.get(vm.stockDocument, 'id')
                });
                stockService.saveDocumentComment(_.cloneDeep(vm.comment)).then(data => {
                    loadComments();
                });
                vm.comment = null;
            }

        }

        function update(c) {
            $mdDialog.show(_.assign({}, require('../dialogs/comment-dialog'), {comment: _.cloneDeep(c)})).then(success);

            function success(comment) {
                if (comment) {
                    stockService.saveDocumentComment(_.cloneDeep(comment)).then(loadComments);
                }
            }
        }

        function privatize(c) {
            stockService.privatizeDocumentComments(_.get(c, 'id')).then(data => {
                if (data) {
                    loadComments();
                }
            })
        }

        function unprivatize(c) {
            stockService.unprivatizeDocumentComments(_.get(c, 'id')).then(data => {
                if (data) {
                    loadComments();
                }
            })
        }

        function remove(c) {
            stockService.deleteDocumentComment(_.get(c, 'id')).then(data => {
                loadComments();
            });
        }

        function handleCommentFocus() {

        }
    }

    module.exports = mnDocumentComment;

})();