/**
 * Created by Hp on 28/02/2017.
 */
(function () {

    'use strict';

    module.exports = stockService;

    stockService.$inject = ["$http", "$q", "mnWebSocket", "lotService"];

    function stockService($http, $q, mnWebSocket, lotService) {
        let self = this;

        self.isAllValid = isAllValid;
        self.validateMovement = validateMovement;
        self.invalidateMovement = invalidateMovement;

        self.saveStockDocument = saveStockDocument;
        self.getStockDocument = getStockDocument;
        self.deleteStockObject = deleteStockObject;
        self.getNonTakenNumbers = getNonTakenNumbers;

        self.isMovementBlock = isMovementBlock;
        self.allowDocInvalidate = allowDocInvalidate;
        self.simpleInvalidate = simpleInvalidate;

        self.get_stock_config = get_stock_config;
        self.updateStockDocument = updateStockDocument;
        self.saveDocumentComment = saveDocumentComment;
        self.getDocumentComments = getDocumentComments;
        self.deleteDocumentComment = deleteDocumentComment;
        self.privatizeDocumentComments = privatizeDocumentComments;
        self.unprivatizeDocumentComments = unprivatizeDocumentComments;


        function getUrl(_model) {
            return `/api/${_model}/`;
            // let url = "";
            // switch (_model) {
            //     case "movement":
            //         url += "movement/";
            //         break;
            //     case "purchase-form":
            //         url += "purchase-form/";
            //         break;
            //     case "purchase-receipt":
            //         url += "purchase-receipt/";
            //         break;
            //     case "good-deposition":
            //         url += "good-deposition/";
            //         break;
            //     case "good-return":
            //         url += "good-return/";
            //         break;
            //     case "delivery-form":
            //         url += "delivery-form/";
            //         break;
            //     case "inventory":
            //         url += "inventory/";
            //         break;
            //     case "purchase-invoice":
            //         url += "purchase-invoice/";
            //         break;
            //     case "price-request":
            //         url += "price-request/";
            //         break;
            //
            //     case "transformation":
            //         url += "transformation/";
            //         break;
            //
            //     case "warehouse-exchange":
            //         url += "warehouse-exchange/";
            //         break;
            //     case "credit-note":
            //         url += "credit-note/";
            //         break;
            //     case "opening-balance":
            //         url += "opening-balance/";
            //         break;
            //     case "consignment":
            //         url += "opening-balance/";
            //         break;
            //     case "opening-balance":
            //         url += "opening-balance/";
            //         break;
            //
            // }
            // return url;
        }

        function saveStockDocument(_document, _model, mnModel) {
            let deferred = $q.defer();
            let url = getUrl(_model);
            _document = _.assign(_document, {ignore: true});
            if (!_.isNil(_document.id)) {
                url += _document.id + "/";
                mnWebSocket.call('stock.StockDocument.load_stock_document', {
                    pk: _document.id,
                    mnModel: mnModel,
                    pkg: 'stock'
                }).then(function (data) {
                    $http.post(url, _document)
                        .then(successCallback, errorCallback);
                });
            }

            else $http.post(url, _document)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getStockDocument(object_id, _model) {
            let deferred = $q.defer();
            let url = getUrl(_model) + object_id + "/";
            $http.get(url)
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }


        function updateStockDocument(object_id, _model, content) {

            let deferred = $q.defer();
            let url = getUrl(_model) + object_id + "/";

            $http.put(url, content)
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function simpleInvalidate(object_id, _model) {
            let deferred = $q.defer();
            let url = getUrl(_model) + object_id + "/";
            $http.put(url, {is_valid: false})
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteStockObject(object_id, _model) {
            let deferred = $q.defer();
            let url = getUrl(_model) + object_id + "/";
            $http.delete(url)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function isAllValid(movement, movement_type, documentCls) {
            let valid = true;
            let non_complete_serials = _.filter(movement.lines, function (line) {
                return !line.is_comment && line.article['has_serial_number'] && (_.size(line.serial_numbers) != line.qte || _.filter(line.serial_numbers, function (item) {
                    return item.warehouse.id != line.warehouse.id;
                }).length > 0);
            });
            let non_complete_lots = _.filter(movement.lines, function (line) {
                if (!line.is_comment && line.article['has_lot_number']) {
                    switch (movement_type) {
                        case 'IN':
                        case 'DISASSEMBLY_LINES':
                            return _.sumBy(line.lot_numbers, function (lot) {
                                return lotService.getLotQteByStore(lot, line);
                            }) != line.qte;
                            break;
                        case 'BR':
                            let sum_received_qte = _.sumBy(line.lot_numbers, function (o) {
                                return _.sumBy(lotService.currentLotStore(o, line)['received_quantities'], function (item) {
                                    if (item.mvt_code == movement.number && item.mvt_cls == documentCls && !item['is_deleted']) return item.qte;
                                })
                            });
                            return line.src_doc_cls == 'GoodDeposition' ? sum_received_qte != line.qte : _.sumBy(line.lot_numbers, function (lot) {
                                return lotService.getLotQteByStore(lot, line);
                            }) != line.qte;
                        case 'OUT':
                        case 'ASSEMBLY_LINES':
                            let sum_sold_qte = _.sumBy(line.lot_numbers, function (o) {
                                return _.sumBy(lotService.currentLotStore(o, line)['sold_quantities'], function (item) {
                                    if (item.mvt_code == movement.number && item.mvt_cls == documentCls && !item['is_deleted']) return item.qte;
                                })
                            });
                            return sum_sold_qte != line.qte || _.filter(line.lot_numbers, function (item) {
                                return lotService.currentLotStore(item, line).warehouse.id != line.warehouse.id;
                            }).length > 0;
                            break;
                        case 'RETURN':
                            let sum_returned_qte = _.sumBy(line.lot_numbers, function (o) {
                                return _.sumBy(lotService.currentLotStore(o, line)['returned_quantities'], function (item) {
                                    if (item.mvt_code == movement.number && item.mvt_cls == documentCls && !item['is_deleted']) return item.qte;
                                })
                            });
                            return sum_returned_qte != line.qte || _.filter(line.lot_numbers, function (item) {
                                return lotService.currentLotStore(item, line).warehouse.id != line.warehouse.id;
                            }).length > 0;
                            break;

                        case 'WarehouseExchange':
                            return _.sumBy(line.lot_numbers, function (lot) {
                                return lot['transfer_qte'];
                            }) != line.qte;
                            ;
                            break;
                    }
                }
            });
            if (!_.isEmpty(non_complete_serials) || !_.isEmpty(non_complete_lots)) valid = false;
            return valid;
        }

        function validateMovement(_model, _model_name, _mvt_type) {
            let query = {
                _document: _model,
                _model_name: _model_name,
                _mvt_type: _mvt_type,
                action: 'validate'
            };
            return mnWebSocket.call('stock.StockDocument.validate_document', query);
        }

        function invalidateMovement(_model, _model_name, _mvt_type) {
            let query = {
                _document: _model,
                _model_name: _model_name,
                _mvt_type: _mvt_type,
                action: 'invalidate'
            }
            return mnWebSocket.call('stock.StockDocument.validate_document', query);
        }

        function isMovementBlock() {
            return mnWebSocket.call('stock.Inventory.is_inventory_started');
        }

        function getNonTakenNumbers(mnModel, pkg) {
            return mnWebSocket.call('stock.StockDocument.get_model_non_taken_numbers', {mnModel: mnModel, pkg: pkg});
        }

        function allowDocInvalidate(id, doc_cls, mvt_type, pkg) {
            return mnWebSocket.call('stock.StockDocument.allow_invalidate', {
                _id: id,
                doc_cls: doc_cls,
                mvt_type: mvt_type,
                package: pkg
            });
        }


        function get_stock_config() {
            return mnWebSocket.call('stock.StockDocument.get_default_config');
        }

        function privatizeDocumentComments(pk) {
            return mnWebSocket.call("stock.DocumentComments.privatize", {pk});
        }

        function unprivatizeDocumentComments(pk) {
            return mnWebSocket.call("stock.DocumentComments.unprivatize", {pk});
        }

        function getDocumentComments(cls, pk) {
            return mnWebSocket.call("stock.DocumentComments.get_comments", {cls, pk});
        }

        function saveDocumentComment(c) {
            var deferred = $q.defer();
            var url = `/api/document-comments/${c.id ? c.id + "/" : ""}`;
            $http.post(url, c)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteDocumentComment(pk) {
            var deferred = $q.defer();
            var url = `/api/document-comments/${pk}/`;
            $http.delete(url)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

    }
})();