/**
 * Created by BETALOS on 24/02/2016.
 */
(function () {

    'use strict';

    // TODO think about well refactor this code before starting new calendar module new projects

    module.exports = {
        controller: CalendarCtrl,
        controllerAs: "vm",
        bindings: {
            filter: "=mnFilter",
            callback: "<"
        },
        template: require('./views/calendar.tpl.html'),
    };

    const MenuCtrl = require('shared/utils/menu-ctrl');
    const getWidthOfText = require('shared/utils/text-length');

    const VISIT_SUB_LINKS = require('parameters/json/visit-sub-links.json');
    const VISUALIZE_FILE = require('stand-alone/file-manager/dialogs/visualize-file-dialog');

    const RDV_EVENT_ELEMENT = require('stand-alone/calendar/views/rdv-event-element.tpl.html');
    const PAUSE_EVENT_ELEMENT = require('stand-alone/calendar/views/pause-event-element.tpl.html');
    const UNAVAILABLE_EVENT_ELEMENT = require('stand-alone/calendar/views/unavailable-event-element.tpl.html');
    const UNAVAILABLE_MONTH_ELEMENT = require('stand-alone/calendar/views/unavailable-month-element.tpl.html');
    const PAUSE_MONTH_ELEMENT = require('stand-alone/calendar/views/pause-month-element.tpl.html');
    const RDV_MENU_ELEMENT = require('stand-alone/calendar/views/rdv-menu-element.tpl.html');
    const BASIC_MENU_ELEMENT = require('stand-alone/calendar/views/basic-menu-element.tpl.html');
    const WAITING_ITEM_ELEMENT = require('stand-alone/calendar/views/waiting-item-element.tpl.html');
    const CALENDAR_DATE_NAVIGATION_ELEMENT = require('stand-alone/calendar/views/calendar-date-navigation.tpl.html');

    CalendarCtrl.$inject = [
        '$element', 'mnWebSocket', 'system', 'configService', '$translate', 'frontDeskService',
        '$interpolate', '$timeout', '$mdPanel', '$scope', '$compile', '$q', 'physicianService', 'practiceService',
        '$state', "$auth", "$mdDialog", "notificationService", "visitService"
    ];

    function CalendarCtrl(
        element, mnWebSocket, system, configService, $translate, frontDeskService, $interpolate,
        $timeout, $mdPanel, $scope, $compile, $q, physicianService, practiceService, $state, $auth, $mdDialog,
        notificationService, visitService
    ) {
        let vm = this;

        let timeFormat = system['time_format'].js;
        let dateFormat = system['date_format'].js;

        let $calendar = null;

        let $waitingNav = $("md-sidenav[md-component-id=waiting-list-nav]", element);

        let killTimeout = null;
        let slot_duration = null;

        let closingDays = null;

        let panelRef = null;
        let subscription = null;
        let visitSubLinksSubscription = null;
        let menuCtrl = new MenuCtrl($mdPanel, $scope, $compile);

        vm.$onInit = init;
        vm.$onDestroy = destroy;
        vm.isLockedOpen = true;
        vm.isOpen = false;

        vm.navigationDate = moment().toDate();
        vm.navigationChange = navigationChange;

        function init() {
            vm.promise = configService.getByHttp("calendar_config")
                .then(loadCalendar);

            mnWebSocket.sub("frontdesk.Calendar.notify", "calendar", _.mnDelay(handleNotification, 400));

            subscription = frontDeskService.resourceSubject.subscribe(reloadResource);
            visitSubLinksSubscription = visitService.visitSubLinks.subscribe(handleSubLinks);

            $scope.$watch("vm.filter", function (filter) {
                if (_.isNil(filter)) return;
                if (!_.isNull($calendar)) $calendar.fullCalendar('refetchEvents');
                frontDeskService.getWaitingList(filter).then(WaitingListDone);

                if ("physician" in filter || "agenda" in filter) reloadResource();

            }, true);
        }

        function reloadResource() {
            if (!_.isNull($calendar)) $calendar.fullCalendar('refetchResources');
        }

        function handleSubLinks(data) {
            vm.visitLinkConfig = _.find(VISIT_SUB_LINKS, ['key', data.favorite]);
        }

        function WaitingListDone(data) {
            if (_.isEmpty(data)) $('md-content > .empty-content', $waitingNav).show();
            else $('md-content > .empty-content', $waitingNav).hide();

            $('md-content > .mn-event', $waitingNav).remove();

            _.forEach(data, generateWaitingList);

            $timeout(
                () => handleWaitingListCount(data.length)
            )
        }

        function handleWaitingListCount(count) {
            $('button.fc-waitingListToggle-button > .waiting-list-count', $calendar).text(count);
        }

        function destroy() {
            subscription.unsubscribe();
            visitSubLinksSubscription.unsubscribe();

            if (!_.isNull($calendar)) $calendar.fullCalendar('destroy');
            mnWebSocket.unsub("frontdesk.Calendar.notify", element.attr('namespace') || "calendar");
        }

        function loadCalendar(data) {
            $calendar = $("#calendar", element);
            slot_duration = moment.duration(data['slot_duration']).asMinutes();

            closingDays = _.assign({
                "rendering": "background",
                "end": "23:59",
                "start": "00:00",
                "overlap": false,
                "unavailable": true,
                "editable": false,
                "id": "cd"
            }, data['closing_days']);

            closingDays['dow'] = _.has(closingDays, 'dow') ? closingDays['dow'] : [];

            const config = _.assign({
                minTime: data['min_time'],
                maxTime: data['max_time'],
                defaultView: data['default_view'],
                slotDuration: data['slot_duration'],
                defaultTimedEventDuration: moment.duration(data['slot_duration'], 'm'),

                locale: system.lang,
                timeFormat: timeFormat,
                slotLabelFormat: timeFormat,

                drop: eventDrop,
                eventReceive: eventResizeOrMove,

                resourceRender: resourceRender,
                resources: call => getResources(call, data['use_abbr']),

                eventConstraint: {
                    start: moment().format('YYYY-MM-DD'),
                    end: '2100-01-01' // hard coded goodness unfortunately
                },

                customButtons: {
                    waitingListToggle: {
                        icon: 'mdi mdi-menu',
                        click: toggleWaitingList
                    },
                    calendarButton: {
                        icon: 'mdi mdi-calendar-text',
                        click: calendarNavigation
                    },
                    addWaitingList: {
                        icon: 'mdi mdi-plus',
                        click: addAppointmentToWL
                    }
                },
                buttonText: {
                    today: $translate['instant']('today'),
                    month: $translate['instant']('month'),
                    agendaWeek: $translate['instant']('week'),
                    agendaDay: $translate['instant']('day'),
                    listWeek: $translate['instant']('list_week'),
                    listDay: $translate['instant']('list_day'),
                },

                defaultDate: moment(),
                eventLimitText: $translate['instant']('more'),
                columnFormat: $translate['instant']('column_format'),

                events: getEvents,
                eventRender: eventRender,
                eventDrop: eventResizeOrMove,
                eventResize: eventResizeOrMove,
                eventAfterAllRender: addDayEventCount,

                select: selectInterval,
                eventClick: eventClick,
                eventMouseout: eventMouseOut,
                eventMouseover: eventMouseOver,
            }, require('./json/calendar.json'));

            $calendar.fullCalendar(config);

            let $scrollElem = $('.fc-scroller', $calendar);
            let $currentHourLabel = $('.fc-now-indicator.fc-now-indicator-arrow', $calendar);

            $('button.fc-waitingListToggle-button', $calendar).append('<span class="waiting-list-count">0</span>');

            if ($currentHourLabel.length == 0 || $scrollElem.length == 0) return;

            let elementTop = $scrollElem.scrollTop();
            let itemTop = $currentHourLabel.position().top;

            $scrollElem.stop().animate({scrollTop: elementTop + itemTop}, {
                duration: 600,
                easing: "easeInOutQuad"
            });
        }

        function getResources(callback, useAbbr) {
            let currentResource = frontDeskService.resourceSubject.getValue();

            let promise = null;

            if (currentResource == 'agenda_resource') promise = frontDeskService.getAgendasResource(vm.filter);
            if (currentResource == 'physician_resource') promise = physicianService.agendaResources(vm.filter, useAbbr);

            promise.then(callback);
        }

        function resourceRender(resourceObj) {
            let element = '.fc-resource-cell[data-resource-id=' + resourceObj.id + ']';
            $(element, $calendar).attr('data-date', $calendar.fullCalendar('getDate').format('YYYY-MM-DD'));

            if (_.has(resourceObj, 'color')) $(element, $calendar).css('color', resourceObj.color);
        }

        function getEvents(start, end, timezone, callback) {
            let obj = {start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD'), filter: vm.filter};

            frontDeskService
                .getEvents(obj, isMonthView(), closingDays)
                .then(done);

            function done(data) {
                $calendar.fullCalendar('removeEvents');
                callback(data);
            }
        }

        function addDayEventCount() {
            let currentView = $calendar.fullCalendar('getView').name;

            let calendarTitle = $('.fc-center > h2', $calendar).text();
            $calendar.parents('.agenda-dialog, .mn-module').find('.md-toolbar-tools > h2').text(calendarTitle);

            if (currentView === 'month') return;
            if (_.includes(['listWeek', 'listDay'], currentView)) $('.fc-list-heading .fc-widget-header').attr('colspan', '4');

            let headers = $(getHeaderElements(), $calendar);

            headers.each(handleHeader);

            function handleHeader() {
                let $header = $(this);

                let headerDate = $header.attr('data-date');
                let resourceId = $header.attr('data-resource-id');

                let eventClass = `.mn-rdv${resourceId ? '.' + resourceId : ''}[event-date="${headerDate}"]`;
                let eventSize = $(eventClass, $calendar).length;

                if ($header.find('.day-event-count').length === 0) handleCount($header);

                $('.day-event-count', $header).text(eventSize);
            }

            function handleCount($header) {
                if (_.includes(['listWeek', 'listDay'], currentView)) $('.fc-widget-header', $header).append('<span class="day-event-count" />');
                else $header.append('<span class="day-event-count" />');
            }

            function getHeaderElements() {
                if (currentView === 'agendaWeek') return '.fc-day-header[data-date]';
                else if (_.includes(['listWeek', 'listDay'], currentView)) return '.fc-list-heading[data-date]';
                else if (_.includes(['agendaTwoDay', 'agendaDay'], currentView)) return '.fc-resource-cell[data-date]';
            }
        }

        function eventResizeOrMove(event, delta, revertFunc, jsEvent) {
            let appointment = transformToAppointment(event);

            frontDeskService.partialUpdateAppointment(event.id, appointment)
                .then(successNotify, revertFunc);
        }

        function transformToAppointment(event) {
            // make more validation on date
            return _.assign({
                id: event.id,
                date: event.start.format(dateFormat),
                start_time: event.start.format(timeFormat),
                end_time: event['is_waiting_list'] ?
                    event.start.add(event['reason_duration'], 'minutes').format(timeFormat) : event.end.format(timeFormat),
                is_waiting_room: false
            }, handleResources(event));
        }

        function handleResources(event) {
            return _.chain(event).get('resourceIds').reduce((data, item) => {
                let resource = _.chain(item).split('_').thru(i => {
                    return {[i[0]]: parseInt(i[1])}
                }).value();

                return _.assign(data, resource);
            }, {}).value();
        }

        function successNotify(data) {
            mnWebSocket.pub("frontdesk.Calendar.notify", data, false);
        }


        // waiting list
        function generateWaitingList(item) {
            let $inner = $interpolate(WAITING_ITEM_ELEMENT)(item);
            let $elem = $('<div />').html($inner);

            item = _.assign(item, {stick: true, is_waiting_list: true});

            $elem.addClass('fc-event');
            $elem.addClass('mn-event');
            $elem.addClass('mn-waiting-event');
            $elem.addClass(item['reason_class']);
            $elem.addClass(item['doctor_class']);
            $elem.attr("data-event", item.id);

            $elem.data('event', item);

            $elem.draggable({
                zIndex: 999,
                containment: element,
                appendTo: element,
                helper: "clone",
                start: start,
                stop: stop,
                cursorAt: {left: 25, top: 10},
                scroll: false,
                revert: true,
                addClasses: false,
                revertDuration: 400
            });

            $('.waiting-item-data  > .patient-block', $elem)
                .on('click', function (ev) {
                    stopPropagation(ev);
                    patientDetail(item.id, ev);
                });

            $elem.on('click', function (ev) {
                editEvent(item.id, ev);
            });

            $(".dots-button > .mdi.mdi-dots-vertical", $elem)
                .on('click', showMenu);

            $('md-content', $waitingNav).append($elem);

            function start(event, ui) {
                $(this).addClass('is_dragging');
                ui.helper.data('width', ui.helper.width());
                ui.helper.addClass('dragging-stat');
                ui.helper.css('width', $('.fc-day.fc-widget-content', element).width());
            }

            function stop(event, ui) {
                $(this).removeClass('is_dragging');
            }
        }

        function eventDrop(date, jsEvent, ui) {
            $(this).remove();
            showEmptyList();
        }

        function showEmptyList() {
            if (_.isEmpty($('.mn-waiting-event')))
                $('md-content > .empty-content', $waitingNav).show();
        }

        function addAppointmentToWL(event) {
            vm.callback(null, {is_waiting_room: true}, event);
        }

        //calendar navigation
        function calendarNavigation(ev) {
            menuCtrl.open(ev, CALENDAR_DATE_NAVIGATION_ELEMENT).then(ref => panelRef = ref);
        }

        function navigationChange() {
            closePanel();
            $calendar.fullCalendar('gotoDate', vm.navigationDate);
        }

        // end waiting list

        function selectInterval(start, end, jsEvent, view, resource) {
            if (view.name === "month") {
                $calendar.fullCalendar('gotoDate', start);
                $calendar.fullCalendar("changeView", "agendaDay");
            }

            else {
                let obj = _.assign(
                    handleResource(resource),
                    {start: start, end: end, isDrag: moment.duration(end.diff(start)).minutes() != slot_duration}
                );

                vm.callback(obj, null, jsEvent);
            }
        }


        function handleResource(resource) {
            if (resource) return _.chain(resource).get('id').split('_').thru(
                data => {
                    return {[data[0]]: {id: parseInt(data[1])}}
                }
            ).value();
            else return {}
        }

        function eventClick(event, jsEvent) {
            vm.callback(null, event, jsEvent);
        }

        function eventMouseOver(event, jsEvent, view) {
            if (view.name === "month") return;

            let $elem = $(this);

            if ($(jsEvent.target, $elem).is('span.mdi.mdi-dots-vertical')) return;
            if ($elem.is('.fc-list-item')) return;

            killTimeout = $timeout(hoverStart, 450);

            function hoverStart() {
                $elem.addClass("hovered");

                let t = $elem.css("top");
                let b = $elem.css("bottom");
                let r = $elem.css("right");

                let eventHeight = $('.rdv-data', $elem).height();
                let width = $('.rdv-data > .patient-block', $elem).width();

                let $scrollClone = $('.rdv-data > .patient-block', $elem);

                let scrollWidth = getWidthOfText($scrollClone) + 25;

                let nb = -1 * (parseInt(t) + eventHeight);
                let nr = scrollWidth - width;

                $elem.data("bottom", parseInt(b));
                $elem.data("right", r);

                let obj = {};

                if ((parseInt(b) > nb)) _.set(obj, 'bottom', nb);
                if (width < scrollWidth && nr < 0) _.set(obj, 'right', nr);
                else _.set(obj, 'right', 0);

                $elem.animate(obj, {
                    start: startAnimation,
                    duration: 120,
                    easing: "easeInOutQuad",
                });

                function startAnimation() {
                    killTimeout = null;
                    $elem.parent().addClass("hovered-event-container");
                }
            }
        }

        function eventMouseOut(event, jsEvent, view) {
            if (view.name === "month") return;

            if (_.isObject(killTimeout)) {
                $timeout.cancel(killTimeout);
            }

            let $elem = $(this);

            if ($elem.hasClass("hovered")) hoverEnd();

            function hoverEnd() {
                $elem.stop().animate({bottom: $elem.data("bottom"), right: $elem.data("right")}, {
                    duration: 120,
                    easing: "easeInOutQuad",
                    complete: completeHover
                });
            }

            function completeHover() {
                $elem.removeClass("hovered");
                $elem.parent().removeClass("hovered-event-container");
            }

        }

        function eventRender(event, elem, view) {
            event.start = moment(event.start);
            event.end = moment(event.end);

            elem.addClass("mn-event");
            elem.attr("data-event", event.id);

            switch (true) {
                case _.eq(event.id, "cd"):
                    return renderBusinessDay(event, elem);
                    break;
                case !event['is_pause'] && !_.isNumber(event.id):
                    return renderUnavailability(event, elem);
                    break;
                case event['is_pause']:
                    return renderPause(event, elem);
                    break;
                default:
                    return renderRdv(event, elem, view.name);
            }
        }

        function renderBusinessDay(event, elem) {
            if (!isMonthView()) {
                elem.addClass("mn-business-day");
                elem.on('mousedown click', stopPropagation);
                return elem;
            }
            else {
                let dataToFind = event.start.format('YYYY-MM-DD');
                let $container = $(`.fc-bg td[data-date='${dataToFind}'], .fc-content-skeleton td[data-date='${dataToFind}']`);
                $container.addClass("mn-bd-day");
                return $(null);
            }
        }

        function renderUnavailability(event, elem) {
            if (!event.is_permanent) {
                let eventStart = event.start.clone();
                let startDate = moment.utc(event['start_date'], dateFormat).add(-1, 'd').set({hour: 23, minute: 59});
                let endDate = moment.utc(event['end_date'], dateFormat).add(1, 'd').set({hour: 0, minute: 0});

                if (!eventStart.isBetween(startDate, endDate)) {
                    return $(null);
                }
            }

            if (!isMonthView()) {
                elem.addClass("mn-unavailable");

                let $inner = $interpolate(UNAVAILABLE_EVENT_ELEMENT)(event);

                let timeSlot = event.end.diff(event.start, 'minutes');
                if (timeSlot === slot_duration) elem.addClass("mn-small-unavailable");

                elem.on('mousedown click', stopPropagation);

                elem.html($inner);

                return elem;
            }
            else {
                if (event['is_all_day'] || event['allDay']) {
                    let dataToFind = moment(event.start);
                    let $content = $(".fc-content-skeleton td[data-date='" + dataToFind.format('YYYY-MM-DD') + "']");

                    $(".fc-bg td[data-date='" + dataToFind.format('YYYY-MM-DD') + "']")
                        .attr("data-id", event.id)
                        .addClass('mn-unavailable-day');

                    $content
                        .addClass("mn-unavailable-day")
                        .html(
                            $('<span/>')
                                .append(dataToFind.format("DD"))
                        )
                        .append(
                            $("<div class='mn-unavailable-title'/>")
                                .append(event.title)
                        );

                    return $(null);
                }
                else {
                    elem.on('mousedown click', stopPropagation);
                    elem.addClass("mn-unavailable-month-event");
                    elem.html(
                        $interpolate(UNAVAILABLE_MONTH_ELEMENT)(event)
                    );

                    return elem;
                }

            }
        }

        function renderPause(event, elem) {
            if (!isMonthView()) {
                let $inner = $interpolate(PAUSE_EVENT_ELEMENT)(event);
                elem.addClass("mn-pause");
                let timeSlot = event.end.diff(event.start, 'minutes');
                if (timeSlot == slot_duration) elem.addClass("mn-small-pause");

                elem
                    .on('mousedown', stopPropagation)
                    .on('click', function (e) {
                        e.stopPropagation();
                        vm.callback(null, event, e);
                    });

                elem.html($inner);

                $(".dots-button > .mdi.mdi-dots-vertical", elem)
                    .on('click', showMenu)

                return elem;
            }
            else {
                elem.addClass("mn-pause-month-event");
                elem.html(
                    $interpolate(PAUSE_MONTH_ELEMENT)(event)
                );

                $(".dots-button > .mdi.mdi-dots-vertical", elem)
                    .on('click', showMenu);

                return elem;
            }
        }

        function renderRdv(event, $elem, viewName) {
            $elem.addClass('mn-rdv');

            $elem.addClass(event.resourceIds.join(' '));
            $elem.attr('event-date', event.start.format('YYYY-MM-DD'));

            event.date = event.start.format(dateFormat);

            if (_.isNull(event.end)) {
                event.end = event.start.clone().add(slot_duration, 'm');
            }

            if (event.is_entered) {
                event.startEditable = false;
                event.durationEditable = false;
            }

            if (viewName === 'listWeek' || viewName == 'listDay') return handleList();
            else return HandleRegularView();

            function handleList() {
                $elem.addClass('mn-list-item');


                let alertTpl = `
                    <mn-patient-alert patient="${event.patient_id}" class="calendar-patient-alert"></mn-patient-alert>
                `

                let compiledDirective = $compile(alertTpl);
                let directiveElement = compiledDirective($scope);

                $elem.prepend(`
                    <td class="mn-list-item-picture">
                        <img src="${getPatientImageSrc()}" />
                    </td>
                `);

                let $titleContainer = $('<div class="mn-list-title-container" />')
                    .append(`
                        <p class="mn-list-comment">
                            <a class="mn-list-title">${event.title}</a>
                            ${event.other_comment ? ' - ' + event.other_comment : ''}
                        </p>
                    `)
                    .append(directiveElement)
                    .append(`
                        <div class="dots-button">
                            <span class="mdi mdi-dots-vertical"></span>
                        </div>
                    `);

                $('.fc-list-item-title', $elem)
                    .html($titleContainer);

                let $eventDot = $('.fc-list-item-marker', $elem).html();
                let $eventDotContainer = $('<div class="mn-event-dot-container" />').append($eventDot);
                $('.fc-list-item-marker', $elem).html($eventDotContainer);

                attachEvent();

                return $elem;
            }

            function HandleRegularView() {
                let $inner = $interpolate(RDV_EVENT_ELEMENT)(event);
                $elem.html($inner);

                //$elem.addClass(event['reason_class']);
                //$elem.addClass(event['doctor_class']);

                attachEvent();

                return $elem;
            }

            function attachEvent() {
                $('.rdv-data > .patient-block, .fc-list-item-title > a', $elem)
                    .on('click', patientInfo);

                $('.mn-list-item-picture > img', $elem)
                    .on('click', openFullImage)
                    .on('error', imageError)

                $(".dots-button > .mdi.mdi-dots-vertical", $elem)
                    .on('click', showMenu)
                    .on('mouseenter', mouseEnter);
            }

            function patientInfo(ev) {
                stopPropagation(ev);
                patientDetail(event.id, ev);
            }

            function openFullImage(ev) {
                stopPropagation(ev);

                const file = {name: '', mime: 'image/png', url: getPatientImageSrc()};

                const dialog = _.assign({}, VISUALIZE_FILE, {
                    targetEvent: ev,
                    locals: {files: _.castArray(file), fileIndex: 0, allowEdit: false}
                });

                $mdDialog.show(dialog);
            }

            function mouseEnter() {
                if (_.isObject(killTimeout)) $timeout.cancel(killTimeout);
            }

            function getPatientImageSrc() {
                return `/api/patient/${event.patient_id}/data/?auth=${$auth.getToken()}`;
            }

            function imageError() {
                $(this).hide();
            }
        }

        function handleNotification() {
            if (!_.isNull($calendar)) {
                $calendar.fullCalendar('refetchEvents');
            }

            frontDeskService.getWaitingList(vm.filter).then(WaitingListDone);
        }

        function isMonthView() {
            return $calendar.fullCalendar('getView').name === "month";
        }

        function toggleWaitingList() {
            $waitingNav.toggleClass('md-locked-open');
            $waitingNav.toggleClass('md-closed');
        }

        function showMenu(e) {
            stopPropagation(e);

            let menuElement = _.isEmpty($(this).parents('.mn-rdv')) ? BASIC_MENU_ELEMENT : RDV_MENU_ELEMENT;
            let eventId = $(event.target).parents('.mn-event').data('event');

            frontDeskService.roomSubject
                .subscribe(done);

            function done(data) {
                vm.rooms = _.filter(data, {type: 'WR'});
                menuCtrl.open(e, prepareMenu(menuElement, eventId)).then(ref => panelRef = ref);
            }

        }

        function stopPropagation(e) {
            e.stopPropagation();
        }

        function closePanel() {
            panelRef && panelRef.hide();
        }

        // Calendar menu Methods
        vm.editEvent = editEvent;
        vm.createEntry = createEntry;
        vm.deleteEvent = deleteEvent;
        vm.toWaitingList = toWaitingList;
        vm.patientFile = patientFile;
        vm.patientDetail = patientDetail;
        vm.patientDental = patientDental;
        vm.patientLastVisit = patientLastVisit;
        vm.addNewAlert = addNewAlert;
        vm.addNewNotification = addNewNotification;

        function getEventObj(event) {
            let $waitingElem = $(`.mn-waiting-event[data-event="${event}"]`);
            return _.isEmpty($waitingElem) ? _.head($calendar.fullCalendar('clientEvents', event)) : $waitingElem.data('event');
        }


        function editEvent(event, jsEvent) {
            closePanel();

            let e = getEventObj(event);
            vm.callback(null, e, jsEvent);
        }

        function createEntry(event, stat, room) {
            closePanel();

            let e = _.head($calendar.fullCalendar('clientEvents', event));

            frontDeskService.createEntryFromCalendar(e, stat, room)
                .then(doneCallback);

            function doneCallback(data) {
                practiceService.entryAdded(data.entry);
                handleNotification(data.appointment);

                mnWebSocket.pub("frontdesk.Practice.entry_added", data.entry);
                mnWebSocket.pub("frontdesk.Calendar.notify", data.appointment);
            }
        }

        function toWaitingList(event) {
            closePanel();

            const data = {date: null, start_time: null, end_time: null, ignore: true, is_waiting_room: true};

            frontDeskService.partialUpdateAppointment(event, data)
                .then(successNotify);
        }

        function deleteEvent(event) {
            closePanel();

            frontDeskService.removeAppointment(event)
                .then(successNotify);
        }

        function patientFile(event) {
            closePanel();

            let eventObj = getEventObj(event);
            $state.go("app.patient-form", {'patient_id': _.get(eventObj, 'patient_id')});
        }

        function patientDetail(event, ev) {
            closePanel();

            let eventObj = getEventObj(event);

            const locals = {
                onlyResume: true,
                'patient-id': _.get(eventObj, 'patient_id')
            };

            frontDeskService.entryResume(locals, ev);
        }

        function patientDental(event) {
            closePanel();
            let eventObj = getEventObj(event);
            $state.go('app.patient-dental', {pId: _.get(eventObj, 'patient_id')});
        }

        function patientLastVisit(event) {
            closePanel();
            let eventObj = getEventObj(event);

            $state.go(this.visitLinkConfig.link, {
                visitId: eventObj.patient_last_visit_id, pId: eventObj.patient_id
            });
        }

        function addNewAlert(event, ev) {
            closePanel();
            let eventObj = getEventObj(event);
            notificationService.alertDialog(
                {id: _.get(eventObj, 'patient_id'), full_name: _.get(eventObj, 'title')}, 'SV', null, ev
            );
        }

        function addNewNotification(event, ev) {
            closePanel();

            let eventObj = getEventObj(event);
            let patient = {id: _.get(eventObj, 'patient_id'), full_name: _.get(eventObj, 'title')}

            notificationService.notificationDialog(null, patient, ev);
        }

        function prepareMenu(element, event) {
            let $element = $(element);

            let e = _.isObject(event) ? event : _.head($calendar.fullCalendar('clientEvents', event)) || {};
            let start = _.get(e, 'start', {}) || {};

            if (_.isFunction(start.format) && start.format(dateFormat) !== moment().format(dateFormat) || _.get(e, 'is_entered'))
                $('[today]', $element).remove();

            if (_.isFunction(start.format) && start.format(dateFormat) === moment().format(dateFormat) && _.get(e, 'patient_is_draft'))
                $('[is_draft]', $element).remove();

            if (e.is_pause) $('[not-pause]', $element).remove();
            if (e.is_entered || e.is_pause || e.is_waiting_list) $('[not-entered]', $element).remove();
            if (!e.patient_last_visit_id) $('[last-visit]', $element).remove();

            let $buttons = $('md-button', $element);

            $buttons.each((index, element) => {
                let $item = $(element);
                let id = _.isLength(event) ? event : _.isObject(event) ? event.id : `'${event}'`;

                if ($item.is('[ng-click]')) {
                    let ngClick = _.replace($item.attr('ng-click'), 'eventId', id);
                    $item.attr('ng-click', ngClick);
                }
                if ($item.is('[ng-mouseenter]')) {
                    let ngMouseEnter = _.replace($item.attr('ng-mouseenter'), 'eventId', id);
                    $item.attr('ng-mouseenter', ngMouseEnter);
                }
            });

            return $element;
        }
    }

})();
