(function () {

    'use strict';

    module.exports = InsuredPatientsCtrl;

    InsuredPatientsCtrl.$inject = ['system', 'statesService', '$translate', '$element'];

    function InsuredPatientsCtrl(system, statesService, $translate, $element) {
        var vm = this;

        var utils = require('../utils/activity-functions');

        vm.$onInit = init;

        vm.exportToExcel = exportToExcel;
        vm.getData = _.mnDelay(getData, 400);

        function init() {
            vm.months = moment().localeData().months();

            vm.query = {
                month: moment().month() + 1,
                year: moment().year()
            };

            getData();
        }

        function getData() {
            vm.promise = statesService.getInsuredPatients(vm.query)
                .then(success);

            function success(data) {
                $("#output-patient-insured", $element).pivot(data, {
                    rows: _.castArray($translate['instant']("state_organization_name")),
                    cols: [],
                    derivedAttributes: keysToShow(),
                });

                $("#output-visit-insured", $element).pivot(data, {
                    rows: _.castArray($translate['instant']("state_organization_name")),
                    cols: [],
                    aggregator: aggregator,
                    derivedAttributes: keysToShow(),
                });
            }
        }

        function keysToShow() {
            var keysToShow = {};

            keysToShow[$translate['instant']("state_organization_name")] = organizationName();
            keysToShow[$translate['instant']("state_organization_patient_count")] = directShow();
            keysToShow[$translate['instant']("state_organization_visit_count")] = showVisitCountField();

            return keysToShow;

            function organizationName() {
                return function (record) {
                    return record["organization_name"];
                }
            }

            function directShow() {
                return function (record) {
                    return _.get(record, 'state_organization_patient_count', 0);
                }
            }

            function showVisitCountField() {
                return function (record) {
                    return $translate['instant']("state_organization_visit_count");
                }
            }
        }

        function aggregator(data, rowKey, colKey) {
            return {
                sum: 0,
                push: function (elem) {
                    return this.sum += elem['visit_count'];
                },
                value: function () {
                    return this.sum;
                },
                format: $.pivotUtilities.numberFormat({
                    digitsAfterDecimal: 0,
                    thousandsSep: " ",
                    decimalSep: ",",
                    showZero: true
                })
            };
        }

        function exportToExcel(element, title) {
            utils.exportToExcel(
                element,
                title,
                vm.query,
                $translate
            )
        }
    }

})();
