/**
 * Created by Hp on 02/03/2017.
 */
(function () {
    'use strict';

    module.exports = CreditNoteCtrl;

    CreditNoteCtrl.$inject = ["patientService", "$transition$", "$scope", "stockService", "system", "$state", "$mdToast", "$translate", "$mdDialog","$q", "configService"];

    function CreditNoteCtrl(patientService, $transition$, $scope, stockService, system, $state, $mdToast, $translate, $mdDialog,$q, configService) {
        var vm = this;

        var currentcCreditNote= $transition$.params('to')["creditNoteId"];
        var dateFormat = system['date_format'].js;
        vm.$onInit = init;
        vm.submit = submit;
        vm.invalidateCreditNote = invalidateCreditNote;
        vm.validateCreditNote = validateCreditNote;
        vm.removeErrorLines = removeErrorLines;
        vm.save = save;

        function init() {
            vm.is_new = !_.isNil(currentcCreditNote) ? false : true;
            $scope.$watch('vm.creditNote.is_valid', readOnlyCreditNote);
            vm.docType = 'CreditNote';
            vm.modelName="CreditNote";

            vm.promises = [ configService.get(["price_mode"], true)];

            if (!_.isNil(currentcCreditNote)) vm.promises.push(stockService.getStockDocument(currentcCreditNote, 'credit-note') );
            else   vm.creditNote = {doc_date: moment().format(dateFormat), lines: [],  type:'DISCOUNT'};
            vm.promise = $q.all(vm.promises).then(success);
            function success(data) {
                if (!_.isNil(currentcCreditNote)) vm.creditNote = data[1];
                else{
                    vm.creditNote.price_mode = data[0];
                }
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success, error);
            }
            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else saveSuccess(data);
            }
            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.creditNote = data;
            if (_.isNil(currentcCreditNote)) $state.go('app.stock.credit-note-form', {creditNoteId: data.id}, {location: 'replace'});
        }


        function invalidateCreditNote() {
            return stockService.invalidateMovement(vm.creditNote, 'CreditNote', vm.docType);
        }

        function validateCreditNote(data) {
            return stockService.validateMovement(data, 'CreditNote', vm.docType);
        }

        function readOnlyCreditNote() {
            vm.readOnly = (vm.creditNote && vm.creditNote.is_valid) ? vm.creditNote.is_valid : false;
        }

        function save() {
            return stockService.saveStockDocument(vm.creditNote, "credit-note", 'CreditNote');
        }
        function removeErrorLines() {

        }

    }
})
();
