//noinspection UnterminatedStatementJS
/**
 * Created by BETALOS on 16/08/2016.
 */
(function () {
    'use strict';

    $.fn.focusEnd = function () {
        $(this).focus();
        let tmp = $('<span />').appendTo($(this));
        let node = tmp.get(0);
        let range = null;
        let sel = null;

        if (document.selection) {
            range = document.body.createTextRange();
            range.moveToElementText(node);
            range.select();
        } else if (window.getSelection) {
            range = document.createRange();
            range.selectNode(node);
            sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }
        tmp.remove();
        return this;
    };

    $.fn.selectContentEditable = function () {
        const element = $(this);
        const node = element.get(0);

        // select all text in contenteditable
        // see http://stackoverflow.com/a/6150060/145346
        let range;
        let selection;

        if (document.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(node);
            range.select();
        } else if (window.getSelection) {
            selection = window.getSelection();
            range = document.createRange();
            range.selectNodeContents(node);
            selection.removeAllRanges();
            selection.addRange(range);
        }

        return this;
    };

    $.fn.setCursorPosition = function (position) {
        if (this.length === 0) return this;
        return $(this).setSelection(position, position);
    };

    $.fn.setSelection = function (selectionStart, selectionEnd) {
        if (this.length === 0) return this;
        let input = this[0];

        if (input.createTextRange) {
            let range = input.createTextRange();
            range.collapse(true);
            range.moveEnd('character', selectionEnd);
            range.moveStart('character', selectionStart);
            range.select();
        } else if (input.setSelectionRange) {
            input.focus();
            input.setSelectionRange(selectionStart, selectionEnd);
        }

        return this;
    };

    $.fn.focusInputEnd = function () {
        this.setCursorPosition(this.val().length);
        return this;
    };

    function jqueryInvoke() {
        let arr = arguments[0];
        let invokedFunction = arguments[1];
        let args = _.slice(arguments, 2);

        if (_.isFunction($.fn[invokedFunction])) {
            return _.invokeMap(arr, function (args) {
                let result = $.fn[invokedFunction].apply($(this), args);
                return (result.jquery) ? true : result;
            }, args)
        } else {
            return null;
        }
    }

    function mnDelay() {
        let id = null;
        let args = arguments;

        return function () {
            if (arguments.length > 0) {
                args = _.chain(args)
                    .slice(0, 2)
                    .concat(arguments)
                    .value();
            }
            if (!_.isNull(id)) clearTimeout(id);

            id = _.delay.apply(this, args);
        }
    }

    function stringFormat() {
        let args = _.slice(arguments, 1);
        let string = arguments[0];

        if (!_.isString(string)) return false;
        if (_.isObject(args[0])) {
            args = args[0];
        } else if (!_.isArray(args) || args.length === 0) return string;

        return _.reduce(_.keys(args), function (result, value) {
            return _.replace(result, new RegExp('{(' + value + ')}', 'g'), function (m, k) {
                return _.isNil(args[k]) ? "" : args[k];
            });
        }, string);
    }

    function objectToQueryString(params) {
        const result = [];

        if (Object.keys(params).length > 0) {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const keyValue = params[key];
                    if (keyValue) {
                        switch (keyValue.constructor.name) {
                            case 'Array':
                                keyValue.forEach(value => {
                                    result.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
                                });
                                break;
                            case 'Object':
                                Object.entries(keyValue).map(([k, v]) => {
                                    if (v) {
                                        result.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
                                    }
                                });
                                break;
                            default:
                                result.push(`${encodeURIComponent(key)}=${encodeURIComponent(keyValue)}`);
                        }
                    }
                }
            }
        }

        return result.join('&');
    }

    function pushOrUpdate() {
        let arr = arguments[0];
        let item = arguments[1];
        let key = arguments[2] || 'id';

        if (item) {
            let index = _.findIndex(arr, {[key]: _.get(item, key, null)});

            if (index === -1) arr.push(item);
            else arr.splice(index, 1, item);
        }

        return arr;
    }

    function momentFormat(obj, format) {
        if (window.moment.isMoment(obj)) return obj.format(format);
        if (_.isString(obj)) return obj;
    }

    _.mixin({
        jqInvoke: jqueryInvoke,
        mnDelay: mnDelay,
        format: stringFormat,
        qs: objectToQueryString,
        pushOrUpdate: pushOrUpdate,
        momentFormat: momentFormat
    });

})();
