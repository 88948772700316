/**
 * Created by amine on 13/08/2018.
 */
(function () {
    class ExternalConnectionService {
        constructor(configService, $q, mnWebSocket, $http, $mdDialog) {
            this.configService = configService;
            this.ws = mnWebSocket;
            this.$q = $q;
            this.$http = $http;
            this.$mdDialog = $mdDialog;
        }

        static get $inject() {
            return ["configService", "$q", "mnWebSocket", "$http", "$mdDialog"];
        }

        get(key) {
            return this.configService.get(key);
        }

        set(config) {
            const event = "shared.Config.set_secured";
            const mine = true;
            const speciality = true;

            return this.configService.set(config, mine, speciality, event);
        }

        shareDocument(group, model, uid, qs, message, title, viewSet) {
            if (_.isNil(viewSet)) viewSet = group;
            let deferred = this.$q.defer();

            this.$http.post(`/api/${viewSet}/${model}/share/`, {group, uid, message, title}).then(
                result => deferred.resolve(result.data),
                result => deferred.reject(result)
            );

            return deferred.promise;
        }

        sendConsoleMessage(message) {
            return this.ws.call("shared.External.send_console_message", message)
        }

        getSMSModels() {
            const deferred = this.$q.defer();

            this.$http.get("/api/sms-model/").then(
                result => deferred.resolve(result.data),
                result => deferred.reject(result)
            );

            return deferred.promise;
        }

        saveSMSModel(model) {
            const deferred = this.$q.defer();

            this.$http.post(`/api/sms-model/${_.isNil(model.id) ? '' : model.id + '/'}`, model)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        deleteSMSModel(model) {
            const deferred = this.$q.defer();

            this.$http.delete(`/api/sms-model/${model.id}/`, model)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        showSMSDialog($event, wsEvent, query) {
            const dialog = require('shared/dialogs/sms-sending-dialog');

            return this.$mdDialog.show(_.assign({}, dialog, {
                targetEvent: $event,
                locals: {
                    event: wsEvent,
                    query: query
                }
            }))
        }

        sendSms(event, query) {
            return this.ws.call(`shared.External.${event}`, query)
        }

        getHistory(query) {
            return this.ws.call("shared.External.history", query)
        }

        getEmailModels() {
            const deferred = this.$q.defer();

            this.$http.get("/api/email-model/").then(
                result => deferred.resolve(result.data),
                result => deferred.reject(result)
            );

            return deferred.promise;
        }

        saveEmailModel(model) {
            const deferred = this.$q.defer();

            this.$http.post(`/api/email-model/${_.isNil(model.id) ? '' : model.id + '/'}`, model)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        deleteEmailModel(model) {
            const deferred = this.$q.defer();

            this.$http.delete(`/api/email-model/${model.id}/`, model)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        getConsentModels() {
            const deferred = this.$q.defer();

            this.$http.get("/api/consent-model/").then(
                result => deferred.resolve(result.data),
                result => deferred.reject(result)
            );

            return deferred.promise;
        }

        saveConsentModel(model) {
            const deferred = this.$q.defer();

            this.$http.post(`/api/consent-model/${_.isNil(model.id) ? '' : model.id + '/'}`, model)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        deleteConsentModel(model) {
            const deferred = this.$q.defer();

            this.$http.delete(`/api/consent-model/${model.id}/`, model)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }
    }

    module.exports = ExternalConnectionService;
})();