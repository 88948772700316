/**
 * Created by Betalos on 27/06/2017.
 */
(function () {
    'use strict';

    module.exports = {
        tableMain: require('./components/table-main'),
        columnMenu: require('./components/column-menu'),
        thRow: require('./directives/th-row'),
        thSearch: require('./directives/th-search'),
        tableService: require('./services/table-service')
    };

})()