/**
 * Created by BETALOS on 10/07/2017.
 */
(function () {

    'use strict';

    const {Subject} = require("rxjs");
    const MODEL_DIALOG = require('../dialogs/dictionary-models-dialog');
    const NEW_ENTRY_DIALOG = require('../dialogs/dictionary-new-entry-dialog');

    class FreeDictionaryCtrl {
        constructor(dictionaryService, $element, $scope, $mdDialog, consultationService) {
            this.scope = $scope;
            this.dialog = $mdDialog;
            this.element = $element;
            this.dictionaryService = dictionaryService;
            this.consultationService = consultationService;

            this.field = this.field || null;
            this.readonly = this.readonly || false;

            this.selectedText = "";
            this.isRequired = $element.is('[required]');
            this.isStandAlone = this.element.is('[stand-alone]');

            this.model = this.isStandAlone ? "" : {};

            this.listnerSubject = new Subject();
            this.listnerSubscription = this.listnerSubject.subscribe(items => this.handleNode(items));
        }

        static get $inject() {
            return ["dictionaryService", "$element", "$scope", "$mdDialog", "consultationService"];
        }

        $onInit() {
            this.ngModelController = this.ngModelController ? this.ngModelController : null;
            this.ngModelController.$render = () => this.renderCurrentValue();

            this.dictUid = _.get(this.field, 'meta_data.dict_uid', '');
            this.readOnly = _.isFunction(this.readonly) ? this.readonly() : false;

            this.element.on('focusin', '.md-input', () => this.focus());
            this.element.on('focusout', '.md-input', ev => this.focusOut(ev));

            const mouseUpFunc = _.mnDelay(() => this.mouseUp(), 200);
            this.element.on('mouseup', '.md-input', () => mouseUpFunc());
            this.element.on('mouseout', '.md-input', () => mouseUpFunc());

            this.treeHandler = {
                title: this.dictUid,
                subject: this.listnerSubject
            }
        }

        $onDestroy() {
            this.element.off('focusin', '.md-input');
            this.element.off('focusout', '.md-input');
            this.element.off('mouseup', '.md-input');
            this.element.off('mouseout', '.md-input');

            this.listnerSubject.complete();
            this.listnerSubscription.unsubscribe();
        }

        emptyField() {
            this.model = this.isStandAlone ? "" : {};
            this.triggerChange();
        }

        isEmpty() {
            return this.isStandAlone ? _.isEmpty(this.model) : this.model && _.isEmpty(this.model.value);
        }

        viewModels(ev) {
            this.dialog.show(_.assign({}, MODEL_DIALOG, {
                locals: {
                    uid: this.dictUid,
                    modelsShown: true
                },
                targetEvent: ev
            })).then(nodes => {
                nodes.forEach(item => this.pushValue(item));
                this.triggerChange();
            });
        }

        addToDict(ev) {
            this.dialog.show(_.assign({}, NEW_ENTRY_DIALOG, {
                targetEvent: ev,
                locals: {
                    uid: this.dictUid,
                    newEntry: {value: this.selectedText}
                }
            })).then(() => this.selectedText = "");
        }

        focus() {
            if (!this.dictUid) return;

            this.consultationService.lockSubject.next({lock: false});
            this.dictionaryService.dictModelSubject.next(this.treeHandler);
        }

        handleNode(nodes) {
            if (_.isArray(nodes)) nodes.forEach(item => this.pushValue(item));
            else this.pushValue(nodes);

            this.triggerChange();
        }

        pushValue(node) {
            const dictSeparator = _.get(this.dictionaryService, 'dictionaryConfig.dict_separator', '');
            const hasValue = this.isStandAlone ? _.isEmpty(this.model) : _.chain(this.model).get('value', '').isEmpty().value();

            const newValue = this.newValue(node);
            const itemsSeparator = dictSeparator != '\n' ? " " : "";
            const oldValue = this.isStandAlone ? this.model : _.get(this.model, 'value', '');
            const value = hasValue ? newValue : `${oldValue} ${dictSeparator}${itemsSeparator}${newValue}${itemsSeparator}`;

            this.model = this.isStandAlone ? value : _.set({}, 'value', value);
        }

        newValue(node) {
            return `${_.get(this.dictionaryService, 'dictionaryConfig.is_long_value') ? node.parent : ''}${node.value}`;
        }

        mouseUp() {
            if (this.readOnly || !this.dictUid) return;

            this.selectedText = (document.all) ? document.selection.createRange().text : document.getSelection().toString();
            this.scope.$applyAsync();
        }

        focusOut(ev) {
            const $relatedTarget = $(ev.relatedTarget);
            const $parents = $relatedTarget.parents(this.element);

            if (_.isEmpty($parents)) {
                this.selectedText = "";
                this.scope.$applyAsync();
            }
        }

        triggerChange() {
            let viewValue = _.cloneDeep(this.model);

            this.ngModelController.$setViewValue(viewValue, 'change');
            this.ngModelController.$commitViewValue();
        }

        renderCurrentValue() {
            this.model = this.ngModelController.$viewValue;
        }
    }

    tpl.$inject = ['$element'];

    function tpl($element) {
        const isStandAlone = $element.is('[stand-alone]');
        const tpl = require('stand-alone/blocks/views/free-dictionary.tpl.html');
        const $tpl = $(tpl);

        const isRequired = $element.is('[required]');

        if (isStandAlone) {
            $('textarea, mn-voice-recognition', $tpl).attr('ng-model', 'vm.model');
        }

        if (isRequired) {
            $('textarea', $tpl).attr('required', 'required');
        }

        return $tpl.prop('outerHTML');
    }

    module.exports = {
        template: tpl,
        bindings: {
            readonly: "&?",
            field: "<mnField"
        },
        controllerAs: "vm",
        controller: FreeDictionaryCtrl,
        require: {ngModelController: "ngModel"},
    };


})();
