/**
 * Created by Amine on 30/11/2016.
 */
(function () {
    'use strict';

    module.exports = medicineService;

    medicineService.$inject = ["$q", "mnWebSocket", "$http"];

    function medicineService($q, mnWebSocket, $http) {
        let self = this;
        self.getMedicines = getMedicines;
        self.getMedicine = getMedicine;
        self.saveMedicine = saveMedicine;
        self.removeMedicine = removeMedicine;

        self.addPosology = addPosology;
        self.getPosologies = getPosologies;
        self.savePosologies = savePosologies;
        self.saveDefault = saveDefault;
        self.bookmarkMedicine = bookmarkMedicine;
        self.disableMedicine = disableMedicine;
        self.getDefault = getDefault;

        self.getMedicinesList = getMedicinesList;
        self.getBookmarkedIdsList = getBookmarkedIdsList;

        //self.getMedcines = getMedcines;

        function getMedicines(query) {
            let obj = {
                query: query.searchText,
                inn: query.inn,
                laboratory: query.laboratory,
                therapeutic_class: query.therapeutic_class,
                medicine_category: query.medicine_category,
                product_type: query.product_type,
                type: query.type
            };
            return mnWebSocket.call("shared.Medicine.search", obj);
        }


        function getMedicine(pk, arg = null) {
            // let deferred = $q.defer();
            //
            // $http.get("/api/medicine" + (is_mn ? "-mn" : "-ud") + "/" + pk + "/")
            //     .then(success, error);
            //
            // function success(result) {
            //     deferred.resolve(result.data);
            // }
            //
            // function error(data) {
            //     deferred.reject(data);
            // }
            //
            // return deferred.promise;
            return mnWebSocket.call("shared.Medicine.get_by_id", {pk});
        }

        function handleArMedicine(medicine) {
            if (!_.isNil(medicine['commercial_name_ar']) && !_.isEmpty(medicine['commercial_name_ar'])) {
                let dosageForm = _.get(medicine, "dosage_form.ar_instance.id");
                let data = {
                    mn_ar_id: null,
                    commercial_name: medicine['commercial_name_ar'],
                    dosage_form: _.isNil(dosageForm) ? null : {id: dosageForm}
                }

                if (_.isNil(medicine['ar_instance'])) medicine['ar_instance'] = data
                else medicine['ar_instance'] = _.assign(medicine['ar_instance'], data);
            } else {
                medicine['ar_instance'] = null;
            }

            return medicine
        }

        function saveMedicine(medicine) {
            let deferred = $q.defer();
            let view = "ud";

            if (medicine.is_mn) view = "mn";
            else if (medicine.is_para_product) view = "para";

            let url = `/api/medicine-${view}/${(_.isUndefined(medicine.id) ? "" : medicine.id + "/")}`;

            medicine = handleArMedicine(medicine);

            $http.post(url, medicine).then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function removeMedicine(medicine) {
            let deferred = $q.defer();

            $http.delete(`/api/medicine/${medicine.id}/`, medicine)
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function getPosologies(medicine) {
            return mnWebSocket.call("shared.Medicine.get_saved_pos", _.pick(medicine, 'id'));
        }

        function savePosologies(medicine, posologies) {
            let query = _.pick(medicine, 'id')
            query['posologies'] = posologies;
            return mnWebSocket.call("shared.Medicine.set_saved_pos", query);
        }

        function addPosology() {
            let data = {}
            if (arguments.length == 1) {
                const detail = arguments[0];
                data = {id: detail.medicine.id, text: _.get(detail, "text", ""), text_ar: _.get(detail, "text_ar", "")}
            } else if (arguments.length == 2) {
                const medicine = arguments[0];
                const detail = arguments[1];
                data = {id: medicine.id, text: _.get(detail, "text", ""), text_ar: _.get(detail, "text_ar", "")}
            } else {
                return $q.defer().promise;
            }

            return mnWebSocket.call("shared.Medicine.add_pos", data);
        }

        function saveDefault(detail) {
            let data = {id: detail.medicine.id, text: detail.text, text_ar: detail.text_ar}
            return mnWebSocket.call("shared.Medicine.save_def_pos", data);
        }

        function getDefault(medicine) {
            return mnWebSocket.call("shared.Medicine.get_def_pos", _.pick(medicine, 'id'));
        }

        function bookmarkMedicine(medicine) {
            return mnWebSocket.call("shared.Medicine.bookmarked", _.pick(medicine, 'id'));
        }

        function disableMedicine(medicine) {
            let deferred = $q.defer();
            let url = "/api/medicine/" + medicine.id + "/"

            $http.put(url, {is_disabled: !medicine.is_disabled}).then((result) => {
                deferred.resolve(result.data);
            }, deferred.reject);

            return deferred.promise;
        }

        function getBookmarkedIdsList() {
            return mnWebSocket.call("shared.Medicine.setup_list_bookmarked");
        }

        function getMedicinesList(query) {
            return mnWebSocket.call("shared.Medicine.setup_list", query);
        }

        /*function getMedcines(name) {
            return mnWebSocket.call("shared.Medicine.search_by_name", {name:name});
        }*/
    }

})();
