(function () {
    'use strict';

    module.exports = ConsignmentCtrl;

    ConsignmentCtrl.$inject = ["purchaseService", "$transition$", "$scope", "stockService", "system", "$state", "$mdToast", "$translate", "$mdDialog", "$q", "configService"];

    function ConsignmentCtrl(purchaseService, $transition$, $scope, stockService, system, $state, $mdToast, $translate, $mdDialog, $q, configService) {

        var vm = this;
        var currentConsignment = $transition$.params('to')["consignmentId"];
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.submit = submit;
        vm.invalidateConsignment = invalidateConsignment;
        vm.validateConsignment = validateConsignment;
        vm.isAllValid = isAllValid;
        vm.save = save;
        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;
        vm.contactCallback = contactCallback;


        function init() {
            vm.is_new = !_.isNil(currentConsignment) ? false : true;
            vm.docType = 'Consignment';
            vm.modalFilter = {is_deleted: {$ne: true}, is_valid: {$ne: false}};
            $scope.$watch('vm.consignment.is_valid', readOnlyConsignment);

            vm.promises = [configService.get(["price_mode"], true)];
            if (!_.isNil(currentConsignment)) vm.promises.push(stockService.getStockDocument(currentConsignment, 'consignment'));
            else vm.consignment = {
                price_mode: 'HT',
                doc_date: moment().format(dateFormat),
                start_date: moment().format(dateFormat),
                lines: []
            };
            vm.promise = $q.all(vm.promises).then(success);

            function success(data) {
                if (!_.isNil(currentConsignment)) vm.consignment = data[1];
                else vm.consignment.price_mode = data[0];
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success, error);
            }

            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else saveSuccess(data);
            }

            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.consignment = data;
            if (_.isNil(currentConsignment)) $state.go('app.stock.consignment-form', {consignmentId: data.id}, {location: 'replace'});
        }

        function invalidateConsignment() {
            return stockService.invalidateMovement(vm.consignment, 'Consignment', 'OUT');
        }

        function validateConsignment(data) {
            return stockService.validateMovement(data, 'Consignment', 'OUT');
        }

        function readOnlyConsignment() {
            vm.readOnly = (vm.consignment && vm.consignment.is_valid) ? vm.consignment.is_valid : false;
        }

        function isAllValid() {
            return vm.consignment ? stockService.isAllValid(vm.consignment, vm.docType, vm.modelName) : true;
        }

        function save() {
            return stockService.saveStockDocument(vm.consignment, "consignment", 'Consignment');
        }

        function patientCallback(patient) {
            vm.consignment.patient = patient;
        }
        function contactCallback(data) {
            vm.consignment.related_doc = data;
        }

        function removePatient() {
            vm.consignment.patient = null;
        }

    }
})
();
