/**
 * Created by Betalos on 27/06/2017.
 */
(function () {

    'use strict';

    module.exports = {
        controller: ColumnMenuCtrl,
        controllerAs: "vm",
        bindings: {
            columns: "="
        },
        template: require('stand-alone/table/views/table-column-menu.html')
    };

    ColumnMenuCtrl.$inject = ["$scope", "tableTestService"];

    function ColumnMenuCtrl($scope, tableTestService) {
        var vm = this;

        vm.$onInit = init;
        vm.handleColumn = handleColumn;

        function init() {
            $scope.$on('md-menu-one.drop-model', orderChanged);
        }

        function handleColumn(column) {
            if (!_.isUndefined(column)) column['hidden'] = !column['hidden'];

            tableTestService.columnChangeSubject.next();
        };

        function orderChanged() {
            tableTestService.columnChangeSubject.next();
        }
    }

})();