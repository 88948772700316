/**
 * Created by BETALOS on 18/12/2015.
 */
(function () {

    'use strict';

    const VISIT_SUB_LINKS = require('parameters/json/visit-sub-links.json');
    const REMOVING_VISIT_DIALOG = require('visit/dialogs/removing-visit-dialog');

    class VisitListCtrl {
        constructor($state, paymentService, billingService, visitService, careSheetService, $mdDialog, $translate) {
            this.$state = $state;
            this.dialog = $mdDialog;
            this.$translate = $translate;
            this.visitService = visitService;
            this.paymentService = paymentService;
            this.billingService = billingService;
            this.careSheetService = careSheetService;

            this.filter = {is_deleted: {"$ne": true}};
        }

        static get $inject() {
            return [
                "$state", "paymentService", "billingService", "visitService", "careSheetService", "$mdDialog",
                "$translate"
            ];
        }

        $onInit() {
            this.visitService.visitSubLinks.subscribe(data => {
                let linkConfig = _.find(VISIT_SUB_LINKS, ['key', data.favorite]);

                this.actions = {
                    single: [
                        {
                            icon: 'mdi-pencil',
                            label: 'edition',
                            resource: 'visit',
                            action: 'update',
                            behavior: 'disable',
                            method: (v, e) => this.goToVisit(v, e, linkConfig.link)
                        },
                        {
                            icon: 'mdi-currency-usd',
                            label: 'pay_visit',
                            resource: 'payment',
                            action: 'create',
                            behavior: 'disable',
                            disabled: "item.total_amount <= 0",
                            method: (v, e) => this.payVisit(v, e)
                        },
                        {
                            icon: 'mdi-receipt',
                            label: 'billing_visit',
                            resource: 'billing-invoice',
                            action: 'create',
                            behavior: 'disable',
                            method: (v, e) => this.billingService.billingVisit(v, e)
                        }, {
                            icon: 'mdi-folder-plus',
                            label: 'visit_care_sheet',
                            resource: 'visit',
                            action: 'create',
                            behavior: 'disable',
                            disabled: "item.total_amount <= 0",
                            method: (v, e) => this.careSheetService.visitCareSheet(v, e)
                        }
                    ],
                    multiple: [
                        {
                            icon: 'mdi-receipt',
                            label: 'billing_visit',
                            resource: 'billing-invoice',
                            action: 'create',
                            behavior: 'disable',
                            method: (v, e) => this.billingService.billingVisit(v, e)
                        },
                        {
                            icon: 'mdi-delete',
                            label: 'visit_delete',
                            resource: 'visit',
                            action: 'delete',
                            behavior: 'remove',
                            method: (v, e) => this.remove(v, e)
                        },
                        {
                            icon: 'mdi-eye',
                            label: 'visit_hide',
                            resource: 'visit',
                            specialPower: true,
                            method: (v, e) => this.privatize(v, e)
                        },
                        {
                            icon: 'mdi-eye-off',
                            label: 'visit_unhide',
                            resource: 'visit',
                            specialPower: true,
                            method: (v, e) => this.unprivatize(v, e)
                        }
                    ]
                };

            });
        }

        goToVisit(visit, ev, link) {
            const state = link ? link : 'app.visit.consultation';
            this.$state.go(state, {visitId: visit.id, pId: visit['patId']});
        }

        payVisit(visit, ev) {
            ev.stopPropagation();

            this.visitService.getVisit(visit.id)
                .then(visit => {
                    this.paymentService.payVisit(ev, visit);
                });
        }

        confirmDialog(ev) {
            return this.dialog.confirm()
                .targetEvent(ev)
                .ariaLabel('confirm')
                .ok(this.$translate['instant']('confirm_ok'))
                .cancel(this.$translate['instant']('confirm_cancel'))
                .title(this.$translate['instant']("visit_remove_confirm_title"))
                .htmlContent(this.$translate['instant']("visit_remove_confirm_text"));
        }

        remove(list, ev) {
            const confirm = this.confirmDialog(ev)

            this.dialog.show(confirm).then(() => {
                this.promise = this.visitService.removeVisits(list)
                    .then(err => this.removeErrors(err, ev));
            });
        }

        removeErrors(errs, ev) {
            this.refresh();

            if (!_.isEmpty(errs)) {
                this.dialog.show(_.assign({}, REMOVING_VISIT_DIALOG, {
                    targetEvent: ev,
                    locals: {
                        visits: errs
                    }
                }));
            }
        }

        privatize(list) {
            this.promise = this.visitService.privatizeVisits(list)
                .then(() => this.refresh());
        }

        unprivatize(list) {
            this.promise = this.visitService.unprivatizeVisits(list)
                .then(() => this.refresh());
        }

    }

    module.exports = VisitListCtrl;

})();
