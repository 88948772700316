/**
 * Created by BETALOS on 07/03/2017.
 */
(function () {

    'use strict';

    module.exports = MonthActivityCtrl;

    MonthActivityCtrl.$inject = ["statesService", "$translate", "$element", "$timeout", "moment"];

    function MonthActivityCtrl(statesService, $translate, $element, $timeout, moment) {
        let vm = this;
        let utils = require('../utils/activity-functions');

        vm.$onInit = init;

        vm.exportToExcel = exportToExcel;
        vm.getData = _.mnDelay(getData, 400);

        function init() {
            vm.globalTotal = 0;
            vm.months = moment().localeData().months();

            vm.query = {
                month: moment().month() + 1,
                year: moment().year()
            };

            getData();
        }

        function getData() {
            vm.promise = statesService.getMonthActivities(vm.query)
                .then(success);


            function success(data) {
                $("#output-procedures", $element).pivot(data['procedures'], {
                    rows: _.castArray($translate['instant']("patient_last_first_name")),
                    cols: _.castArray($translate['instant']("states_procedure_name")),
                    aggregator: utils.aggregatorProcedures,
                    derivedAttributes: utils.keysToShow($translate, "states_procedure_name"),
                });

                $("#output-payments", $element).pivot(data['payments'], {
                    aggregator: utils.aggregatorPay,
                    cols: _.castArray($translate['instant']("states_receipts")),
                    derivedAttributes: utils.keysToShow($translate, "states_receipts"),
                    rows: [$translate['instant']("payment_mode"), $translate['instant']("patient_last_first_name")],
                });

                $timeout(function () {
                    const procedures = $("#output-procedures .pvtGrandTotal", $element).data('value');
                    const payments = $("#output-payments .pvtGrandTotal", $element).data('value');

                    vm.proceduresLength = ($("#output-procedures tbody tr", $element).length - 1);
                    vm.paymentsLength = ($("#output-payments tbody tr", $element).length - 1);

                    vm.globalTotal = parseFloat(payments) - parseFloat(procedures);
                });
            }
        }

        function exportToExcel(element, title) {
            utils.exportToExcel(
                element,
                title,
                vm.query,
                $translate
            )
        }
    }

})();
