(function () {

    'use strict';

    module.exports = PatientStatusCtrl;

    PatientStatusCtrl.$inject = ["statesService", "$translate", "$element", "$timeout"];

    function PatientStatusCtrl(statesService, $translate, $element, $timeout) {
        var vm = this;

        vm.$onInit = init;

        var utils = require('../utils/activity-functions');

        vm.exportToExcel = exportToExcel;
        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;

        function init() {
            vm.globalTotal = 0;
        }

        function patientCallback(patient) {
            vm.promise = statesService.getPatientStatus(patient)
                .then(success);

            function success(data) {
                vm.visitCount = data['visits']
                vm.appointmentCount = data['appointments'];

                $("#output-procedures", $element).pivot(data['procedures'], {
                    rows: _.castArray($translate['instant']("patient_last_first_name")),
                    cols: _.castArray($translate['instant']("states_procedure_name")),
                    aggregator: utils.aggregatorProcedures,
                    derivedAttributes: utils.keysToShow($translate, "states_procedure_name"),
                });

                $("#output-payments", $element).pivot(data['payments'], {
                    rows: [$translate['instant']("payment_mode"), $translate['instant']("patient_last_first_name")],
                    cols: _.castArray($translate['instant']("states_receipts")),
                    aggregator: utils.aggregatorPay,
                    derivedAttributes: utils.keysToShow($translate, "states_receipts", true),
                });

                $timeout(function () {
                    var procedures = $("#output-procedures .pvtGrandTotal", $element).data('value');
                    var payments = $("#output-payments .pvtGrandTotal", $element).data('value');

                    vm.globalTotal = parseFloat(payments) - parseFloat(procedures);
                });
            }
        }

        function removePatient() {

        }

        function exportToExcel(element, title) {
            utils.exportToExcel(
                element,
                title,
                vm.query.patient,
                $translate
            )
        }

    }

})();
