/**
 * Created by Hp on 10/05/2017.
 */
(function () {
    'use strict';

    module.exports = FamilyCtrl;

    FamilyCtrl.$inject = ["$mdDialog", "familyService", "$mdToast", "$translate"];

    function FamilyCtrl($mdDialog, familyService, $mdToast, $translate) {
        let vm = this;
        vm.reset = null;
        vm.$onInit = init;

        vm.addFamily = addFamily;

        function init() {
            vm.filter = {is_deleted: {$ne:true}};
            vm.actions = {
                single: [
                    {
                        icon: 'mdi-pencil',
                        label: 'edition',
                        resource: 'family',
                        action: 'update',
                        behavior: 'disable',
                        method: goToEdition
                    },
                    {
                        icon: 'mdi-delete',
                        label: 'delete',
                        resource: 'family',
                        action: 'delete',
                        behavior: 'disable',
                        method: deleteFamily
                    }
                ],
                multiple: []
            }

        }

        function goToEdition(obj) {
            showFamilyDialog(null, obj);
        }

        function deleteFamily(obj) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_object_warning'))
                    .ariaLabel('delete_object_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                familyService.checkFamilyDependencies(obj.id).then(checkSuccess);
            });
            function checkSuccess(data) {
                if (data === true) familyService.deleteFamily(obj.id).then(_.isFunction(vm.reset) ? vm.reset : _.noop);
                else showWarning();

            }
        }

        function showWarning() {
            const simpleToast = $mdToast.simple()
                .textContent($translate.instant('impossible_delete'))
                .position("top right")
                .hideDelay(3000);
            $mdToast.show(simpleToast);
        }

        function addFamily(ev) {
            showFamilyDialog(ev, null);
        }

        function showFamilyDialog(ev, family) {
            const dialog = _.assign({}, require('../dialogs/family-dialog'), {
                targetEvent: ev || null,
                locals: {
                    family: _.cloneDeep(family) || null,
                    showParent_family: true
                },
                clickOutsideToClose: false,
                focusOnOpen:false
            });
            $mdDialog.show(dialog)
                .then(_.isFunction(vm.reset) ? vm.reset : _.noop);
        }


    }
})();