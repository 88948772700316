/**
 * Created by Hp on 12/05/2017.
 */
(function () {

    'use strict';

    var documentSearchDialog = {
        controller: documentSearchDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/document-search-modal.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        focusOnOpen: false
    };

    documentSearchDialogCtrl.$inject = ["$mdDialog"];

    function documentSearchDialogCtrl($mdDialog) {
        var vm = this;
        vm.cancel = $mdDialog.cancel;
        vm.selected = [];
        vm.addSelection = addSelection;
        vm.selectRow = selectRow;
        init();

        function init() {
        }

        function addSelection() {
            $mdDialog.hide(vm.selected);
        }

        function selectRow(data) {
            $mdDialog.hide(data);
        }

    }

    module.exports = documentSearchDialog;

})();