/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    'use strict';

    const angular = require('angular');


    const statesService = require("./services/statesService");

    const StatesContainerCtrl = require("./controllers/StatesContainerCtrl");

    const DayActivityCtrl = require("./controllers/DayActivityCtrl");
    const MonthActivityCtrl = require("./controllers/MonthActivityCtrl");
    const MonthActivityGraphCtrl = require("./controllers/MonthActivityGraphCtrl");
    const YearActivityCtrl = require("./controllers/YearActivityCtrl");
    const PeriodicActivityCtrl = require("./controllers/PeriodicActivityCtrl");
    const InsuredPatientsCtrl = require("./controllers/InsuredPatientsCtrl");
    const PatientsByAgeCtrl = require("./controllers/PatientsByAgeCtrl");
    const VisitReportCtrl = require("./controllers/VisitReportCtrl");
    const EntryReportGraphCtrl = require("./controllers/EntryReportGraphCtrl");
    const PatientStatusCtrl = require("./controllers/PatientStatusCtrl");
    const PregnancyDeliveryTermCtrl = require("./controllers/PregnancyDeliveryTermCtrl");
    const PathologiesCtrl = require("./controllers/PathologiesCtrl");

    const AgingBalanceCtrl = require("./controllers/AgingBalanceCtrl");
    const ClientRecoveryCtrl = require("./controllers/ClientRecoveryCtrl");

    angular
        .module("medinet")

        .service("statesService", statesService)

        .controller("StatesContainerCtrl", StatesContainerCtrl)

        .controller("DayActivityCtrl", DayActivityCtrl)
        .controller("MonthActivityCtrl", MonthActivityCtrl)
        .controller("MonthActivityGraphCtrl", MonthActivityGraphCtrl)
        .controller("YearActivityCtrl", YearActivityCtrl)
        .controller("PeriodicActivityCtrl", PeriodicActivityCtrl)
        .controller("InsuredPatientsCtrl", InsuredPatientsCtrl)
        .controller("PatientsByAgeCtrl", PatientsByAgeCtrl)
        .controller("VisitReportCtrl", VisitReportCtrl)
        .controller("EntryReportGraphCtrl", EntryReportGraphCtrl)
        .controller("PatientStatusCtrl", PatientStatusCtrl)
        .controller("PregnancyDeliveryTermCtrl", PregnancyDeliveryTermCtrl)
        .controller("PathologiesCtrl", PathologiesCtrl)
        .controller("AgingBalanceCtrl", AgingBalanceCtrl)
        .controller("ClientRecoveryCtrl", ClientRecoveryCtrl)


})();