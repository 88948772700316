/**
 * Created by BETALOS on 07/03/2017.
 */
(function () {

    'use strict';

    module.exports = DayActivityCtrl;

    DayActivityCtrl.$inject = ["statesService", "system", "$translate", "$element", "$timeout"];

    function DayActivityCtrl(statesService, system, $translate, $element, $timeout) {
        let vm = this;

        let dateFormat = system['date_format'].js;
        let utils = require('../utils/activity-functions');

        vm.$onInit = init;

        vm.getData = _.mnDelay(getData, 400);
        vm.exportToExcel = exportToExcel;

        function init() {
            vm.globalTotal = 0;

            vm.query = {
                date: moment().format(dateFormat)
            };

            getData();
        }

        function getData() {
            vm.promise = statesService.getPathologies(vm.query)
                .then(success);

            function success(data) {
                $("#output-pathologies", $element).pivot(data, {
                    rows: ["name"],
                    cols: [],
                });
            }
        }


        function exportToExcel(element, title) {
            utils.exportToExcel(
                element,
                title,
                {date: moment().format(dateFormat)},
                $translate
            )
        }
    }

})();