/**
 * Created by amine on 12/04/2018.
 */
(function () {
    'use strict';

    module.exports = {
        controller: VaccinationCalendarCtrl,
        controllerAs: 'vm',
        bindings: {
            patient: '<?mnPatient',
            postSave: '&?mnPostSave',
            postSaveSubject: '<?mnPostSaveSubject',
            readOnly: '<?mnReadOnly'
        },
        restrict: 'E',
        template: require("specifics/views/vaccination-calendar-block.tpl.html"),
    };

    VaccinationCalendarCtrl.$inject = ["$timeout", "$scope", "system", "$translate", "$q", "vaccinationService", "patientService", "$mdDialog", "frontDeskService", "configService"];

    function VaccinationCalendarCtrl($timeout, $scope, system, $translate, $q, vaccinationService, patientService, $mdDialog, frontDeskService, configService) {
        let vm = this;
        const dateFormat = system["date_format"].js;

        vm.$onInit = init;
        vm.submit = submit;
        vm.setAppointment = setAppointment;
        vm.editAppointment = editAppointment;
        vm.vaccinationDateChanged = vaccinationDateChanged;
        vm.openSettings = openSettings;
        vm.addLine = addLine;
        vm.editLine = editLine;
        vm.toggleAppearanceLine = toggleAppearanceLine;
        vm.toggleHided = toggleHided;
        vm.commentLine = commentLine;

        function init() {
            vm.showHided = false;
            vm.selectedComment = null;
            $q.all([vaccinationService.getCalendar(vm.patient), configService.get("vaccination_block_config")]).then(loadPatientCalendar)
        }

        function loadPatientCalendar(data) {
            //$scope.$applyAsync(() => {

            if (_.isArray(data)) vm.config = data[1] || {hide_appointment_column: false, fix_estimated_dates: true};

            vm.vaccination = _.isArray(data) ? data[0] : data;
            vm.hierarchy = {};

            generateCalendar();
            //});
        }

        function vaccinationDateChanged(item) {
            if (!vm.config.fix_estimated_dates) return false;

            let birth_date = moment(vm.vaccination.patient['birth_date'], dateFormat);
            let hierarchyGroup = vm.hierarchy[item.line.vaccination_ids];
            let current = _.findIndex(hierarchyGroup, ["uid", item.line.injection_uid]);
            let itemExpectedDate = moment(item.default_expected_date, dateFormat);
            let vaccinationDate = moment(item.line.vaccination_date, dateFormat);
            let diff = vaccinationDate.diff(itemExpectedDate, 'days');


            _.slice(hierarchyGroup, current + 1).forEach(sibling => {
                let elem = _.get(vm.calendar, sibling.index);
                let expectedDate = moment(birth_date)
                    .add(parseInt(elem.line.age['min_value']), `${elem.line.age['time_unit']}s`)
                    .add(diff, 'days');

                _.set(vm.calendar, `${sibling.index}.edited_expected_date`, true);
                _.set(vm.calendar, `${sibling.index}.expected_date`, expectedDate.format(dateFormat));
            });
        }

        function setAppointment(item) {
            let reason = _.find(frontDeskService.reasonSubject.getValue(), ['id', 2]);

            item.line.appointment = _.assign(item.line.appointment, {
                reason: reason,
                patient: vm.vaccination.patient,
                is_waiting_room: true,
                waiting_room_comment: $translate.instant('vaccination_wl_comment_w_date', {
                    date: item.line.appointment.date,
                    title: item.line.title
                }),
            });
        }

        function editAppointment($event, item) {
            let dialog = require('frontdesk/dialogs/appointment-dialog');
            let reason = _.find(frontDeskService.reasonSubject.getValue(), ['id', 2]);
            let event = {};
            let appointmentDate = _.get(item.line, 'appointment.date', false) ? item.line.appointment.date : item.expected_date;

            if (_.get(item.line, 'appointment.id', false)) {
                event = {
                    id: item.line.appointment.id,
                    assignIn: {
                        date: appointmentDate,
                        //is_waiting_room: false
                    }
                }
            } else {
                event = {
                    date: appointmentDate,
                    start_time: "09:00",
                    end_time: "09:" + reason.appointment_duration,
                    patient: vm.vaccination.patient,
                    waiting_room_comment: $translate.instant('vaccination_wl_comment', {title: item.line.title}),
                    reason: reason
                }
            }

            dialog = _.assign(dialog, {
                targetEvent: $event,
                locals: {
                    timeRange: null,
                    patient: null,
                    event: event
                }
            });

            $mdDialog
                .show(dialog)
                .then(function (data) {
                    item.line.appointment = data;
                    submit();
                }, _.noop);
        }

        function generateCalendar() {
            vm.calendar = _.chain(vm.vaccination.lines)
                .cloneDeep()
                .values()
                .map(iterLines)
                .sortBy(['line.injection_order'])
                .groupBy('line.age.age_by_days')
                .map(iterGroups)
                .sortBy('age_by_days')
                .value();

            if (vm.config.fix_estimated_dates) {
                vm.hierarchy = _.chain(vm.hierarchy)
                    .reduce(
                        (result, items, ids) => {
                            let hierarchyGroup = [];
                            items.forEach(line => {
                                let groupIndex = _.findIndex(vm.calendar, ['age_by_days', line['age']['age_by_days']]);
                                let group = vm.calendar[groupIndex];
                                let lineIndex = _.findIndex(group.details, ['line.injection_uid', line.injection_uid]);

                                hierarchyGroup.push({
                                    uid: line['injection_uid'],
                                    age_by_days: group.age_by_days,
                                    index: `${groupIndex}.details.${lineIndex}`
                                })
                            });

                            return _.assign(result, {[ids]: _.sortBy(hierarchyGroup, "age_by_days")});
                        }, {}
                    )
                    .value();

                vm.calendar.forEach(group => {
                    _.filter(group.details, line => !_.isNil(line.line.vaccination_date))
                        .forEach(line => vaccinationDateChanged(line))
                });
            }


            function showGroup() {
                return this.details.length > 0 && !_.every(this.details, ['line.is_hidden', true])
            }

            function iterGroups(group) {
                let line = _.first(group).line;
                let title = "";

                if (line.age.is_interval) {
                    title = $translate.instant("vaccination_vaccines_interval_title", {
                        min: ageSegment(line.age['min_value']),
                        max: ageSegment(line.age['max_value']),
                        unit: $translate.instant("vaccination_tu_" + line.age['time_unit'])
                    })
                } else if (line.age.is_infinite) {
                    title = $translate.instant("vaccination_vaccines_infinite_title", {
                        min: ageSegment(line.age['min_value']),
                        unit: $translate.instant("vaccination_tu_" + line.age['time_unit'])
                    })
                } else {
                    title = ageSegment(line.age['min_value'], line.age['time_unit'])
                }

                let newGroup = {
                    title: title,
                    age_by_days: line.age['age_by_days'],
                    expected_date: dateSegment(line.age['min_value'], line.age['max_value'], line.age['time_unit']),
                    details: group
                };

                Object.defineProperty(newGroup, 'show', {
                    get: showGroup
                });

                return newGroup;
            }

            function iterLines(line) {
                let ids = line['vaccination_ids'];
                if (_.isNil(vm.hierarchy[ids])) vm.hierarchy[ids] = [];

                vm.hierarchy[ids].push(line);
                const expectedDate = dateSegment(line.age['min_value'], line.age['max_value'], line.age['time_unit'], true);

                return {
                    order: $translate.instant(
                        line['is_booster'] ? "vaccination_booster_order" : "vaccination_injection_order",
                        {order: line['injection_order']}
                    ),
                    expected_date: expectedDate,
                    default_expected_date: expectedDate,
                    line: line
                };
            }

            function ageSegment(value, type) {
                return _.format("{0} {1}", value, _.isNil(type) ? "" : $translate.instant("vaccination_tu_" + type));
            }

            function dateSegment(min_value, max_value, type, unique) {
                let birth_date = moment(vm.vaccination.patient['birth_date'], dateFormat);
                min_value = parseInt(min_value);

                if (_.isNil(max_value) || unique) {
                    return birth_date.add(min_value, type + "s").format(dateFormat);
                } else {
                    max_value = parseInt(max_value);
                    let start = moment(birth_date).add(min_value, `${type}s`).format(dateFormat);
                    let end = moment(birth_date).add(max_value, `${type}s`).format(dateFormat);
                    return _.format("{0} - {1}", start, end);
                }
            }
        }

        function linesToDict() {
            vm.vaccination.lines = _.chain(vm.calendar)
                .cloneDeep()
                .flatMap('details')
                .map('line')
                .keyBy('injection_uid')
                .value()
        }

        function submit() {
            linesToDict();

            return vaccinationService.saveCalendar(vm.vaccination)
                .then(success);

            function success(data) {
                vm.vaccination = data;
                vm.postSave();
            }
        }

        function editLine(uid, event) {
            let dialog = require("../dialogs/vaccination-line-form-dialog");

            $mdDialog.show(_.assign(dialog, {
                targetEvent: event,
                locals: {
                    vaccination: vm.vaccination,
                    line: _.cloneDeep(vm.vaccination.lines[uid])
                }
            })).then(loadPatientCalendar, _.noop);
        }

        function addLine(uid, event) {
            let dialog = require("../dialogs/vaccination-line-form-dialog");

            $mdDialog.show(_.assign(dialog, {
                targetEvent: event,
                locals: {
                    vaccination: vm.vaccination,
                    line: {
                        injection_order: 1
                    }
                }
            })).then(loadPatientCalendar, _.noop);
        }

        function toggleAppearanceLine(item) {
            item.line.is_hidden = !item.line.is_hidden;
            submit();
        }

        function toggleHided() {
            vm.showHided = !vm.showHided;
        }

        function openSettings(event) {
            let dialog = require("../dialogs/vaccination-config-container-dialog");

            $mdDialog.show(_.assign(dialog, {
                targetEvent: event,
            }));
        }

        function commentLine(item) {
            if (vm.selectedComment === item.line.injection_uid) vm.selectedComment = null;
            else vm.selectedComment = item.line.injection_uid;
        }
    }

})();