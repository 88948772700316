/**
 * Created by amine on 29/03/2017.
 */
(function () {
    'use strict';
    
    class PregnancyService {
        constructor($q, $http, mnWebSocket, $translate, $mdDialog) {
            this.$q = $q;
            this.$http = $http;
            this.ws = mnWebSocket;
            this.$translate = $translate;
            this.$mdDialog = $mdDialog;
        }

        static get $inject() {
            return ["$q", "$http", "mnWebSocket", "$translate", "$mdDialog"];
        }

        save(item) {
            const url = "/api/pregnancy-block/" + (!_.isNil(item.id) ? item.id + "/" : "");
            let deferred = this.$q.defer();

            this.$http.post(url, item)
                .then(result => {
                    deferred.resolve(result.data);
                }, result => {
                    deferred.resolve(result.data);
                });

            return deferred.promise;
        }

        get(patient) {
            return this.ws.call("specifics.Pregnancy.get", _.isObject(patient) ? _.pick(patient, "id") : {id: patient});
        }

        deleteItem(item, $ev) {
            const url = _.format('/api/pregnancy-block/{0}/', item.id);
            let deferred = this.$q.defer();

            const confirm = this.$mdDialog.confirm()
                .title(this.$translate.instant("pregnancy_remove_confirm"))
                .ariaLabel('remove plan confirm')
                .targetEvent($ev)
                .ok(this.$translate.instant('confirm_ok'))
                .cancel(this.$translate.instant('confirm_cancel'));

            this.$mdDialog.show(confirm)
                .then(() => {
                    this.$http.delete(url)
                        .then(() => {
                            deferred.resolve(item.id);
                        }, deferred.reject);
                }, deferred.reject);

            return deferred.promise;
        }
    }

    module.exports = PregnancyService;
})();