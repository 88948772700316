(function () {

    'use strict';

    const NEW_ENTRY_DIALOG = require('../dialogs/dictionary-new-entry-dialog');
    const MULTI_ENTRY_DIALOG = require('../dialogs/dictionary-multi-node-dialog');

    class DictTreeCtrl {
        constructor(dictionaryService, mnSidenav, $mdDialog, $timeout, $transitions) {
            this.dialog = $mdDialog;
            this.$transitions = $transitions;
            this.dictionaryService = dictionaryService;

            this.timeout = $timeout;
            this.sidenav = mnSidenav;

            this.key = "";
            this.title = null;
            this.isDictTreeClose = true;
            this.dictModelSubscription = dictionaryService.dictModelSubject.subscribe(i => this.changeModel(i));
        }

        static get $inject() {
            return ["dictionaryService", "mnSidenav", "$mdDialog", "$timeout", "$transitions"];
        }

        $onDestroy() {
            if (this.dictModelSubscription) this.dictModelSubscription.unsubscribe();
        }

        changeModel(arg) {
            if (this.closeTimeout) this.timeout.cancel(this.closeTimeout);
            if (this.title == arg.title && !this.isDictTreeClose) return;

            this.title = arg.title;
            this.dictionaryService.currentFieldSubject = arg.subject;
            this.dictionaryService.currentFieldSubject.subscribe({complete: () => this.closeTimeout = this.cancel()});

            this.clearKey();

            if (this.isDictTreeClose) {
                this.isDictTreeClose = false;

                this.sidenav.open("dictionary");
                this.onEvents();
            }
        }

        filterTree() {
            if (this.key.length < 3) this.dict = this.getDictionary();
            else this.dict = _.chain(this.getDictionary()).head().cloneDeep().thru(
                copy => this._searchTree(copy, this.key)
            ).castArray().compact().value();
        }

        _searchTree(tree, searchKey, parent) {
            let key__ = _.chain(searchKey).deburr().toLower().value();
            let value = _.chain(tree.value).deburr().toLower().value();

            if (value.includes(key__)) return tree;

            if (_.size(tree.nodes) > 0 && !_.includes(value, key__)) {
                let nodes = [];

                _.forEach(tree.nodes, node => {
                    let son = this._searchTree(node, searchKey);
                    if (son) nodes.push(son);
                });

                if (_.size(nodes) > 0 && !parent) {
                    tree.nodes = nodes;
                    return tree;
                }
            }
        }

        clearKey() {
            this.key = null;
            this.dict = this.getDictionary();
        }

        handleClosing(event) {
            let $eventElement = event ? $(event.target) : $("<div />");
            let isNotDictElem = _.isEmpty($eventElement.parents('mn-dictionary'));
            let isNotFreeDictElem = _.isEmpty($eventElement.parents('mn-free-dictionary'));
            let isNotTreeElem = _.isEmpty($eventElement.parents('#dictionary'));

            if (isNotDictElem && isNotTreeElem && isNotFreeDictElem) this.closeTimeout = this.cancel();
        }

        cancel() {
            return this.timeout(() => {
                this.offEvents();
                this.closeTimeout = false
                this.isDictTreeClose = true;
                this.dictionaryService.currentFieldSubject = null;
                this.sidenav.close("dictionary");
            }, 300);
        }

        addKey(ev) {
            this.offEvents();

            this.dialog.show(_.assign({}, NEW_ENTRY_DIALOG, {
                targetEvent: ev,
                locals: {
                    uid: this.title,
                    modelsShown: false,
                    newEntry: {value: this.key}
                }
            })).then(() => {
                const dict = this.getDictionary();
                const copy = _.chain(dict).head().cloneDeep().value();

                this.dict = _.castArray(this._searchTree(copy, this.key));
                this.onEvents();
            }, () => this.onEvents());

        }

        multiple(ev) {
            this.offEvents();

            this.dialog.show(_.assign({}, MULTI_ENTRY_DIALOG, {
                targetEvent: ev,
                locals: {
                    uid: this.title
                }
            })).then(data => {
                this.onEvents();
                if (this.dictionaryService.currentFieldSubject) this.dictionaryService.currentFieldSubject.next(data);
            }, () => this.onEvents());
        }

        getDictionary() {
            return this.dictionaryService.getGroup(this.title);
        }

        onEvents() {
            $(document).on('mousedown.dict-tree', ev => this.handleClosing(ev));
            this.backListener = this.$transitions.onBefore({}, transition => this.transitionHandle(transition));
        }

        offEvents() {
            $(document).off('mousedown.dict-tree');
            if (_.isFunction(this.backListener)) this.backListener();
        }

        transitionHandle(transition) {
            let to = transition.to().name;
            let from = transition.from().name;

            if (to != from) this.handleClosing();
        }
    }

    module.exports = {
        bindings: {},
        controllerAs: "vm",
        controller: DictTreeCtrl,
        template: require('stand-alone/blocks/views/dictionary-tree.tpl.html'), // or template
    };

})();