(function () {

    'use strict';

    module.exports = AgingBalanceCtrl;

    AgingBalanceCtrl.$inject = ["statesService", "$translate", "$element"];

    function AgingBalanceCtrl(statesService, $translate, $element) {
        var vm = this;
        vm.$onInit = init;

        function init() {

            vm.balances = [];
            vm.balanceBases=["invoice_deadline","subscription_payment_deadline"];
            vm.promise = statesService.getAgingBalance(vm.query)
                .then(success);

            function success(data) {
                vm.balances = data;
                vm.totals = {
                    'b_15': _.sumBy(vm.balances, 'b_15'),
                    'b_30': _.sumBy(vm.balances, 'b_30'),
                    'b_60': _.sumBy(vm.balances, 'b_60'),
                    'b_120': _.sumBy(vm.balances, 'b_120')
                }
            }
        }
    }
})();
