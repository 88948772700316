/**
 * Created by amine on 26/08/2016.
 */
(function () {

    'use strict';

    require('../utils/editior-plugins');

    const {AsyncSubject} = require("rxjs"),
        dataInjectorPlugin = require("../plugins/data-injector"),
        speechPlugin = require("../plugins/speech"),
        generateToolbar = require("../plugins/toolbar-generator"),
        TextTemplates = require("../plugins/text-templates");

    const contexts = {
        "email": require("../json/email-text-editor.json"),
        "reminder-email": require("../json/reminder-email-text-editor.json"),
        "consent": require("../json/consent-text-editor.json")
    };

    const defaultOptions = {
        "no_config_key": true,
        "minimal": false,
        "patient": false,
        "has_patient_menu": false,
        "has_physician_menu": false,
        "has_measurement_menu": false,
        "has_misc_menu": false,
        "auto_compile": false
    };

    textEditorService.$inject = ["$q", "mnWebSocket", "$translate", "system", "patientService", "measureService", "authService", "configService", "$mdDialog", "editorTemplateService"];

    function textEditorService($q, mnWebSocket, $translate, system, patientService, measureService, authService, configService, $mdDialog, editorTemplateService) {
        let self = this;
        let addMenusSubject = new AsyncSubject();
        let defaultKeyHandlers = {'start': "%", 'end': "%"};

        self.initTinyMCE = initTinyMCE;
        self.getEditor = getEditor;
        self.addMenus = addMenus;
        self.getModelContent = getModelContent;
        self.getModel = getModel;
        self.focusOn = focusOn;

        function initTinyMCE(configKey, opts, config) {
            let options = opts;
            let deferred = $q.defer();

            if (_.isNil(configKey)) {
                configSuccess(null)
            } else {
                mnWebSocket.call("shared.Editor.get_config", {keys: [configKey]})
                    .then(configSuccess, _.noop);
            }

            addMenusSubject.subscribe(asyncAddMenus);

            function configSuccess(data) {
                if (_.isNil(data)) options = _.assign({}, defaultOptions, options);
                else options = _.assign({}, options, _.get(data, '0.value', {}));

                initEditor();
            }

            function initEditor() {
                options.isModel = !_.isNumber(options.patient);
                let _success = _.bind(patientRetrieveSuccess, deferred);

                dataInjectorPlugin(options, $translate, $mdDialog, $q, _getKey);
                speechPlugin(options, $translate);
                //self.textTemplates = new TextTemplates(options, $translate, $mdDialog, $q, editorTemplateService);


                if (options.isModel) measureService.getMeasures().then(_success);
                else $q.all([
                    patientService.getFormPatient(options.patient),
                    measureService.getMeasurementResume({patient_id: options.patient}),
                    patientService.getVaccinations(options.patient),
                    //vaccinationService.getCalendar(options.patient),
                    //self.textTemplates.asyncLoad()
                ]).then(_success);

            }

            function patientRetrieveSuccess(results) {
                if (!options.isModel) {
                    options.patient = results[0];
                    options.measurements = results[1];
                    options.vaccinations = results[2];
                    //options.vaccination_calendar = results[3];
                } else {
                    options.patient = false;
                    options.vaccinations = [];
                    // options.vaccination_calendar = {};
                    options.measurements = {
                        measurements: [],
                        measures: results
                    };
                }

                tinymce.init(getConfig(options, config, this));
            }

            function asyncAddMenus(obj) {
                addMenus(obj.name, obj.menus);
            }

            return deferred.promise;
        }

        function noToolbar(options) {
            return [
                options['has_patient_menu'],
                options['has_physician_menu'],
                options['has_measurement_menu'],
                options['has_misc_menu']
            ].every(e => !e) || options['minimal'];
        }

        function getConstantConfig(options) {
            let config = _.cloneDeep(require('../json/text-editor.json'));
            if (options['minimal']) {
                const minimal = require('../json/minimal-text-editor.json');
                config = _.assign(config, minimal);
            }
            if (_.has(contexts, options['context'])) {
                const context = _.get(contexts, options['context']);
                config = _.assign(config, context);
            }

            return config;
        }

        function getConfig(options, config, deferred) {
            let constantConfig = getConstantConfig(options);
            let toolbar = generateToolbar(options, config, authService, configService, system['date_format'].js);
            let keyHandlers = _.get(constantConfig, "keyHandlers", defaultKeyHandlers);

            if (noToolbar(options) || true) {
                toolbar.toolbar1 += _.get(constantConfig, "toolbar2", "");
                _.unset(constantConfig, "toolbar2");
            }

            return _.assign({
                language: system.lang,
                toolbar1: toolbar.toolbar1,
                data_injector_buttons: toolbar.data_injector_buttons,
                template: "",
                setup: function (editor) {
                    config.onSetup(editor);

                    if (_.isFunction(options.onSetup)) options.onSetup(editor);
                },
                init_instance_callback: function (editor) {
                    editor.__meta__ = {
                        options: options,
                        config: config
                    };
                    //editor.theme.panel.$el.find(".mce-toolbar-grp").addClass("only-toolbar-grp")

                    if (!options.minimal) {
                        editor.compile = _.bind(compile, editor);
                        editor.compileContent = _.bind(compileContent, editor);
                        editor.resetMenus = _.bind(_resetMenus, editor);
                        //editor.plugins['data_injector_plugin'].handleMnVarClick();
                        editor.getModel = _.bind(_getModel, editor);
                        editor.keyHandlers = keyHandlers;
                    }

                    addMenusSubject.complete();
                    addMenusSubject = new AsyncSubject();

                    deferred.resolve(editor);

                    if (_.isFunction(options.onInit)) options.onInit(editor);
                },
                file_picker_callback: function (cb, value, meta) {
                    let input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');

                    input.onchange = function () {
                        let file = this.files[0];

                        let reader = new FileReader();
                        reader.onload = function () {
                            // Note: Now we need to register the blob in TinyMCEs image blob
                            // registry. In the next release this part hopefully won't be
                            // necessary, as we are looking to handle it internally.
                            const id = 'blobid' + (new Date()).getTime();
                            const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                            const base64 = reader.result.split(',')[1];
                            const blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);

                            // call the callback and populate the Title field with the file name
                            cb(blobInfo.blobUri(), {title: file.name});
                        };
                        reader.readAsDataURL(file);
                    };

                    input.click();
                }
            }, constantConfig, config);
        }


        function _getKey(item, editor = null) {
            let keyHandlers = _.get(editor, 'keyHandlers', defaultKeyHandlers) || defaultKeyHandlers;
            return `${keyHandlers.start}${_.isObject(item) ? item.key : item}${keyHandlers.end}`;
        }

        function getEditor(name) {
            return tinymce.get(name);
        }

        function addMenus(name, menus) {
            //return false;
            let editor = getEditor(name);

            if (!_.isNil(editor)) {
                _.bind(_resetMenus, editor)();
                _.bind(_addMenus, editor)(menus);
            } else {
                addMenusSubject.next({name: name, menus: menus});
            }
        }

        function getModelContent(content) {
            let $content = $("<div />").append(content);

            $("mn-var", $content).each(function () {
                let elem = $(this);

                elem
                    .removeAttr("contenteditable")
                    .attr("dirty", false)
                    .removeClass("e")
                    .addClass("ne")
                    .html(_getKey(elem.attr("key")) + "&nbsp;")
            });

            return $content.html();
        }

        function _getModel() {
            let bodyHtml = this.getBody().innerHTML;

            return getModelContent(bodyHtml);
        }

        function getModel(name) {
            let editor = getEditor(name);

            return _.bind(_getModel, editor)();
        }

        function focusOn(name) {
            let editor = getEditor(name);
            // console.log(tinymce.activeEditor);

            //this.fire("focus");
        }

        function compile(content) {
            content = this.plugins['data_injector_plugin'].compile(content);
            this.fire("keyup");
            return content;
        }

        function compileContent() {
            return this.plugins['data_injector_plugin'].compileContent();
        }

        function _addMenus(menus) {
            //this.plugins['data_injector_plugin'].addMenus(menus);
        }

        function _resetMenus() {
            _(this.theme.panel.find('toolbar buttongroup')[0].items())
                .filter({settings: {inserted: true}})
                .invokeMap("remove")
                .value();
        }

        // function addEditorTemplate(item) {
        //     item
        // }


    }

    module.exports = textEditorService;
})();
