/**
 * Created by BETALOS on 09/03/2017.
 */
(function () {

    'use strict';

    module.exports = YearActivityCtrl;

    YearActivityCtrl.$inject = ["statesService", "$translate", "$element", "$timeout"];

    function YearActivityCtrl(statesService, $translate, $element, $timeout) {
        let vm = this;

        let utils = require('../utils/activity-functions');

        vm.$onInit = init;
        vm.exportToExcel = exportToExcel;

        vm.getData = _.mnDelay(getData, 400);

        function init() {
            vm.query = {
                year: moment().year()
            };

            getData();
        }

        function getData() {
            vm.promise = statesService.getYearActivities(vm.query)
                .then(success);

            function success(data) {
                $("#output-procedures", $element).pivot(data['procedures'], {
                    aggregator: utils.aggregatorProcedures,
                    cols: _.castArray($translate['instant']("states_procedure_name")),
                    rows: _.castArray($translate['instant']("patient_last_first_name")),
                    derivedAttributes: utils.keysToShow($translate, "states_procedure_name"),
                });

                $("#output-payments", $element).pivot(data['payments'], {
                    aggregator: utils.aggregatorPay,
                    cols: _.castArray($translate['instant']("states_receipts")),
                    derivedAttributes: utils.keysToShow($translate, "states_receipts"),
                    rows: [$translate['instant']("payment_mode"), $translate['instant']("patient_last_first_name")],
                });

                $timeout(function () {
                    vm.proceduresLength = ($("#output-procedures tbody tr", $element).length - 1);
                    vm.paymentsLength = ($("#output-payments tbody tr", $element).length - 1);
                })
            }
        }

        function exportToExcel(element, title) {
            utils.exportToExcel(
                element,
                title,
                vm.query,
                $translate
            )
        }

    }

})();