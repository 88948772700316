/**
 * Created by Hp on 05/07/2017.
 */
/**
 * Created by Hp on 01/03/2017.
 */
/**
 * Created by Hp on 01/03/2017.
 */
(function () {
    "use strict";

    var dialog = {
        controller: WarehouseDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/warehouse-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
        focusOnOpen: false
    };

    module.exports = dialog;

    WarehouseDialogCtrl.$inject = ["$mdDialog", "$scope", "locationService", "warehouseService"];

    function WarehouseDialogCtrl($mdDialog, $scope, locationService, warehouseService) {
        var vm = this;
        var res_valid = true;
        vm.submit = submit;
        vm.cancel = cancel;
        vm.checkUnique = checkUnique;

        init();

        function init() {
            vm.is_new = _.isNil(vm.warehouse) ? true : false;
            vm.allow_update = true;

            if (!_.isNil(vm.warehouse) && !_.isNil(vm.warehouse.id)) vm.promise = warehouseService.checkWarehouseDependencies(vm.warehouse.id).then(success);
            function success(data) {
                vm.allow_update = !_.isNil(vm.warehouse) && !_.isNil(vm.warehouse.id) ? data : true;
            }
        }

        function checkUnique(name) {
            warehouseService.checkWarehouseExistence(name, vm.warehouse.id).then(done);
            function done(data) {
                res_valid = !_.isNil(data) ? false : true;
                $scope.warehouseForm['name'].$setValidity('unique', res_valid);
            }
        }

        function submit() {
            warehouseService
                .saveWarehouse(vm.warehouse)
                .then(success);
            function success(data) {
                vm.warehouse = data;
                $mdDialog.hide(data);
            }
        }

        function cancel() {
            $mdDialog.cancel();
        }

    }


})();
