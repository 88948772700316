/**
 * Created by BETALOS on 27/07/2016.
 */
(function () {

    'use strict';

    module.exports = generateId;

    const KEY_CHARS = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"

    function generateId(length, sep) {
        if (_.isUndefined(length)) length = 16;
        if (_.isUndefined(sep)) sep = ".";
        let keyLen = length, key = "", l = KEY_CHARS.length;

        while (keyLen--) {
            key += KEY_CHARS.charAt(Math.floor(Math.random() * l));
        }
        return key + sep + (new Date()).getTime();
    };

})();