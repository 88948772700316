/**
 * Created by Betalos on 27/06/2017.
 */
(function () {
    'use strict';

    module.exports = {
        dcmService: require('./services/dcm-service')
    };

})()