/**
 * Created by BETALOS on 27/02/2017.
 */
(function () {

    'use strict';

    module.exports = StatesContainerCtrl;

    StatesContainerCtrl.$inject = ["$scope", "$state", "$transitions"];

    function StatesContainerCtrl($scope, $state, $transitions) {
        let vm = this;

        vm.$onInit = init;

        function init() {
            vm.availableStats = require('../json/available-stats.json');

            if (_.eq($state.$current['name'], "app.states")) $state.go("app.states.day-activity", {}, {inherit: true});

            let listener = $transitions.onStart({}, toList);

            function toList(transition) {
                let toState = transition.to();

                if (!_.eq(toState.name, "app.states.day-activity") && _.eq(toState.name, "app.states")) {
                    $state.go("app.states.day-activity", {}, {inherit: true});
                }
            }

            $scope.$on("$destroy", onDestroy);

            function onDestroy() {
                listener();
            }
        }

    }

})();
