(function () {

    'use strict';

    const {Workbox} = require('workbox-window');

    if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'development') {
        const wb = new Workbox('/service-worker.js');

        wb.addEventListener('controlling', () => {
            window.location.reload();
        });

        wb.register();
    }

})();
