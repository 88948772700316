/**
 * Created by amine on 03/09/2018.
 */

(function () {
    'use strict';

    const PATHOLOGIES_FORM_DIALOG = require('../../specifics/dialogs/pathologies-dialog');

    class PathologyCtrl {
        constructor($translate, $element, $q, $mdDialog) {
            this.$translate = $translate;
            this.$q = $q;
            this.$element = $element;
            this.dialog = $mdDialog;

            this.requireMatch = false;
        }

        static get $inject() {
            return ['$translate', "$element", "$q", "$mdDialog"];
        }

        $onInit() {
            this.save = _.isFunction(this.save) ? this.save : _.noop;
            this.model = _.isNil(this.model) ? [] : this.model;
            this.ngModelController = this.ngModelController ? this.ngModelController : null;
        }

        addPathology(event) {
            this.dialog.show(_.assign({}, PATHOLOGIES_FORM_DIALOG, {
                targetEvent: event
            })).then(item => {
                this.model.push(item);
                _.mnDelay(this.save(), 300);
            }, _.noop);
        }

        change() {
            let viewValue = _.cloneDeep(this.model);

            this.ngModelController.$setViewValue(viewValue, 'change');
            this.ngModelController.$commitViewValue();
        }

    }

    module.exports = {
        bindings: {
            model: "=ngModel",
            readOnly: '<?mnReadOnly'
        },
        controllerAs: "vm",
        controller: PathologyCtrl,
        require: {ngModelController: "ngModel"},
        template: require('specifics/views/pathology-block.tpl.html'),
    };

})();