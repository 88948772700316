/**
 * Created by amine on 22/03/2017.
 */

(function () {
    'use strict';

    const APPOINTMENT_DIALOG = require('frontdesk/dialogs/appointment-dialog');

    let mnPregnancyBlock = {
        controller: mnPregnancyCtrl,
        controllerAs: 'vm',
        bindings: {
            patient: '<?mnPatient',
            postSave: '&?mnPostSave',
            postSaveSubject: '<?mnPostSaveSubject',
            readOnly: '<?mnReadOnly',
            currentDate: '<?',
        },
        restrict: 'E',
        template: require("specifics/views/pregnancy-block.tpl.html"),
    };

    mnPregnancyCtrl.$inject = ["$q", "$scope", "system", "$element", "$translate", "$mdDialog", "patientService", "pregnancyService", "configService", "frontDeskService", "moment"];

    function mnPregnancyCtrl($q, scope, system, $element, $translate, $mdDialog, patientService, pregnancyService, configService, frontDeskService, moment) {
        let vm = this;
        let dateFormat = system.date_format.js;
        let saving = false;
        let savingConsultation = false;
        let blockConfig = {};
        let initPregnancy = {
            amenorrhea_start: null,
            calculated_pregnancy_date: null,
            estimated_pregnancy_date: null,
            delivery_date: null,
            actual_term: null,
            details: [],
            visits: [],
            biology_prescriptions: [],
        };

        vm.$onInit = init;
        vm.recalculate = recalculate;
        vm.editAppointment = editAppointment;
        vm.itemValidation = itemValidation;
        vm.newPregnancy = newPregnancy;
        vm.pregnancySelected = pregnancySelected;
        vm.validatePregnancy = validationPregnancy(true);
        vm.invalidatePregnancy = validationPregnancy(false);
        vm.dictionaryChanged = _.mnDelay(dictionaryChanged, 500);
        vm.deletePregnancy = deletePregnancy;
        vm.previous = previous;
        vm.next = next;
        vm.submit = submit;

        function init() {
            $element
                .addClass("flex-noshrink")
                .addClass("layout-column");

            vm.maxDate = new Date();
            newPregnancy();

            if (!_.isNil(vm.postSaveSubject) && !vm.readOnly) vm.postSaveSubject.subscribe(consultationSaved);

            $q.all([configService.getByHttp("pregnancy_block"), pregnancyService.get(vm.pregnancy.patient)])
                .then(preparePregnancies, _.noop)
        }

        function reloadPregnancies() {
            $q.all([pregnancyService.get(_.get(vm.pregnancy, "patient", vm.patient))])
                .then(preparePregnancies, _.noop)
        }

        function preparePregnancies(data) {
            let pregnancies = [];

            if (data.length == 2) {
                blockConfig = data[0];
                pregnancies = data[1];
            } else {
                pregnancies = data[0];
            }

            vm.standard_error = _.get(blockConfig, "standard_error", 5);
            vm.pregnancies = _.map(pregnancies, calculate);
            vm.currentVersion = getCurrentPregnancy();

            pregnancySelected();
            dictionaryFields();
        }

        function getCurrentPregnancy() {
            let tmpCurrentVersion = _.chain(vm.pregnancies).orderBy("id", "desc").findIndex(item => {
                let itemDate = null;
                let current = moment(vm.currentDate, dateFormat);

                if (!_.isNil(item.amenorrhea_start)) itemDate = moment(item.amenorrhea_start, dateFormat);
                else if (!_.isNil(item.estimated_pregnancy_date)) itemDate = moment(item.estimated_pregnancy_date, dateFormat)

                return (itemDate.isSameOrBefore(current, 'day') && !item.is_validated);
            }).value();

            if (_.isNil(tmpCurrentVersion) || tmpCurrentVersion === -1) {
                tmpCurrentVersion = _.chain(vm.pregnancies).orderBy("id", "desc").findIndex(item => {
                    return !item.is_validated;
                }).value();
            }

            return tmpCurrentVersion == -1 ? vm.pregnancies.length - 1 : tmpCurrentVersion;
        }


        function calculate(pregnancy) {
            let momentAS;

            if (_.isNil(pregnancy) || !pregnancy) return false;
            if (pregnancy.estimated_pregnancy_date) {
                let momentEPD = moment.isMoment(pregnancy.estimated_pregnancy_date) ? _.clone(pregnancy.estimated_pregnancy_date) : moment(pregnancy.estimated_pregnancy_date, dateFormat);
                momentAS = moment(momentEPD).subtract(_.get(blockConfig, "pregnancy_date", 2), "w");
            } else {
                momentAS = moment.isMoment(pregnancy.amenorrhea_start) ? _.clone(pregnancy.amenorrhea_start) : moment(pregnancy.amenorrhea_start, dateFormat);
                pregnancy.calculated_pregnancy_date = moment(momentAS).add(_.get(blockConfig, "pregnancy_date", 2), "w");
            }

            let diff = moment.duration(moment(momentAS).diff(moment()));
            let weeks = Math.abs(_.ceil(diff.asWeeks()));
            let days = Math.abs(_.ceil(diff.asDays()) % 7);
            let calendar = _.sortBy(_.get(blockConfig, "calendar", []), "period.start");

            pregnancy.delivery_date = moment(momentAS).add(_.get(blockConfig, "delivery_date", 40), "w");

            if (!_.isNaN(weeks) && !_.isNaN(days))
                pregnancy.actual_term = $translate["instant"]("pregnancy_current_term_value", {
                    weeks: weeks,
                    days: days
                });
            pregnancy.details = _.reduce(calendar, handleDetail, []);

            return pregnancy;

            function handleDetail(details, configDetail) {
                let start = moment(momentAS).add(configDetail.period.start, 'w').format(dateFormat);
                let end = moment(momentAS).add(configDetail.period.end, 'w').format(dateFormat);
                let temp_date = moment(momentAS).add((configDetail.period.start + configDetail.period.end) / 2, 'w');
                let pregnancyDetail = _.find(pregnancy.details, ["uid", configDetail.uid]);
                let result = _.assign(_.cloneDeep(configDetail), {
                    uid: _.get(pregnancyDetail, "uid", configDetail.uid),
                    start: start,
                    end: end,
                    appointment: _.get(pregnancyDetail, "appointment"),
                    minDate: minDate(start),
                    maxDate: maxDate(end),
                    expected_date: moment(temp_date).format(dateFormat),
                    is_done: _.get(pregnancyDetail, "is_done", false)
                });

                return _.concat(details, result);
            }
        }

        function recalculate() {
            vm.pregnancy = calculate(vm.pregnancy);
        }

        function next() {
            vm.currentVersion -= 1;
            pregnancySelected();
        }

        function previous() {
            vm.currentVersion += 1;
            pregnancySelected();
        }

        function pregnancySelected() {
            if (vm.currentVersion == -1) newPregnancy();
            else vm.pregnancy = vm.pregnancies[vm.currentVersion];
        }

        function dictionaryFields() {
            vm.fields = _.map(['observation', 'comment'], function (item) {
                return {
                    key: item,
                    label: $translate["instant"](`ophthalmic_${item}`),
                    meta_data: {
                        dict_uid: _.get(blockConfig, `dictionary.${item}`, null)
                    }
                }
            });
        }

        function newPregnancy() {
            vm.currentVersion = -1;

            vm.pregnancy = _.assign(initPregnancy, {
                patient: {
                    id: vm.patient
                }
            })
        }

        function deletePregnancy($event) {
            pregnancyService.deleteItem(vm.pregnancy, $event)
                .then(success, _.noop)

            function success() {
                _.remove(vm.pregnancies, ['id', vm.pregnancy.id]);

                scope.$applyAsync(function () {
                    vm.pregnancy = null;
                    newPregnancy()
                });
            }
        }

        function submit() {
            if (_.isNil(vm.pregnancy.real_delivery_date)) return save();
            else return validationPregnancy(true)();
        }

        function save() {
            if (_.isNil(vm.pregnancy.amenorrhea_start) && _.isNil(vm.pregnancy.estimated_pregnancy_date))
                return false;

            saving = true;
            return pregnancyService.save(vm.pregnancy)
                .then(success, _.noop);

            function success(data) {
                vm.pregnancy = calculate(data);
                vm.pregnancies.push(vm.pregnancy);

                vm.currentVersion = vm.pregnancies.length - 1;
                pregnancySelected();

                if (!savingConsultation) vm.postSave();
                else savingConsultation = false;
            }
        }

        function validationPregnancy(validate) {
            return function () {
                vm.pregnancy.is_validated = validate;
                vm.pregnancy.validate_at = validate ? moment() : null;

                return save()
            }
        }

        function dictionaryChanged() {
            if (_.isNil(vm.pregnancy.id)) return false;

            save();
        }

        function consultationSaved() {
            if (!saving) {
                savingConsultation = true;
                save();
            } else saving = false;
        }

        function editAppointment($event, item, index) {
            patientService
                .getMinimalPatient(vm.patient)
                .then(success);

            function success(patient) {
                let reason = _.find(frontDeskService.reasonSubject.getValue(), ['id', item.reason]);
                let appointmentDate = _.get(item, 'appointment.date', false) ? item.appointment.date : item.expected_date;

                let dialog = _.assign({}, APPOINTMENT_DIALOG, {
                    targetEvent: $event,
                    locals: {
                        timeRange: null,
                        patient: null,
                        event: {
                            date: appointmentDate,
                            start_time: "09:00",
                            end_time: `09:${_.get(reason, 'appointment_duration', 10)}`,
                            patient: patient,
                            waiting_room_comment: item.title,
                            reason: reason
                        }
                    }
                });

                $mdDialog.show(dialog)
                    .then((data) => {
                        item.appointment = data;
                        save();
                    });
            }
        }

        function itemValidation(item) {
            item.is_done = !item.is_done;
            setTimeout(() => {
                save();
            }, 500)
        }

        function minDate(date) {
            let date_;
            if (moment.isMoment(date)) {
                date_ = moment(date).subtract(1, "w").toDate();
            } else {
                date_ = moment(date, dateFormat).subtract(1, "w").toDate();
            }
            return date_;
        }

        function maxDate(date) {
            let date_;
            if (moment.isMoment(date)) {
                date_ = moment(date).add(1, "w").toDate();
            } else {
                date_ = moment(date, dateFormat).add(1, "w").toDate();
            }
            return date_;
        }
    }

    module.exports = mnPregnancyBlock;
})();