/**
 * Created by BETALOS on 14/10/2016.
 * Edited by amine on 04/10/2018
 */
(function () {

    'use strict';

    class VisitFinancialCtrl {
        constructor($mdDialog, visitService, $q, $element, $timeout, paymentService, $scope, configService) {
            this.$mdDialog = $mdDialog;
            this.visitService = visitService;
            this.$q = $q;
            this.$element = $element;
            this.$timeout = $timeout;
            this.paymentService = paymentService;
            this.$scope = $scope;

            this.visitProcedures = this.visitProcedures || [];
            this.visitInstance = this.visitInstance || null;
            this.visit = this.visit || null;

            this.advanceForm = configService.hasAdvanceVisitFinancial();
        }

        static get $inject() {
            return ["$mdDialog", "visitService", "$q", "$element", "$timeout", "paymentService", "$scope", "configService"];
        }

        $onInit() {
            this.promise = this.$q.all([
                this.visitService.getVisit(this.visit),
                this.visitService.getProcedures()
            ]).then(data => {
                this.visitInstance = data[0];
                this.procedures = data[1];
                this.visitProcedures = this.visitInstance.procedures;

                if (this.advanceForm) {
                    this.visitInstance.delivery_document = this.visitInstance.delivery_document || {
                        price_mode: 'TTC',
                        patient: this.visitInstance.patient_id,
                        doc_date: this.visit.visit_date,
                        lines: []
                    };
                }

                this.$timeout(() => this.calculateHeight());
            });

            this.applyDiscount = this.paymentService.applyDiscount;

            this.$scope.$watch("vm.total()", value => {
                if (_.isNil(this.visitInstance) || _.get(this.visitInstance, 'financial_status.total') === value) return false;

                this.visitInstance.financial_status.gross_total = value;
                this.applyDiscount("value", this.visitInstance);

                this.$timeout(() => this.calculateHeight(), 300);
            });
        }

        cancel() {
            this.$mdDialog.cancel();
        }

        addProcedure(procedure) {
            let existence = _.find(this.visitProcedures, {name: procedure.name, procedure: {id: procedure.id}});

            if (_.isUndefined(existence)) this.visitProcedures.push(this.procedureToVisit(procedure));
            else existence.qte += 1;

            this.$timeout(() => this.calculateHeight());
        }

        remove(index) {
            this.visitProcedures.splice(index, 1);
            this.$timeout(() => this.calculateHeight());
        }

        procedureToVisit(procedure) {
            let visitProcedure = _.pick(procedure, ['name', 'price']);
            return _.assign(visitProcedure, {qte: 1, discount: 0, procedure: procedure});
        }

        total(net = false) {
            let start = 0;
            if (this.visitInstance) {
                const deliveryDocumentTaxedTotal = _.get(this.visitInstance, "delivery_document.taxed_total", 0);
                const globalDiscount = this.visitInstance.financial_status.global_discount;
                start = net ? deliveryDocumentTaxedTotal - globalDiscount : deliveryDocumentTaxedTotal;
            }

            const t = _.reduce(this.visitProcedures, (result, item) => {
                return result + item['qte'] * (item['price'] - item['discount']);
            }, start);

            return _.isNaN(t) ? 0 : t;
        }

        prePrint() {
            return this.visitService.updateVisit(this.visitInstance, ['procedures', 'financial_status']);
        }

        calculateHeight() {
            const tableHeight = $("md-table-container", this.$element).height() + 45;
            const documentLinesHeight = this.advanceForm ? $("mn-document-lines", this.$element).height() + 62 : 0;

            this.height = {
                'max-height': (tableHeight + documentLinesHeight + 45) + 'px',
                'min-height': '182px'
            };
        }

        save() {
            this.$mdDialog.hide(this.visitInstance);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: VisitFinancialCtrl,
        template: require("../views/visit-financial.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true
    };
})();

