/**
 * Created by Hp on 02/03/2017.
 */
(function () {
    'use strict';

    module.exports = ReturnFormCtrl;

    ReturnFormCtrl.$inject = ["purchaseService", "$transition$", "$scope", "stockService", "system", "$state", "$mdToast", "$translate", "$mdDialog", "$q", "configService"];

    function ReturnFormCtrl(purchaseService, $transition$, $scope, stockService, system, $state, $mdToast, $translate, $mdDialog, $q, configService) {

        var vm = this;

        var currentReturn = $transition$.params('to')["returnId"];
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.submit = submit;
        vm.invalidateReturn = invalidateReturn;
        vm.validateReturn = validateReturn;
        vm.isAllValid = isAllValid;
        vm.save = save;

        function init() {
            vm.is_new = !_.isNil(currentReturn) ? false : true;
            vm.docType = 'RETURN';
            vm.modalFilter = {is_deleted: {$ne: true}, is_valid: {$ne: false}};
            $scope.$watch('vm.goodReturn.is_valid', readOnlyReturn);
            vm.modelName = 'GoodReturn';

            vm.promises = [configService.get(["price_mode"], true), stockService.get_stock_config()];
            if (!_.isNil(currentReturn)) vm.promises.push(stockService.getStockDocument(currentReturn, 'good-return'));
            else vm.goodReturn = {price_mode: 'HT', doc_date: moment().format(dateFormat), lines: []};
            vm.promise = $q.all(vm.promises).then(success);

            function success(data) {
                if (!_.isNil(currentReturn)) vm.goodReturn = data[2];
                else {
                    vm.goodReturn.price_mode = data[0];
                    vm.goodReturn.type = data[1]['return_default_type'] || 'STORE'
                }
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success, error);
            }

            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else saveSuccess(data);
            }

            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.goodReturn = data;
            if (_.isNil(currentReturn)) $state.go('app.stock.return-form', {returnId: data.id}, {location: 'replace'});
        }

        function invalidateReturn() {

            if (vm.goodReturn.type == 'PRICE') {
                return stockService.updateStockDocument(vm.goodReturn.id, "good-return", {is_valid: false});
            }
            else {
                return stockService.invalidateMovement(vm.goodReturn, 'GoodReturn', 'OUT');
            }
        }

        function validateReturn(data) {
            if (vm.goodReturn.type == 'PRICE') {
                return stockService.updateStockDocument(vm.goodReturn.id, "good-return", {is_valid: true});
            }
            else {
                return stockService.validateMovement(data, 'GoodReturn', 'OUT');
            }
        }

        function readOnlyReturn() {
            vm.readOnly = (vm.goodReturn && vm.goodReturn.is_valid) ? vm.goodReturn.is_valid : false;
        }

        function isAllValid() {
            return vm.goodReturn ? stockService.isAllValid(vm.goodReturn, vm.docType, vm.modelName) : true;
        }

        function save() {
            return stockService.saveStockDocument(vm.goodReturn, "good-return", 'GoodReturn');
        }

    }
})
();
