/**
 * Created by amine on 03/09/2019 V2.0
 */
(function () {

    'use strict';

    class SummaryFilterDialogCtrl {
        constructor($mdDialog) {
            this.dialog = $mdDialog;

            this.query = this.query || {};
        }

        static get $inject() {
            return ["$mdDialog"];
        }

        $onInit() {
        }

        submit() {
            this.dialog.hide(this.query);
        }

        cancel() {
            this.dialog.cancel();
        }

        visitToggled() {
            const subItems = [
                'consultations',
                'medical_prescriptions',
                'medical_certificates',
                'biology_prescriptions',
                'exams',
                'text_prescriptions',
                'procedure_prescriptions'
            ];
            subItems.forEach(item => _.set(this.query.activated, item, this.query.activated.visits))
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: SummaryFilterDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        template: require("stand-alone/patient-summary/views/filters-dialog.tpl.html")
    };


})();