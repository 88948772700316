/**
 * Created by Betalos on 27/06/2017.
 */
(function () {

    'use strict';

    module.exports = thSearch;

    thSearch.$inject = ["$compile", "$q", "mnWebSocket", "$http", "$rootScope", "$controller"];

    function thSearch($compile, $q, mnWebSocket, $http, $rootScope, $controller) {
        var directive = {
            restrict: 'A',
            priority: 0,
            require: 'ngModel',
            link: {
                pre: preLink
            },
        };

        function preLink(scope, element, attributes, ctrl) {
            var attr = _.get(attributes, 'thSearch');

            var childScope = null;
            var childController = null;

            element.addClass('mn-input-cell');
            scope.$watch(attr, handleColumn);

            function handleColumn(column) {
                if(!column.search) return;

                var tpl = getSearchTpl(column);
                var $unsetButton = $('<md-button class="md-icon-button" ng-if="vm.search" ng-click="vm.unsetContent($event)" />')
                    .append('<md-icon md-font-icon="mdi-close" md-font-set="mdi" />');

                if (!_.isNull(childScope)) {
                    element.empty();
                    childScope.$destroy();
                }

                var $container = $('<div  />')
                    .addClass(_.eq(column.type, 'select') ? 'layout-row layout-align-center-center' : '')
                    .append(tpl)
                    .append($unsetButton)
                    .prop('outerHTML');

                childScope = $rootScope.$new();
                var linkFn = $compile($container);
                var elem = linkFn(childScope);
                var controllerObjBefore = childScope["vm"];

                childController = $controller(ChildElementCtrl, {$scope: childScope}, false, "vm");
                childController = _.assign(childController, controllerObjBefore);

                element.html(elem);
            }

            function getSearchTpl(column) {
                var path = _.format('stand-alone/table/views/table-{0}-search.html', column.type);
                var tpl = "" //require(path);

                if (_.eq(column.type, 'select')) return handleSelect(column, tpl);
                else return tpl;
            }

            function handleSelect(column, tpl) {
                var $tpl = $(tpl);
                var $mdOption = $('md-option', $tpl);
                var selectSource = _.get(column, 'select_source', {});


                if (_.eq(selectSource.source, 'static')) {
                    $mdOption
                        .attr('ng-bind', 'item')
                        .attr('ng-value', 'item');
                }
                else {
                    $tpl.attr("ng-model-options", "{ trackBy: \'$value.id\' }");

                    var field = _.format("item.{0}", selectSource.key);
                    $mdOption
                        .attr('ng-bind', field)
                        .attr('ng-value', "item");
                }

                return $tpl.prop('outerHTML');
            }

            ChildElementCtrl.$inject = [];

            function ChildElementCtrl() {
                var vm = this;

                vm.change = change;
                vm.getItems = getItems;
                vm.unsetContent = unsetContent;

                if (ctrl.$modelValue) {
                    vm.getItems();
                    vm.search = ctrl.$modelValue;
                }

                function change() {
                    vm.search = _.isEmpty(vm.search) ? undefined : vm.search;
                    ctrl.$setViewValue(vm.search);
                    ctrl.$commitViewValue();
                    ctrl.$render();
                }

                function unsetContent(ev) {
                    ev.stopPropagation();
                    vm.search = undefined;

                    change();
                }

                function getItems() {
                    var column = scope.$eval(attr);
                    if (!_.eq(column.type, 'select')) return;

                    var selectSource = _.get(column, 'select_source', {});
                    var promise = null;

                    if (_.eq(selectSource.source, 'websocket')) promise = mnWebSocket.call(selectSource.path, selectSource.query);
                    if (_.eq(selectSource.source, 'http')) promise = $http.get(selectSource.path);
                    if (_.eq(selectSource.source, 'static')) promise = selectSource.items

                    return $q.all([promise])
                        .then(success);

                    function success(data) {
                        vm.items = _.eq(selectSource.source, 'http') ? _.chain(data).head().get('data').value() : _.head(data);
                    }
                }
            }
        };

        return directive;
    };

})();