/**
 * Created by Hp on 08/03/2017.
 */


(function () {

    'use strict';

    var mnProvider = {
        controller: MnProviderController,
        controllerAs: "vm",
        bindings: {
            provider: "=ngModel",
            mnLabel: "=mnLabel",
            disabled: "=mnDisabled",
            change: "<?mnChange"
        },
        template: tpl,
    };

    MnProviderController.$inject = ["$mdDialog", "providerService"];

    function MnProviderController($mdDialog, providerService) {

        var vm = this;

        vm.$onInit = init;
        vm.cancel = cancel;
        vm.add = add;
        vm.getProviders = providerService.getProviders;
        vm.showCheckTable = showCheckTable;
        vm.selectedChange = selectedChange;


        function init() {
        }

        function cancel() {
            vm.provider = null;
            vm.selectedChange();
        }

        function add(ev) {
            const dialog = _.assign({}, require('stock/dialogs/provider-dialog'), {
                locals: {
                    provider: {}
                },
                clickOutsideToClose: false,
                focusOnOpen: false
            });
            $mdDialog.show(dialog).then(done);

            function done(data) {
                vm.provider = data;
            }
        }

        function showCheckTable() {
            $mdDialog.show(_.assign(require('stock/dialogs/provider-search-dialog'), {
                locals: {
                    search: {is_deleted: {$ne: true}},
                }
            })).then(function (data) {
                vm.provider = data;
            });
        }

        function selectedChange() {
            _.isFunction(vm.change) ? vm.change(vm.provider) : _.noop;
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        var required = $elem.is("[required]") ? " required " : "";
        var mndisabled = !_.isUndefined(attrs['mnDisabled']) ? ' ng-disabled="vm.disabled" ' : "";
        var label = attrs['mnLabel'];
        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        if (!$elem.is('[flex]')) $elem.addClass("flex");

        return [
            '<md-autocomplete  md-selected-item="vm.provider" md-search-text="vm.searchText"' +
            ' md-items="item in vm.getProviders(vm.searchText)" md-item-text="item.social_reason"' +
            'md-no-cache="true" md-selected-item-change="vm.selectedChange()" md-autoselect="true" md-delay="60" md-floating-label="{{vm.mnLabel  | translate }}" ' + mndisabled + '> ',
            '<md-item-template> ',
            '<span md-highlight-text="vm.searchText" md-highlight-flags="i">{{ item.social_reason }}</span>',
            '  </md-item-template>',
            '<md-not-found>',
            '<span translate="provider_not_found"></span>',
            '</md-not-found>',
            '</md-autocomplete>',
            '<div class="mn-option-buttons flex-nogrow layout-row">',
            '<md-button class="md-icon-button" ng-click="vm.showCheckTable()" aria-label="select"  ' + mndisabled + '>',
            '<md-icon md-font-icon="mdi-magnify" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '<md-button  class="md-icon-button" ng-click="vm.add($event)" aria-label="add"  ' + mndisabled + '>',
            '<md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '<md-button ng-if="vm.provider" class="md-icon-button"  ng-click="vm.cancel()" aria-label="cancel"  ' + mndisabled + '>',
            '<md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '</div>'
        ].join('');
    }

    module.exports = mnProvider;

})();