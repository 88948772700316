
(function () {
    "use strict";

    var dialog = {
        controller: TariffDetailsDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/tariff-details-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
        focusOnOpen: false
    };

    module.exports = dialog;

    TariffDetailsDialogCtrl.$inject = ["$mdDialog", "$scope" ];

    function TariffDetailsDialogCtrl($mdDialog, $scope ) {
        var vm = this;
        vm.submit = submit;
        vm.cancel = cancel;
        vm.addLineTariff = addLineTariff;
        vm.showLineDetails = showLineDetails;

        init();

        function init() {
            vm.selectedLine = -1;
        }


        function submit() {

                $mdDialog.hide(vm.line);

        }

        function cancel() {
            $mdDialog.cancel();
        }

        function addLineTariff(line) {
            if (vm.selectedLine != line) {
                showLineDetails(line)
            }

            line.tariffs.push({
                rule: vm.tariffRules[0],
                type: 'amount',
                details: []
            })
        }
        function showLineDetails(line) {
            if (vm.selectedLine == line) {
                vm.selectedLine = -1;
            } else {
                vm.selectedLine = line;
            }
        }

    }


})();
