/**
 * Created by amine on 08/09/2017.
 */
(function () {

    'use strict';

    module.exports = {
        controller: CustomVarFormDialogController,
        controllerAs: "vm",
        template: require("../views/custom-var.tpl.html"),
        parent: $(document.body),
        bindToController: true,
        clickOutsideToClose: true
    };

    CustomVarFormDialogController.$inject = ["$scope", "$mdDialog", "customVariableService"];

    function CustomVarFormDialogController($scope, $mdDialog, customVariableService) {
        var vm = this;

        vm.$onInit = init;
        vm.submit = submit;
        vm.typeChanged = typeChanged;
        vm.addNewToList = addNewToList;
        vm.removeFromList = removeFromList;
        vm.labelChanged = labelChanged;
        vm.saveVariable = saveVariable;
        vm.selectRow = getVariable(false);
        vm.cancel = $mdDialog.cancel;

        function init() {
            vm.params = false;
            vm.inputTypes = [
                {name: "text"},
                {name: "longtext"},
                {name: "date"},
                {name: "datetime"},
                {name: "integer"},
                {name: "float"},
                {name: "list", params: true, data: [null]},
                {name: "list_longtext", params: true, data: [{title: null, content: null}]}
            ];

            vm.meta = _.assign({
                id: null,
                type: "text"
            }, vm.meta);

            var type = _.find(vm.inputTypes, {name: vm.meta.type});

            vm.params = _.get(type, "params", false);
            vm.meta.data = _.get(vm.meta , "data", null);

            vm.actions = {
                single: [
                    {
                        icon: 'mdi-pencil',
                        label: 'edition',
                        resource: 'general-config',
                        action: 'update',
                        behavior: 'disable',
                        method: getVariable(true)
                    },
                    {
                        icon: 'mdi-check',
                        label: 'use',
                        resource: 'general-config',
                        action: 'get',
                        behavior: 'disable',
                        method: getVariable(false)
                    }
                ],
                multiple: []
            };
        }

        function labelChanged() {
            vm.meta.slug = _.kebabCase(vm.meta.label);
        }

        function typeChanged() {
            var type = _.find(vm.inputTypes, {name: vm.meta.type});

            vm.params = _.get(type, "params", false);
            vm.meta.data = _.get(type, "data", null);
        }

        function getVariable(edit) {
            return function (meta, event) {
                event.stopPropagation();

                vm.meta = meta;
                if (!edit) submit();
                else vm.selectedTab = 2;
            }
        }

        function prepareMeta() {
            vm.meta.key = _.format("custom::{0}", vm.meta.slug);
        }

        function submit() {
            prepareMeta();
            $mdDialog.hide(vm.meta);
        }

        function saveVariable(validate) {
            prepareMeta();
            vm.reset()
            vm.selectedTab = 0;
            customVariableService
                .saveVariable(vm.meta)
                .then(success)

            function success() {
                if (validate) submit();
                else {
                    vm.meta = _.assign({
                        id: null,
                        type: "text"
                    }, {});

                    $scope.customVarForm.$setPristine();
                }
            }

        }

        function addNewToList(obj) {
            vm.meta.data.push(obj);
        }

        function removeFromList($index) {
            vm.meta.data.splice($index, 1);
        }
    }

})();