
(function () {
    "use strict";

    var dialog = {
        controller: ProviderDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/provider-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
        focusOnOpen: false
    };

    module.exports = dialog;

    ProviderDialogCtrl.$inject = ["$mdDialog", "$scope", "locationService", "providerService"];

    function ProviderDialogCtrl($mdDialog, $scope, locationService, providerService) {
        var vm = this;
        var res_valid = true;
        vm.submit = submit;
        vm.cancel = cancel;
        vm.checkUnique = checkUnique;
        init();

        function init() {
            vm.is_new = _.isNil(vm.provider) ? true : false;
            locationService.list('City').then(getCities);
            function getCities(data) {
                vm.cities = data;
            }
        }

        function checkUnique(name) {
            providerService.checkProviderExistence(name,vm.provider.id).then(done);
            function done(data) {
                res_valid = !_.isNil(data) ? false : true;
                $scope.providerForm['social_reason'].$setValidity('unique', res_valid);
            }
        }

        function submit() {
            providerService
                .saveProvider(vm.provider)
                .then(success);
            function success(data) {
                vm.provider = data;
                $mdDialog.hide(data);
            }
        }

        function cancel() {
            $mdDialog.cancel();
        }

    }


})();
