(function () {
    "use strict";

    var dialog = {
        controller: CommentPreviewDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/comment-preview.tpl.html"),
        parent: $(document.body),
        bindToController: true,
        clickOutsideToClose: false,
        focusOnOpen: false,
        multiple: true

    };

    module.exports = dialog;

    CommentPreviewDialogCtrl.$inject = ["$mdDialog", "system", "$translate", "$timeout"];

    function CommentPreviewDialogCtrl($mdDialog, system, $translate, $timeout) {
        var vm = this;
        vm.cancel = cancel;
        init();
        function init() {
            $timeout(function () {
                var elem =document.getElementById('comment-input').focus();

                if (elem) elem.focus();
            }, 300);
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }
})();
