(function () {
    "use strict";

    var dialog = {
        controller: CommentDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/comment-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
        focusOnOpen: false
    };

    module.exports = dialog;

    CommentDialogCtrl.$inject = ["$mdDialog", "system", "$translate", "$timeout"];

    function CommentDialogCtrl($mdDialog, system, $translate, $timeout) {
        var vm = this;
        vm.submit = submit;
        vm.cancel = cancel;


        init();

        function init() {
            $timeout(function () {
                var elem = document.getElementById('comment-text')
                if (elem) elem.focus();
            }, 300);
        }


        function submit() {
            $mdDialog.hide(vm.comment);
        }

        function cancel() {
            $mdDialog.cancel();
        }

    }


})();
