/**
 * Created by BETALOS on 03/10/2016.
 */
(function () {

    'use strict';

    module.exports = visitService;

    const {Subject, BehaviorSubject} = require("rxjs");

    visitService.$inject = ["$q", "$http", "$stateParams", "configService", "mnWebSocket", "stockService"];

    function visitService($q, $http, $stateParams, configService, mnWebSocket, stockService) {
        let self = this;

        self.getVisit = getVisit;
        self.updateVisit = updateVisit;
        self.removeVisits = removeVisits;
        self.privatizeVisits = privatizeVisits;
        self.unprivatizeVisits = unprivatizeVisits;
        self.getPatientVisits = getPatientVisits;

        self.handleProcedure = handleProcedure;
        self.getProcedures = getProcedures;
        self.removeProcedures = removeProcedures;

        self.validateDeliveryDocument = deliveryDocumentValidation();
        self.invalidateDeliveryDocument = deliveryDocumentValidation(false);

        /* self.getCareSheet = getCareSheet;
         self.saveCareSheet = saveCareSheet;
         self.deleteCareSheet = deleteCareSheet;
         self.invalidateCareSheet = careSheetValidation(false);
         self.validateCareSheet = careSheetValidation(true);*/

        //self.procedureItemsFromVisit = procedureItemsFromVisit;

        self.careSheetForm = new Subject();
        self.currentPatientSubject = new BehaviorSubject(null);
        self.currentVisitSubject = new BehaviorSubject(null);

        self.visitSubLinks = new BehaviorSubject(null);
        self.getSubLinksConfig = getSubLinksConfig;

        function getVisit(pk) {
            let deferred = $q.defer();
            let url = '/api/visit/' + pk + '/';

            let patient = $stateParams['pId'];

            $http.get(url)
                .then(success, deferred.reject);

            return deferred.promise;

            function success(response) {
                if (patient && response.data.patient_id !== patient) deferred.reject(null);
                else deferred.resolve(response.data);
            }
        }

        function getPatientVisits(patient, query) {
            let deferred = $q.defer();
            let url = `/api/visit/?patient=${patient}${_.isNil(query.start) ? "" : `&start=${query.start}`}${_.isNil(query.start) ? "" : `&end=${query.end}`}`;

            $http.get(url)
                .then(success, deferred.reject);

            return deferred.promise;

            function success(response) {
                deferred.resolve(response.data);
            }
        }

        function updateVisit(visit, fields) {
            const deferred = $q.defer();
            const url = `/api/visit/${visit.id}/`;
            const data = _.isNil(fields) ? visit : _.pick(visit, fields);
            const http = $http[_.isNil(fields) ? 'post' : 'put'](url, data);

            http.then(doneCallback, deferred.reject);

            return deferred.promise;

            function doneCallback(response) {
                deferred.resolve(response.data);
            }
        }

        function removeVisits(lists) {
            let ids = _.map(lists, 'id');
            return mnWebSocket.call('visit.Visit.remove', ids);
        }

        function privatizeVisits(lists) {
            let ids = _.map(lists, 'id');
            return mnWebSocket.call('visit.Visit.privatize', ids)
        }

        function unprivatizeVisits(lists) {
            let ids = _.map(lists, 'id');
            return mnWebSocket.call('visit.Visit.unprivatize', ids)
        }

        function handleProcedure(procedure) {
            let deferred = $q.defer();
            let url = `/api/procedure/${procedure.id ? procedure.id + '/' : ''}`;

            $http.post(url, procedure)
                .then(doneCallback, deferred.reject);

            return deferred.promise;

            function doneCallback(response) {
                deferred.resolve(response.data);
            }
        }

        function getProcedures() {
            let deferred = $q.defer();
            let url = '/api/procedure/';

            $q.all([$http.get(url), configService.get("procedure_sort", true)])
                .then(doneCallback, deferred.reject);

            return deferred.promise;

            function doneCallback(response) {
                let data = _.isNil(response[1]) ? response[0].data : _.orderBy(
                    response[0].data, item => _.get(response, `[1].${item.id}`)
                );


                deferred.resolve(data);
            }
        }

        function removeProcedures(procedure) {
            let url = `/api/procedure/${procedure.id}/`;
            return $http.delete(url);
        }

        // function procedureItemsFromVisit(visit) {
        //     return _.reduce(visit.procedures, handleReduce, []);
        //
        //     function handleReduce(result, value) {
        //         return _.concat(result, {
        //             code: value.procedure.code,
        //             qte: value.qte,
        //             name: value.name,
        //             price: value.price - value.discount,
        //             comment: value.comment
        //         });
        //     }
        // }

        function getSubLinksConfig() {
            return configService.get("visit_links_config", true)
                .then(success);

            function success(data) {
                self.visitSubLinks.next(data);
            }
        }

        function deliveryDocumentValidation(validate = true) {
            return (document) => {
                if (validate) return stockService.validateMovement(document, "DeliveryForm", "OUT");
                else return stockService.invalidateMovement(document, "DeliveryForm", "OUT");
            }
        }
    }

})();
