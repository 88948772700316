/**
 * Created by Hp on 01/03/2017.
 */
(function () {
    'use strict';

    module.exports = ArticleCtrl;

    ArticleCtrl.$inject = ["$state", "articleService", "$mdToast", "$translate", "$mdDialog"];

    function ArticleCtrl($state, articleService, $mdToast, $translate, $mdDialog) {

        var vm = this;
        vm.$onInit = init;
        vm.loadMedicineAsArticle = loadMedicineAsArticle;

        function init() {
            vm.reset = null;
            vm.filter = {is_deleted: {$ne:true}};
            vm.actions = {
                single: [
                    {
                        icon: 'mdi-pencil',
                        label: 'edition',
                        resource: 'article',
                        action: 'update',
                        behavior: 'disable',
                        method: goToEdition
                    },
                    {
                        icon: 'mdi-delete',
                        label: 'delete',
                        resource: 'article',
                        action: 'delete',
                        behavior: 'disable',
                        method: deleteArticle
                    }
                ],
                multiple: []
            }
        }

        function goToEdition(obj) {
            if (obj['is_medicine']) $state.go('app.stock.article-medicine-form', {articleId: obj.id}).then($state.reload);
            else  $state.go('app.stock.article-form', {articleId: obj.id}).then($state.reload);
        }

        function deleteArticle(obj) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_object_warning'))
                    .ariaLabel('delete_object_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                articleService.checkArticleDependencies(obj.id).then(checkSuccess);
            });
            function checkSuccess(data) {
                if (data == true) articleService.deleteArticle(obj.id).then(_.isFunction(vm.reset) ? vm.reset : _.noop);
                else showWarning();
            }

            function showWarning() {
                var simpleToast = $mdToast.simple()
                    .textContent($translate.instant('impossible_delete'))
                    .position("top right")
                    .hideDelay(3000);
                $mdToast.show(simpleToast);
            }
        }

        function loadMedicineAsArticle() {
            $mdToast.show($mdToast.mnAdvancedToast().handle(articleService.loadMedicines)).then(_.isFunction(vm.reset) ? vm.reset : _.noop);
        }
    }
})();