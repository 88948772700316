/**
 * Created by Hp on 04/05/2017.
 */
(function () {

    'use strict';

    var RemovingVisitDialog = {
        controller: RemovingVisitDialogCtrl,
        controllerAs: "vm",
        template: require("visit/views/removing-visit-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true
    };

    RemovingVisitDialogCtrl.$inject = ["$mdDialog"];

    function RemovingVisitDialogCtrl($mdDialog) {
        var vm = this;
        vm.cancel = $mdDialog.cancel;
    }

    module.exports = RemovingVisitDialog;

})();