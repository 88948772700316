/**
 * Created by BETALOS on 03/04/2017.
 */
(function () {
    'use strict';

    module.exports = {
        locals: {},
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: DictionaryModelDialogCtrl,
        template: require('stand-alone/blocks/views/dictionary-models-dialog.tpl.html'),
    };

    DictionaryModelDialogCtrl.$inject = ["$mdDialog", "dictionaryService"];

    function DictionaryModelDialogCtrl($mdDialog, dictionaryService) {
        let vm = this;

        vm.$onInit = init;
        vm.cancel = $mdDialog.cancel;

        vm.validateModels = validateModels;
        vm.checkSelectedValues = checkSelectedValues;
        vm.addModel = addModel;
        vm.removeModel = removeModel;

        vm.toggleModels = toggleModels;

        function init() {
            vm.dict = _.cloneDeep(dictionaryService.getGroup(vm.uid));
            vm.models = _.cloneDeep(dictionaryService.getModels(vm.uid));
            vm.newModel = null;
        }

        function validateModels() {
            let ids = _.chain(vm.models)
                .filter({selected: true})
                .map('values')
                .flatten()
                .value();

            $mdDialog.hide(getNodesByIds(vm.dict, ids));
        }

        function getNodesByIds(nodes, ids) {
            return _.reduce(nodes, (result, item) => {
                if (_.includes(ids, item.uid) && _.isNull(item.nodes)) result.push(item);
                else if (!_.isNull(item.nodes)) result = _.concat(result, getNodesByIds(item.nodes, ids));
                return result
            }, []);
        }

        function checkSelectedValues() {
            return _.size(getSelectedValues(vm.dict)) > 1;
        }

        function addModel($event) {
            let selectedValues = _.map(getSelectedValues(vm.dict), 'uid');

            let confirm = $mdDialog.mnPromptDialog()
                .title('exam_model_title')
                .event('shared.Dictionary.model_validation')
                .query({id: dictionaryService.getDictionaryID(), uid: vm.uid})
                .placeholder('title')
                .targetEvent($event)

            $mdDialog.show(confirm).then(success);

            function success(title) {
                let model = {title, values: selectedValues};

                dictionaryService.updateModels(vm.uid, model)
                    .then(doneCallback);

                function doneCallback(models) {
                    vm.models = _.cloneDeep(models);
                    let index = _.findIndex(vm.models, {title: title});

                    if (index == -1) return;
                    else vm.models[index]['selected'] = true;

                    toggleModels();
                }
            }
        }

        function getSelectedValues(nodes) {
            return _.reduce(nodes, (result, item) => {
                if (item.isChecked && _.isNull(item.nodes)) result.push(item)
                else if (!_.isNull(item.nodes)) result = _.concat(result, getSelectedValues(item.nodes));
                return result
            }, []);
        }

        function removeModel(index) {
            dictionaryService.removeModel(vm.uid, index)
                .then(models => vm.models = _.cloneDeep(models));

        }

        function toggleModels() {
            vm.modelsShown = !vm.modelsShown;
        }
    }

})()