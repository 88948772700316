/**
 * Created by amine on 22/02/2017.
 */

(function () {
    'use strict';

    class VisitProceduresCtrl {
        constructor(visitService) {
            this.visit = null;
            this.visitService = visitService;
        }

        static get $inject() {
            return ["visitService"];
        }

        $onInit() {
            if (!_.has(this.visit, "procedures")) this.visitService.getVisit(this.visit.id || this.visit)
                .then(visit => this.visit = visit);
        }
    }

    module.exports = {
        controller: VisitProceduresCtrl,
        controllerAs: "vm",
        bindings: {
            visit: "<visit"
        },
        template: require('visit/views/visit-procedures.tpl.html'),
    };
})();
