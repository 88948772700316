/**
 * Created by Issmahane on 24/12/2016.
 */
(function () {

    'use strict';

    module.exports = procedureService;

    procedureService.$inject = ["$q", "mnWebSocket", "$http"];

    function procedureService($q, mnWebSocket, $http) {
        var self = this;
        self.getProcedures = getProcedures;


        function getProcedures(query) {
            var obj = {
                query: query,
            };
            return mnWebSocket.call("shared.Procedure.search", obj);
        }


    }

})();
