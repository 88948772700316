(function () {

    'use strict';

    const Excel = require('xlsx');
    const canvasDataGrid = require('canvas-datagrid/dist/canvas-datagrid');

    class ExcelViewerCtrl {
        constructor($http, $element) {
            this.$http = $http;
            this.$element = $element;
        }

        static get $inject() {
            return ["$http", "$element"];
        }

        $onInit() {
            this.promise = this.$http.get(this.src, {responseType: 'arraybuffer'})
                .then(data => this.success(data));
        }

        $onDestroy() {
            this.grid = null;
            this.sheets = null;
            this.promise = null;
            this.workBookJson = null;
        }

        success(response) {
            const data = new Uint8Array(response.data);
            const workbook = Excel.read(data, {type: "array"});

            this.workBookJson = this.toJson(workbook);

            this.sheets = _.keys(this.workBookJson);
            this.currentSheet = _.head(this.sheets);

            this.grid = canvasDataGrid({
                tree: false,
                debug: false,
                editable: false,
                showPerformance: false,
                allowFreezingRows: true,
                allowRowReordering: true,
                borderDragBehavior: 'move',
                allowMovingSelection: true,
                allowFreezingColumns: true,
                parentNode: this.$element.get(0),
                data: this.workBookJson[this.currentSheet],
            });
        }

        toJson(workbook) {
            if (workbook.SSF) Excel.SSF.load_table(workbook.SSF);
            let result = {};

            workbook.SheetNames.forEach(function (sheetName) {
                let roa = Excel.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1});
                if (roa.length > 0) result[sheetName] = roa;
            });

            return result;
        }

        selectSheet(sheet) {
            this.currentSheet = sheet;
            this.grid.data = this.workBookJson[sheet];
        }
    }

    module.exports = {
        bindings: {
            src: '@'
        },
        controllerAs: "vm",
        controller: ExcelViewerCtrl,
        template: require('../views/excel-viewer.tpl.html')
    };

})();
