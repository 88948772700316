/**
 * Created by Hp on 02/03/2017.
 */
(function () {
    'use strict';

    module.exports = DepositionFormCtrl;

    DepositionFormCtrl.$inject = ["$mdDialog", "$transition$", "$scope", "stockService", "system", "$state", "$mdToast", "$translate", "purchaseService", "$q", "configService"];

    function DepositionFormCtrl($mdDialog, $transition$, $scope, stockService, system, $state, $mdToast, $translate, purchaseService, $q, configService) {

        var vm = this;

        var currentDeposition = $transition$.params('to')["depositionId"];
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.submit = submit;
        vm.invalidateDeposition = invalidateDeposition;
        vm.validateDeposition = validateDeposition;
        vm.isAllValid = isAllValid;
        vm.save = save;

        function init() {
            vm.is_new = !_.isNil(currentDeposition) ? false : true;
            vm.docType = 'DP';
            $scope.$watch('vm.deposition.is_valid', readOnlyDeposition);
            vm.modelName = 'GoodDeposition';

            vm.promises = [configService.get(["price_mode"], true)];
            if (!_.isNil(currentDeposition)) vm.promises.push(stockService.getStockDocument(currentDeposition, 'good-deposition'));
            else vm.deposition = {doc_date: moment().format(dateFormat) , deposition_start_date: moment().format(dateFormat), lines: []};
            vm.promise = $q.all(vm.promises).then(success);

            function success(data) {
                if (!_.isNil(currentDeposition)) vm.deposition = data[1];
                else vm.deposition.price_mode = data[0];
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success, error);
            }

            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else saveSuccess(data);
            }

            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.deposition = data;
            if (_.isNil(currentDeposition)) $state.go('app.stock.deposition-form', {depositionId: data.id}, {location: 'replace'});
        }

        function invalidateDeposition() {
            return stockService.invalidateMovement(vm.deposition, 'GoodDeposition', 'IN');
        }

        function validateDeposition(data) {
            return stockService.validateMovement(data, 'GoodDeposition', 'IN');
        }

        function readOnlyDeposition() {
            vm.readOnly = (vm.deposition && vm.deposition.is_valid) ? vm.deposition.is_valid : false;
        }

        function isAllValid() {
            return vm.deposition ? stockService.isAllValid(vm.deposition, 'IN', vm.modelName) : true;
        }

        function save() {
            return stockService.saveStockDocument(vm.deposition, "good-deposition", 'GoodDeposition');
        }
    }
})
();
