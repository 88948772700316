/**
 * Created by BETALOS on 25/10/2016.
 */
(function () {

    'use strict';

    module.exports = calculateFormula;

    function calculateFormula(formula, measurements, measures) {
        let handledFormula = _.chain(measures)
            .filter(i => !_.isNull(i.cat))
            .map(i => _.pick(i, ['id', 'cat']))
            .reduce((sum, value) => {
                let path = `${_.has(measurements, 'measure_values') ? 'measure_values.' : ''}${value.id}.value`;

                let measurement = _.get(measurements, path);
                let reg = new RegExp(_.escapeRegExp(`[${value.cat}]`), "g");

                return _.replace(sum, reg, measurement);
            }, formula)
            .value();

        try {
            let num = eval(handledFormula);
            return _.isNumber(num) && _.isFinite(num) ? num : undefined;
        }

        catch (error) {
            return undefined;
        }
    }

})();