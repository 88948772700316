(function () {
    'use strict';


    // remember to move it to angular service instead


    class MenuCtrl {
        constructor($mdPanel, $scope, $compile) {
            this.scope = $scope;
            this.panel = $mdPanel;
            this.compile = $compile;
        }

        open(event, element) {
            let compiledDirective = this.compile(element);
            let directiveElement = compiledDirective(this.scope);

            const config = {
                openFrom: event,
                focusOnOpen: true,
                targetEvent: event,
                escapeToClose: true,
                clickOutsideToClose: true,
                contentElement: directiveElement,
                position: this.panelPosition(event),
                animation: this.panelAnimation(event),
                attachTo: angular.element(document.body),
                onDomAdded: (ev) => {
                    $(_.get(ev, '1.panelContainer')).addClass('mn-menu-panel');
                }
            };

            return this.panel.open(config);
        }

        panelAnimation($event) {
            const target = angular.element($event.currentTarget);

            return this.panel.newPanelAnimation()
                .duration(150)
                .closeTo(target)
                .openFrom(target)
                .withAnimation(this.panel.animation.SCALE);
        }

        panelPosition($event) {
            return this.panel.newPanelPosition()
                .relativeTo($event.currentTarget)
                .addPanelPosition(
                    this.panel.xPosition.CENTER,
                    this.panel.yPosition.CENTER
                )
        }
    }

    module.exports = MenuCtrl;

})();