/**
 * Created by Hp on 08/03/2017.
 */


(function () {

    'use strict';

    var multiProvider = {
        controller: multiProviderCtrl,
        controllerAs: "vm",
        bindings: {
            providers: "=ngModel",
            mnLabel: "=mnLabel",
            disabled: "=mnDisabled"

        },
        template: tpl,
    };

    multiProviderCtrl.$inject = ["$mdDialog", "providerService"];

    function multiProviderCtrl($mdDialog, providerService) {

        var vm = this;

        vm.$onInit = init;
        vm.getProviders = providerService.getProviders;
        vm.transformChip = transformChip;

        vm.add = add;
        vm.showCheckTable = showCheckTable;

        function init() {
            vm.providers = [];
        }


        function transformChip(chip) {
            if (angular.isObject(chip)) {
                return chip;
            }
        }

        function add(ev) {
            const dialog = _.assign({},  require('../dialogs/provider-dialog'), {
                locals: {
                    provider: {}
                },
                clickOutsideToClose: false,
            });
            $mdDialog.show(dialog).then(done);
            function done(data) {
                if (_.chain(vm.providers).filter({social_reason: data.social_reason}).value().length == 0) vm.providers.push(data);
            }
        }

        function showCheckTable() {
            $mdDialog.show(_.assign(require('stock/dialogs/provider-search-dialog'), {
                locals: {
                    search: {is_deleted: {$ne:true}},
                }
            })).then(function (data) {
                if (_.chain(vm.providers).filter({social_reason: data.social_reason}).value().length == 0) vm.providers.push(data);
            });
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        var required = $elem.is("[required]") ? " required " : "";
        var mndisabled = !_.isUndefined(attrs['mnDisabled']) ? ' ng-disabled="vm.disabled" ' : "";

        var label = attrs['mnLabel'];
        $elem
            .addClass("layout-column")

        if (!$elem.is('[flex]')) $elem.addClass("flex");
        return [
            '<div class="control-block layout-row">',
            '<label  translate-once="' + label + '"></label>',
            '<span flex></span>',
            '<md-button class="md-icon-button" ng-click="vm.showCheckTable()" aria-label="select"  ' + mndisabled + '>',
            '<md-icon md-font-icon="mdi-magnify" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '<md-button  class="md-icon-button" ng-click="vm.add($event)" aria-label="add"  ' + mndisabled + '>',
            '<md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '</div>',
            '<md-chips ng-model="vm.providers" md-autocomplete-snap="" md-transform-chip="vm.transformChip($chip)" md-require-match="false">',
            '<md-autocomplete  md-selected-item="vm.provider" md-search-text="vm.searchText"' +
            ' md-items="item in vm.getProviders(vm.searchText)" md-item-text="item.social_reason"' +
            'md-no-cache="true" md-autoselect="true" md-delay="60" placeholder="Ajouter" ' + mndisabled + '> ',
            '<md-item-template> ',
            '<span md-highlight-text="vm.searchText" md-highlight-flags="i">{{ item.social_reason }}</span>',
            '  </md-item-template>',
            '<md-not-found>',
            '<span translate="provider_not_found"></span>',
            '</md-not-found>',
            '</md-autocomplete>',
            '<span md-highlight-text="item.social_reason">{{item.social_reason}} </span>',
            '<md-not-found>',
            '<div class="chip-not-found">Not found. Click here to add new.</div>',
            '</md-not-found>',
            '</md-autocomplete>',
            '<md-chip-template>',
            '<span>',
            '<strong>{{$chip.social_reason}}</strong>',
            '</span>',
            '</md-chip-template>',
            '</md-chips>',

        ].join('');
    }


    module.exports = multiProvider;


})();