/**
 * Created by Betalos on 27/06/2017.
 */
(function () {
    'use strict';

    module.exports = tableService;

    const {Subject} = require("rxjs");

    tableService.$inject = ["configService"];

    function tableService(configService) {
        let self = this;

        self.columnChangeSubject = new Subject();

        self.getColumns = getColumns;

        function getColumns(event, configKey) {
            const getEvent = `${event}.get_config`;
            return configService.get(configKey, true, false, getEvent);
        }
    }

})()
