/**
 * Created by BETALOS on 03/04/2017.
 */
(function () {
    'use strict';

    class NewEntryDialogCtrl {
        constructor($mdDialog, dictionaryService) {
            this.dialog = $mdDialog;
            this.service = dictionaryService;

            this.uid = this.uid ? this.uid : null;
            this.newEntry = this.newEntry ? this.newEntry : "";
        }

        static get $inject() {
            return ["$mdDialog", "dictionaryService"];
        }

        $onInit() {
            this.chosen = {};
            this.dict = _.cloneDeep(this.service.getGroup(this.uid));
        }

        cancel() {
            this.dialog.cancel();
        }

        choose() {
            this.chosen.nodes = _.isNull(this.chosen.nodes) ? _.castArray(this.newEntry) : _.concat(this.chosen.nodes, this.newEntry);
            this.promise = this.service.updateGroup(this.uid, this.dict)
                .then(dict => {
                    const chosen = this.getChosen(dict);
                    const newEntry = _.find(chosen.nodes, {value: this.newEntry.value});

                    this.dialog.hide(newEntry);
                });

            return this.promise;
        }

        getChosen(group) {
            return _.reduce(group, (result, item) => {
                if (_.eq(item.uid, this.chosen.uid)) result = _.assign(result, item);
                else if (!_.isEmpty(item.nodes)) result = _.assign(result, this.getChosen(item.nodes));
                return result;
            }, {});
        }
    }

    module.exports = {
        locals: {},
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: NewEntryDialogCtrl,
        template: require('stand-alone/blocks/views/dictionary-new-entry-dialog.tpl.html'),
    };


})()