/**
 * Created by amine on 26/07/2017.
 */
(function () {
    'use strict';

    module.exports = {
        textEditorService: require('./services/textEditorService'),
        customVariableService: require('./services/customVariableService'),
        editorTemplateService: require('./services/editorTemplateService'),
        mnTextEditor: require('./components/text-editor'),
        mnSMSEditor: require('./components/sms-editor')
    };

})()