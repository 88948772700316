/**
 * Created by BETALOS on 16/03/2016.
 */
(function () {

    'use strict';

    module.exports = physicianService;

    const {Subject, BehaviorSubject} = require("rxjs");

    physicianService.$inject = ["$http", "$q"];

    function physicianService($http, $q) {
        let self = this;

        self.newContactSubject = new Subject();
        self.physicianSubject = new BehaviorSubject([]);

        self.list = list;
        self.getContacts = getContacts;
        self.addContact = addContact;

        self.getFullContact = getFullContact;
        self.removePhysicianContact = removePhysicianContact;

        self.agendaResources = agendaResources;


        function list() {
            let deferred = $q.defer();

            $http.get('/api/physician-minimal/')
                .then(success, deferred.reject);

            function success(result) {
                self.physicianSubject.next(result.data);
                deferred.resolve(result.data);
            }

            return deferred.promise;
        }

        function agendaResources(filter, useAbbr) {
            const deferred = $q.defer();
            const physicians = _.get(filter, 'physician', []);

            self.physicianSubject.subscribe(success);

            function success(data) {
                const key = useAbbr ? 'name_initials' : 'full_name';
                const items = _.chain(data)
                    .filter(item => physicians.length > 0 ? _.includes(physicians, item.id) : true)
                    .map(item => new Object({title: item[key], id: `physician_${item.id}`}))
                    .value();

                deferred.resolve(items);
            }

            return deferred.promise;
        }

        function getContacts() {
            let deferred = $q.defer();

            $http.get('/api/physician-contact-minimal/')
                .then(success, deferred.reject);

            function success(result) {
                deferred.resolve(result.data);
            }

            return deferred.promise;
        }

        function addContact(physician) {
            let deferred = $q.defer();
            let url = '/api/physician-contact/';
            if (_.has(physician, 'id')) url += physician.id + '/';

            $http.post(url, physician)
                .then(success, deferred.reject);

            function success(result) {
                deferred.resolve(result.data);
            }

            return deferred.promise;
        }

        function getFullContact(physician) {
            let deferred = $q.defer();
            let url = `/api/physician-contact/${physician.id}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(result) {
                deferred.resolve(result.data);
            }

            return deferred.promise;
        }

        function removePhysicianContact(physician) {
            let url = `/api/physician-contact/${physician.id}/`;
            return $http.delete(url);
        }
    }

})();
