/**
 * Created by Betalos on 27/06/2017.
 */
(function () {
    'use strict';

    const ORDER_DIALOG = require('../dialogs/dcm-order-dialog');
    const DCM_INSTANCES_DIALOG = require('../dialogs/dcm-instances-dialog');

    module.exports = dcmService;

    dcmService.$inject = [
        "$q", "$http", "mnWebSocket", "$mdDialog", "patientService", "$mdToast", "$translate", "$timeout"
    ];

    function dcmService($q, $http, mnWebSocket, $mdDialog, patientService, $mdToast, $translate, $timeout) {
        let self = this;

        self.getModalities = getModalities;
        self.getAets = getAets;

        self.handleAet = handleAet;
        self.removeAet = removeAet;
        self.updateModality = updateModality;

        self.getViewers = getViewers
        self.handleViewer = handleViewer;
        self.removeViewer = removeViewer;

        self.getDmcServiceConfigs = getDmcServiceConfigs;
        self.handleDmcServiceConfig = handleDmcServiceConfig;
        self.startDmcService = startDmcService;
        self.stopDmcService = stopDmcService;
        self.restartDmcService = restartDmcService;

        self.createDcmOrder = createDcmOrder;
        self.saveDcmOrder = saveDcmOrder;

        self.importDcmInstance = importDcmInstance;
        self.importLastDcmInstance = importLastDcmInstance;
        self.handleDcmInstance = handleDcmInstance;


        function getModalities() {
            const deferred = $q.defer();
            const url = '/api/dcm-modality/';

            $http.get(url)
                .then(success, deferred.reject)

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getAets() {
            const deferred = $q.defer();
            const url = '/api/dcm-aet/';

            $http.get(url)
                .then(success, deferred.reject)

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }


        function handleAet(aet) {
            const deferred = $q.defer();
            const url = `/api/dcm-aet/${aet.id ? aet.id + '/' : ''}`;

            $http.post(url, aet)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function removeAet(aet) {
            return $http.delete(`/api/dcm-aet/${aet.id}/`);
        }

        function updateModality(modality) {
            const deferred = $q.defer();
            const url = `/api/dcm-modality/${modality.id}/`;

            $http.post(url, modality)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        // viewer configs
        function getViewers() {
            const deferred = $q.defer();
            const url = '/api/dcm-viewer-config/';

            $http.get(url)
                .then(success, deferred.reject)

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleViewer(viewer) {
            const deferred = $q.defer();
            const url = `/api/dcm-viewer-config/${viewer.id ? viewer.id + '/' : ''}`;

            $http.post(url, viewer)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function removeViewer(viewer) {
            const url = `/api/dcm-viewer-config/${viewer.id}/`;
            return $http.delete(url);
        }

        function getDmcServiceConfigs() {
            const deferred = $q.defer();
            const url = '/api/dcm-service-config/';

            $http.get(url)
                .then(success, deferred.reject)

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleDmcServiceConfig(config) {
            const deferred = $q.defer();
            const url = `/api/dcm-service-config/${config.id ? config.id + '/' : ''}`;

            $http.post(url, config)
                .then(success, deferred.reject)

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function startDmcService(config) {
            return mnWebSocket.call("dcm.DcmServiceHandler.start_service", _.pick(config, ["type", "service_name"]));
        }

        function stopDmcService(config) {
            return mnWebSocket.call("dcm.DcmServiceHandler.stop_service", _.pick(config, ["type", "service_name"]));
        }

        function restartDmcService(config) {
            return mnWebSocket.call("dcm.DcmServiceHandler.restart_service", _.pick(config, ["type", "service_name"]));
        }


        function createDcmOrder(patient, ev) {
            $mdDialog.show(_.assign({}, ORDER_DIALOG, {
                targetEvent: ev,
                locals: {
                    patient_id: patient
                }
            }));
        }

        function saveDcmOrder(order) {
            const deferred = $q.defer();
            const url = `/api/dcm-modality-work-item/${order.id ? order.id + '/' : ''}`;

            $http.post(url, order)
                .then(success, deferred.reject)

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function importDcmInstance(patient, preSave, context, ev, resolve = false) {
            const deferred = $q.defer();

            patientService.getMinimalPatient(patient)
                .then(done);

            function done(data) {
                const query = {patient_first_name: data['first_name'], patient_last_name: data['last_name']};
                const dialog = _.assign({}, DCM_INSTANCES_DIALOG, {
                    targetEvent: ev,
                    locals: {
                        query, preSave, context, resolve, patient, fullName: data['full_name']
                    }
                })

                $mdDialog.show(dialog)
                    .then(instance => deferred.resolve(instance), deferred.reject);
            }

            return deferred.promise;
        }

        function importLastDcmInstance(patient, preSave, context, resolve = false) {
            const deferred = $q.defer();

            mnWebSocket.call('dcm.DcmInstanceImport.patient_last_dcm_instance', {patient})
                .then(success, error);

            function success(instance) {
                if (instance) {
                    if (resolve) deferred.resolve(instance);
                    else if (!_.isNil(preSave)) preSave().then(() => startSave(instance));
                    else startSave(instance);
                }

                else error();
            }

            function startSave(instance) {
                handleDcmInstance(patient, instance, context).then(deferred.resolve, error);
            }

            function error() {
                let simpleToast = $mdToast.simple()
                    .textContent($translate['instant']('dcm_patient_has_no_study'))
                    .position("bottom left")
                    .hideDelay(2500);

                $timeout(() => {
                    $mdToast.show(simpleToast);
                    deferred.reject();
                }, 100);
            }

            return deferred.promise;
        }

        function handleDcmInstance(patient, instance, context) {
            return mnWebSocket.call('dcm.DcmInstanceImport.handle_imported_dcm_files', {
                patient, context, instance: instance.id,
            })
        }
    }

})()