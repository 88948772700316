(function () {

    'use strict';

    module.exports = PatientsByAgeCtrl;

    PatientsByAgeCtrl.$inject = ["statesService", "$translate", "$element"];

    function PatientsByAgeCtrl(statesService, $translate, $element) {
        var vm = this;
        var utils = require('../utils/activity-functions');

        vm.$onInit = getData;
        vm.exportToExcel = exportToExcel;

        function getData() {
            vm.promise = statesService.getPatientsByAge()
                .then(success);

            function success(data) {
                $("#output-patients-by-age", $element).pivot(data, {
                    rows: _.castArray($translate['instant']("state_patients_age")),
                    cols: [],
                    localeStrings: "fr",
                    derivedAttributes: keysToShow()
                }, 'fr');
            }
        }

        function keysToShow() {
            var keysToShow = {};

            keysToShow[$translate['instant']("state_patients_age")] = function (record) {
                return (record['patient_age'] - record['patient_age'] % 10) + ' - ' + ((record['patient_age'] - record['patient_age'] % 10) + 10);
            }

            return keysToShow;
        }

        function exportToExcel(element, title) {
            utils.exportToExcel(
                element,
                title,
                {},
                $translate
            )
        }
    }

})();
