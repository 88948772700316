/**
 * Created by Betalos on 27/06/2017.
 */
(function () {

    'use strict';

    module.exports = thRow;

    thRow.$inject = ["$translate"];

    function thRow($translate) {

        var directive = {
            restrict: 'A',
            priority: 0,
            link: {
                pre: preLink
            },
        };

        function preLink(scope, element, attributes) {
            var attr = _.get(attributes, 'thRow');
            scope.$watch(attr, handleColumn);

            function handleColumn(column) {
                var $span = $('<span />').text($translate['instant'](column.label));
                var thClass = getTypeClass(column.type);

                element.removeClass('date-column md-numeric');

                element.html($span);
                element.addClass(thClass);
            }

            function getTypeClass(type) {
                if (_.eq(type, 'date')) return 'date-column';
                else if (_.eq(type, 'number')) return 'md-numeric';
                else return "";
            }
        };

        return directive;
    };

})();