
/**
 * Created by BETALOS on 03/10/2016.
 */
(function () {
    'use strict';

    module.exports = VisitMainCtrl;

    VisitMainCtrl.$inject = ["$transition$", "visitService", "authService", "$mdDialog", "patientService", "$q", "configService", "mnWebSocket"];

    function VisitMainCtrl($transition$, visitService, authService, $mdDialog, patientService, $q, configService, mnWebSocket) {
        let vm = this;

        let subLinks = require('parameters/json/visit-sub-links.json');
        let proceduresDialog = require('../dialogs/visit-financial-dialog');

        let currentPatient = _.get($transition$.params('to'), 'pId');
        let currentVisit = _.get($transition$.params('to'), 'visitId');

        vm.$onInit = init;
        vm.updateVisit = updateVisit;

        vm.showProcedures = showProcedures;

        function init() {
            let isDental = configService.isDental();
            if (!isDental) {
                _.remove(subLinks, ['key', 'DENTAL']);
                _.remove(subLinks, ['key', 'PERIODONTAL']);
            }

            if (!authService.aclHandler({action: "get", resource: "payment"})) {
                _.remove(subLinks, ['key', 'FINANCIAL_STATEMENT']);
            }

            vm.$onDestroy = destroy;

            let visitSubLinksSubscription = visitService.visitSubLinks.subscribe(handleSubLinks);

            $q.all([
                visitService.getVisit(currentVisit, currentPatient),
                patientService.getMinimalPatient(currentPatient),
                configService.get("visit_links_config", true)
            ]).then(doneCallback, error);

            function destroy() {
                visitSubLinksSubscription.unsubscribe();
            }
        }

        function updateVisit(field) {
            return visitService.updateVisit(vm.visit, field);
        }

        function error() {
            window.history.back();
        }

        function handleSubLinks(data) {
            vm.subLinks = _.chain(subLinks)
                .filter(item => !_.get(data, `["disabled"]["${item.key}"]`, false))
                .orderBy(item => _.get(data, `["order"]["${item.key}"]`))
                .value();
        }

        function doneCallback(data) {
            vm.visit = data[0];
            visitService.currentVisitSubject.next(vm.visit);
            visitService.currentPatientSubject.next(data[1]);
        }

        function showProcedures(ev) {
            $mdDialog.show(_.assign({}, proceduresDialog, {
                targetEvent: ev,
                locals: {
                    visit: currentVisit
                }
            })).then(done, _.noop);

            function done(data) {
                vm.visit = data;
                updateVisit(['procedures', 'financial_status', 'delivery_document'])
                    .then(data => mnWebSocket.pub('dental_consultation.Dental.payments_update', {visit: data.id}, false));
            }
        }

    }

})();