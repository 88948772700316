/**
 * Created by amine on 02/01/2019.
 */
(function () {
    'use strict';

    class PathologyService {
        constructor($q, $http, mnWebSocket, $translate, $mdDialog) {
            this.$q = $q;
            this.$http = $http;
            this.ws = mnWebSocket;
            this.$translate = $translate;
            this.$mdDialog = $mdDialog;
        }

        static get $inject() {
            return ["$q", "$http", "mnWebSocket", "$translate", "$mdDialog"];
        }

        savePathology(model) {
            let deferred = this.$q.defer();
            const url = `/api/pathology-list/${_.isUndefined(model.id) ? "" : `${model.id}/`}`;

            this.$http.post(url, model).then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }
    }

    module.exports = PathologyService;
})();