(function () {
    "use strict";

    let XLSX = require('xlsx');

    module.exports = {
        keysToShow: keysToShow,
        numberFormat: numberFormat,
        aggregatorPay: aggregatorPay,
        exportToExcel: exportToExcel,
        aggregatorProcedures: aggregatorProcedures,
    };

    let numberFormat = $.pivotUtilities.numberFormat({
        digitsAfterDecimal: 2,
        thousandsSep: " ",
        decimalSep: ",",
        showZero: true
    });

    function keysToShow($translate, key) {
        return {
            [$translate['instant']("payment_mode")]: paymentMode(),
            [$translate['instant']("patient_last_first_name")]: patientName(),
            [$translate['instant'](key)]: key === "states_procedure_name" ? directShow("procedure_name") : showPayField()
        };

        function patientName() {
            return function (record) {
                return record["patient_last_name"] + " " + record["patient_first_name"];
            }
        }

        function paymentMode() {
            return function (record) {
                return _.isNil(record["payment_mode"]) ? "-" : record["payment_mode"];
            }
        }

        function directShow(key) {
            return function (record) {
                return _.get(record, key, 0);
            }
        }

        function showPayField() {
            return function (record) {
                return $translate['instant']("states_payed_amount");
            }
        }
    }

    function aggregatorPay(data, rowKey, colKey) {
        return {
            sum: 0,
            push: function (elem) {
                return this.sum += parseFloat(elem['amount']);
            },
            value: function () {
                return this.sum.toFixed(2);
            },
            format: numberFormat
        };
    }


    function aggregatorProcedures(data, rowKey, colKey) {
        return {
            sum: 0,
            push: function (elem) {
                return this.sum += parseFloat(elem['procedure_price']) * parseFloat(elem['procedure_quantity']);
            },
            value: function () {
                return this.sum.toFixed(2);
            },
            format: numberFormat
        };
    }

    function exportToExcel(element, title, query, $translate) {
        let elt = document.getElementById(element);
        let label = _.chain($translate['instant'](title, query))
            .deburr()
            .replace(/[^a-z0-9]/gi, '_')
            .truncate()
            .value();

        let wb = XLSX.utils.table_to_book(elt, {sheet: label, raw: true});

        return XLSX.writeFile(wb, label + '.xlsx');
    }

})();