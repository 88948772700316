/**
 * Created by BETALOS on 07/03/2017.
 */
(function () {

    'use strict';

    module.exports = DayActivityCtrl;

    DayActivityCtrl.$inject = ["statesService", "system", "$translate", "$element", "$timeout"];

    function DayActivityCtrl(statesService, system, $translate, $element, $timeout) {
        let vm = this;

        let dateFormat = system['date_format'].js;
        let utils = require('../utils/activity-functions');

        vm.$onInit = init;

        vm.getData = _.mnDelay(getData, 400);
        vm.exportToExcel = exportToExcel;

        function init() {
            vm.globalTotal = 0;

            vm.query = {
                date: moment().format(dateFormat)
            };

            getData();
        }

        function getData() {
            vm.promise = statesService.getDailyActivities(vm.query)
                .then(success);

            function success(data) {
                $("#output-procedures", $element).pivot(data['procedures'], {
                    rows: _.castArray($translate['instant']("patient_last_first_name")),
                    cols: _.castArray($translate['instant']("states_procedure_name")),
                    aggregator: utils.aggregatorProcedures,
                    derivedAttributes: utils.keysToShow($translate, "states_procedure_name"),
                });

                $("#output-payments", $element).pivot(data['payments'], {
                    rows: [$translate['instant']("payment_mode"), $translate['instant']("patient_last_first_name")],
                    cols: _.castArray($translate['instant']("states_receipts")),
                    aggregator: utils.aggregatorPay,
                    derivedAttributes: utils.keysToShow($translate, "states_receipts"),
                });


                $timeout(function () {
                    let procedures = $("#output-procedures .pvtGrandTotal", $element).data('value');
                    let payments = $("#output-payments .pvtGrandTotal", $element).data('value');

                    vm.proceduresLength = $("#output-procedures tbody tr", $element).length - 1;
                    vm.paymentsLength = $("#output-payments tbody tr", $element).length - 1;
                    vm.globalTotal = parseFloat(payments) - parseFloat(procedures);
                })
            }
        }


        function exportToExcel(element, title) {
            utils.exportToExcel(
                element,
                title,
                vm.query,
                $translate
            )
        }
    }

})();