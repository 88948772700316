(function () {

    'use strict';

    class FullScreen {
        static goFullScreen() {
            if (document.documentElement.requestFullScreen) document.documentElement.requestFullScreen();
            else if (document.documentElement.mozRequestFullScreen) document.documentElement.mozRequestFullScreen();
            else if (document.documentElement.webkitRequestFullScreen) document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);


            try {
                screen.orientation.lock('landscape');
            }
            catch (e) {}
        }

        static cancelFullScreen() {
            if (document.cancelFullScreen) document.cancelFullScreen();
            else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
            else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
        }
    }

    module.exports = FullScreen;

})()