/**
 * Created by Hp on 02/03/2017.
 */
(function () {
    'use strict';

    module.exports = ReceiptFormCtrl;

    ReceiptFormCtrl.$inject = ["purchaseService", "$transition$", "$scope", "stockService", "system", "$state", "$mdToast", "$translate", "$mdDialog" ,"$q", "configService"];

    function ReceiptFormCtrl(purchaseService, $transition$, $scope, stockService, system, $state, $mdToast, $translate, $mdDialog,$q, configService) {

        var vm = this;

        var currentReceipt = $transition$.params('to')["receiptId"];
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.submit = submit;
        vm.invalidateReceipt = invalidateReceipt;
        vm.validateReceipt = validateReceipt;
        vm.isAllValid = isAllValid;
        vm.save = save;

        function init() {
            $scope.$watch('vm.receipt.is_valid', readOnlyReceipt);
            vm.is_new = !_.isNil(currentReceipt) ? false : true;
            vm.docType = 'BR';
            vm.modalFilter = {is_deleted: {$ne:true}, is_valid: {$ne:false}};
            vm.modelName = 'PurchaseReceipt';

            vm.promises = [ configService.get(["price_mode"], true)];
            if (!_.isNil(currentReceipt))   vm.promises.push( stockService.getStockDocument(currentReceipt, 'purchase-receipt'));
            else {
                vm.receipt = {
                    doc_date: moment().format(dateFormat),
                    lines: [],
                };
            }
            vm.promise = $q.all(vm.promises).then(success);
            function success(data) {
                if (!_.isNil(currentReceipt)) vm.receipt = data[1];
                else vm.receipt.price_mode = data[0];
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success, error);
            }
            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else  saveSuccess(data);
            }

            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.receipt = data;
            if (_.isNil(currentReceipt)) $state.go('app.stock.receipt-form', {receiptId: data.id}, {location: 'replace'});
        }

        function invalidateReceipt() {
            return stockService.invalidateMovement(vm.receipt, 'PurchaseReceipt', 'IN');
        }

        function validateReceipt(data) {
            return stockService.validateMovement(data, 'PurchaseReceipt', 'IN');
        }

        function readOnlyReceipt() {
            vm.readOnly = (vm.receipt && vm.receipt.is_valid) ? vm.receipt.is_valid : false;
        }

        function isAllValid() {
            return vm.receipt ? stockService.isAllValid(vm.receipt, vm.docType, vm.modelName) : true;
        }

        function save() {
            return stockService.saveStockDocument(vm.receipt, "purchase-receipt", 'PurchaseReceipt');
        }
    }
})
();
