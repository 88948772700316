/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {
    'use strict';

    const angular = require('angular');

    const calendar = require('./calendar/');

    const dcm = require('./dcm/');

    const fileManager = require('./file-manager/');

    const patientSummary = require('./patient-summary/');

    const mnImageEdition = require('./image-edition/');

    const interfacing = require('./interfacing/');

    const table = require('./table/');

    const editor = require('./text-editor/');

    const blocks = require('./blocks/');

    angular
        .module("medinet")

        .component("mnCalendar", calendar)


        .service("dcmService", dcm.dcmService)

        .filter('fileSize', fileManager.fileSize)
        .service('uploadService', fileManager.uploadService)
        .component('mnFiles', fileManager.mnFiles)
        .component('mnFileItem', fileManager.mnFileItem)
        .component('mnFileVisualizer', fileManager.mnFileVisualizer)
        .component('mnPdfViewer', fileManager.mnPdfViewer)
        .component('mnExcelViewer', fileManager.mnExcelViewer)
        .component('filesFilter', fileManager.filesFilter)
        .component('mnImageEditor', fileManager.mnImageEditor)
        .component('mnImageComparison', fileManager.mnImageComparison)
        .component('mnContextualFiles', fileManager.mnContextualFiles)

        .component('mnPatientSummary', patientSummary.mnPatientSummery)
        .component('mnVisitSummary', patientSummary.mnVisitSummary)
        .component('mnConsultationsListSummary', patientSummary.mnConsultationsListSummary)
        .component('mnConsultationSummary', patientSummary.mnConsultationSummary)
        .component('mnPrescriptionsListSummary', patientSummary.mnPrescriptionsListSummary)
        .component('mnCertificatesListSummary', patientSummary.mnCertificatesListSummary)
        .component('mnExamsListSummary', patientSummary.mnExamsListSummary)
        .directive('mnPatientSummaryLink', patientSummary.mnPatientSummaryLink)

        .component('mnImageEdition', mnImageEdition)

        .service('interfacingService', interfacing.interfacingService)
        .component('interfacingFormBlock', interfacing.formBlockField)

        .component('mnTableMain', table.tableMain)
        .component('columnMenu', table.columnMenu)
        .directive('thRow', table.thRow)
        .directive('thSearch', table.thSearch)
        .service('tableTestService', table.tableService)

        .service('textEditorService', editor.textEditorService)
        .service('customVariableService', editor.customVariableService)
        .service('editorTemplateService', editor.editorTemplateService)
        .component('mnTextEditor', editor.mnTextEditor)
        .component('mnSmsEditor', editor.mnSMSEditor)


        .component("mnAutocomplete", blocks.mnAutocomplete)
        .component("mnDictionary", blocks.mnDictionary)
        .component("mnFreeDictionary", blocks.mnFreeDictionary)
        .component("mnHtmlBlock", blocks.mnHtmlBlock)
        .component("mnDictTree", blocks.mnDictTree)
        .component("mnFileBlock", blocks.mnFileBlock)
        .component("mnTree", blocks.mnTree)
        .component("mnSurveyBlockResume", blocks.surveyResume)
        .component("mnSurveyBlockQuestion", blocks.surveyQuestion)
        .component("mnChoiceList", blocks.mnChoiceList)

        .service("blockService", blocks.blockService)
        .service("dictionaryService", blocks.dictionaryService)
        .service("autoCompleteService", blocks.autoCompleteService)
})();
