/**
 * Created by BETALOS on 11/02/2016.
 */
(function () {
    'use strict';

    module.exports = tableService;

    tableService.$inject = ["$q", "mnWebSocket", "$http"];

    function tableService($q, mnWebSocket, $http) {
        let self = this;

        self.getData = getData;
        self.getColumns = getColumns;
        self.updateColumns = updateColumns;
        self.updateFilters = updateFilters;
        self.generateExcel = generateExcel;
        self.handleFilter = handleFilter;

        function getData(model, query, columns) {
            let deferred = $q.defer();
            let event = `${model}.list`;

            mnWebSocket.call(event, query)
                .then(success, deferred.reject);

            function success(data) {
                data.list = handleList(data.list);
                deferred.resolve(data);
            }

            function handleList(list) {
                return _.map(
                    list, item => _.assign(
                        item, handleColumnMap(item), {'patId': _.get(item, 'patient.id')}
                    )
                );
            }

            function handleColumnMap(item) {
                return _.reduce(columns, (data, column) => {
                    return _.assign(data, {
                        [column.label]: _.get(item, _.chain(item).get(column.label).isNil().value() ? column['order_by'] : column.label)
                    })
                }, {});
            }

            return deferred.promise;
        }

        function getColumns(model, reload = false) {
            const deferred = $q.defer();
            const event = `${model}.get_config`;
            const localConfig = getConfig(event);

            if (!localConfig || reload) mnWebSocket.call(`${model}.get_config`, {}).then(
                data => setConfig(event, data, deferred), err => deferred.reject(err)
            );
            else deferred.resolve(localConfig);

            return deferred.promise;
        }

        function getConfig(event) {
            const localConfig = localStorage.getItem(event);

            if (localConfig) return JSON.parse(localConfig);
            else return null;
        }

        function setConfig(event, config, deferred) {
            const jsonConfig = JSON.stringify(config);
            localStorage.setItem(event, jsonConfig);

            deferred.resolve(config);
        }

        function updateColumns(model, columns) {
            const deferred = $q.defer();
            const event = `${model}.get_config`;

            mnWebSocket.call(`${model}.update_columns`, columns).then(
                data => setConfig(event, data, deferred), err => deferred.reject(err)
            )

            return deferred.promise;
        }

        function generateExcel(model, query, ev) {
            return mnWebSocket.call(`${model}.generate_excel`, query);
        }

        function handleFilter(model) {
            const deferred = $q.defer();
            const url = `/api/table-filter/${model.id ? model.id +'/':''}`;

            $http.post(url, model)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function updateFilters(model, filters) {
            const deferred = $q.defer();
            const event = `${model}.get_config`;

            mnWebSocket.call(`${model}.update_filters`, filters).then(
                data => setConfig(event, data, deferred), err => deferred.reject(err)
            )

            return deferred.promise;
        }

    }

})()