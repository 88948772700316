/**
 * Created by Hp on 16/05/2017.
 */
(function () {
    'use strict';
    let mnDocumentNumber = {
        controller: MnDocumentLinesCtrl,
        controllerAs: "vm",
        bindings: {
            stockDocument: "=ngModel",
            disabled: "=mnDisabled",
            mnModel: "=mnModel",
        },
        template: tpl,
    };
    MnDocumentLinesCtrl.$inject = ["$attrs", "sequenceService", "$scope", "stockService", "$q", '$translate'];

    function MnDocumentLinesCtrl(attrs, sequenceService, $scope, stockService, $q, $translate) {
        let vm = this;
        vm.$onInit = init;
        vm.chooseNumber = chooseNumber;
        // let mnModel = !_.isUndefined(attrs['mnModel']) ? attrs['mnModel'] : null;
        let pkg = !_.isUndefined(attrs['pkg']) ? attrs['pkg'] : null;
        let label = attrs['mnLabel'];
        vm.field = attrs['mnField'] || 'number';

        function init() {
            let firstCall = true;
            let killWatch = $scope.$watch('vm.stockDocument', function (newVal) {
                if (firstCall && _.isUndefined(newVal)) {
                    firstCall = false;
                    return;
                }
                else {
                    if (!_.has(newVal, 'id')) {
                        let promises = [sequenceService.getAvailableSequence({
                            _model: vm.mnModel,
                            _package: pkg,
                            _label: $translate.instant(label)
                        }),
                            stockService.getNonTakenNumbers(vm.mnModel, pkg)];
                        vm.promise = $q.all(promises).then(success);
                    }
                    _.isFunction(killWatch) ? killWatch() : _.noop();
                }
            });

            function success(data) {
                if (!_.has(vm.stockDocument, 'id')) vm.stockDocument[vm.field] = data[0];
                vm.nonTakenNumbers = data[1];
            }
        }

        function chooseNumber(item) {
            vm.stockDocument[vm.field] = item[vm.field];
            vm.stockDocument.id = item.id;
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        let mnDisabled = !_.isUndefined(attrs['mnDisabled']) ? ' ng-disabled="vm.disabled" ' : "";

        let label = attrs['mnLabel'];
        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        if (!$elem.is('[flex]')) $elem.addClass("flex-nogrow");
        return [
            '<md-input-container>',
            '<label translate-once="' + label + '"></label>',
            '<input ng-model="vm.stockDocument[vm.field]" required readonly>',
            '<div ng-if="!vm.stockDocument.id && vm.nonTakenNumbers.length>0" class="mn-option-buttons flex-nogrow layout-row">',
            '<md-menu >' +
            '<md-button aria-label="Open menu"   class="md-icon-button" ' +
            'ng-click="$mdMenu.open($event)">',
            '<md-icon md-font-icon="mdi-dots-vertical" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '<md-menu-content class="mn-doc-number-menu">',
            '<md-menu-item ng-repeat="item in vm.nonTakenNumbers track by $index">',
            '<md-button aria-label="Open menu"  ng-click="vm.chooseNumber(item)">',
            '<span ng-bind="item[vm.field]"></span>',
            '</md-button>',
            '</md-menu-item>',
            '</md-menu-content>',
            '</md-menu>',
            '</div>',
            '</md-input-container>',

        ].join('');
    }

    module.exports = mnDocumentNumber;
})();