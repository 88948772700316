/**
 * Created by Betalos on 27/06/2017.
 */
(function () {
    'use strict';

    module.exports = {
        mnFiles: require('./components/files'),
        fileSize: require('./filters/file-size'),
        mnFileItem: require('./components/file-item'),
        mnPdfViewer: require('./components/pdf-viewer'),
        filesFilter: require('./components/files-filter'),
        uploadService: require('./services/uploadService'),
        mnExcelViewer: require('./components/excel-viewer'),
        mnImageEditor: require('./components/image-editor'),
        mnImageComparison: require('./components/image-comparison'),
        mnFileVisualizer: require('./components/file-visualizer'),
        mnContextualFiles: require('./components/contextual-files'),
    };

})()