(function () {

    'use strict';

    module.exports = ClientRecoveryCtrl;

    ClientRecoveryCtrl.$inject = ["statesService", "$translate", "$element"];

    function ClientRecoveryCtrl(statesService, $translate, $element) {
        var vm = this;
        vm.$onInit = init;

        function init() {
            vm.recoveries = [];
            vm.balanceBases=["invoice_deadline","subscription_payment_deadline"];

            vm.promise = statesService.getRecoveries(vm.query)
                .then(success);

            function success(data) {
                vm.recoveries = data;
                vm.totals = {
                    'm_15': _.sumBy(vm.recoveries, 'm_15'),
                    'm_30': _.sumBy(vm.recoveries, 'm_30'),
                    'm_60': _.sumBy(vm.recoveries, 'm_60'),
                    'm_120': _.sumBy(vm.recoveries, 'm_120')
                }
            }
        }
    }
})();
