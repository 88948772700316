/**
 * Created by BETALOS on 07/03/2017.
 */
(function () {

    'use strict';

    module.exports = statesService;

    statesService.$inject = ["mnWebSocket", "$q", "$translate"];

    function statesService(mnWebSocket, $q, $translate) {
        let self = this;

        self.getDailyActivities = getDailyActivities;
        self.getMonthActivities = getMonthActivities;
        self.getMonthGraphActivities = getMonthGraphActivities;
        self.getYearActivities = getYearActivities;
        self.getPeriodicActivities = getPeriodicActivities;
        self.getInsuredPatients = getInsuredPatients;
        self.getPatientsByAge = getPatientsByAge;
        self.getVisitReport = getVisitReport;
        self.getEntryReport = getEntryReport;
        self.getPatientStatus = getPatientStatus;
        self.getAgingBalance = getAgingBalance;
        self.getRecoveries = getRecoveries;
        self.getPathologies = getPathologies;



        function getDailyActivities(query) {
            return mnWebSocket.call('states.States.daily_activities', query);
        }

        function getMonthActivities(query) {
            return mnWebSocket.call('states.States.monthly_activities', query);
        }

        function getMonthGraphActivities(query) {
            return mnWebSocket.call('states.States.monthly_activities_graph', query);
        }

        function getYearActivities(query) {
            return mnWebSocket.call('states.States.yearly_activities', query);
        }

        function getPeriodicActivities(query) {
            return mnWebSocket.call('states.States.periodic_activities', query);
        }


        function getInsuredPatients(query) {
            var deferred = $q.defer();

            mnWebSocket.call('states.States.insured_patients', query)
                .then(success, deferred.reject);

            function success(response) {
                var items =  _.map(response, function (item) {
                    if(!item['patient_is_insured']) item['organization_name'] = $translate['instant']("state_patient_non_insured");
                    return item;
                });

                deferred.resolve(items);
            }

            return deferred.promise;
        }

        function getPatientsByAge() {
            return mnWebSocket.call('states.States.patients_by_age', {});
        }

        function getVisitReport(query) {
            return mnWebSocket.call('states.States.visit_report', query);
        }

        function getEntryReport(query) {
            return mnWebSocket.call('states.EntryReport.entry_activity', query);
        }

        function getPatientStatus(query) {
            return mnWebSocket.call('states.States.patient_status', _.pick(query, 'id'));
        }

        function getAgingBalance(query) {
            return mnWebSocket.call("states.States.balance_status", {query})
        }

        function getRecoveries(query) {
            return mnWebSocket.call("states.States.client_recoveries", {query})
        }
        function getPathologies(query) {
            return mnWebSocket.call("states.States.pathologies", {query})
        }
    }

})();