(function () {

    'use strict';

    module.exports = VisitReportCtrl;

    VisitReportCtrl.$inject = ["statesService", "$translate", "$element"];

    function VisitReportCtrl(statesService, $translate, $element) {
        var vm = this;

        var sortAs = $['pivotUtilities'].sortAs;
        var utils = require('../utils/activity-functions');
        var key = $translate['instant']("state_visit_date");

        var days = _.cloneDeep(moment.localeData().weekdays());
        days.push(days.shift());

        vm.$onInit = init;
        vm.getData = _.mnDelay(getData, 400);
        vm.exportToExcel = exportToExcel;

        function init() {
            vm.months = moment.localeData().months();

            vm.query = {
                month: moment().month() + 1,
                year: moment().year()
            };

            getData()
        }

        function getData() {
            vm.promise = statesService.getVisitReport(vm.query)
                .then(success);

            function success(data) {
                vm.visitCount = data['visits'].length;
                vm.appointmentCount = data['appointments'];

                $("#output-visit-report", $element).pivot(data['visits'], {
                    rows: [],
                    cols: _.castArray(key),
                    sorters: sorters,
                    derivedAttributes: keysToShow(),
                });
            }
        }

        function sorters(attr) {
            if(attr == key) return sortAs(days);
        }

        function keysToShow() {
            var keysToShow = {};
            keysToShow[key] = days();
            return keysToShow;

            function days() {
                return function (item) {
                    var visitDate = moment(item["visit_date"]);

                    if (visitDate.isValid()) return visitDate.format('dddd');
                    else return item["visit_date"];
                }
            }
        }

        function exportToExcel(element, title) {
            utils.exportToExcel(
                element,
                title,
                vm.query,
                $translate
            )
        }

    }

})();
