(function () {

    'use strict';

    module.exports = MonthActivityGraphCtrl;

    var Chart = require('chart.js');

    MonthActivityGraphCtrl.$inject = ["statesService", "$element", "$translate"];

    function MonthActivityGraphCtrl(statesService, $element, $translate) {
        var vm = this;

        var countGraph = null;

        vm.$onInit = init;
        vm.getData = _.mnDelay(getData, 400);

        function init() {
            vm.months = moment().localeData().months();

            vm.query = {
                month: moment().month(),
                year: moment().year()
            };

            var context = $("#output-canvas", $element).get(0).getContext("2d");

            countGraph = new Chart(context, {
                type: 'line',
                data: {
                    datasets: [],
                    labels: []
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                }
            });

            getData();
        }

        function getData() {
            vm.promise = statesService.getMonthGraphActivities(vm.query)
                .then(success);

            function success(data) {
                countGraph.data.labels = _.map(data, function (item) {
                    return moment(item.payment_date).format('ll');
                })

                countGraph.data.datasets = [
                    {
                        borderColor: "#00BCD4",
                        data: _.map(data, 'amount'),
                        backgroundColor: "transparent",
                        label: $translate['instant']("states_month_revenue_progress")
                    }
                ]

                countGraph.update();
            }
        }

    }

})();