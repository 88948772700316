
(function () {
    "use strict";

    var dialog = {
        controller: ChargeDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/charge-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
        focusOnOpen: false
    };

    module.exports = dialog;

    ChargeDialogCtrl.$inject = ["$mdDialog", "$scope", "$translate" ];

    function ChargeDialogCtrl($mdDialog, $scope, $translate ) {
        var vm = this;
        vm.submit = submit;
        vm.cancel = cancel;
        vm.removeLine = removeLine;
        vm.onPaginate = onPaginate;
        vm.addLine = addLine;
        vm.getTotal = getTotal;

        init();

        function init() {
            vm.query = {
                limit: 10,
                page: 1,
                order: "-id"
            };
            vm.options = [5, 10, 20, 50];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            };

        }


        function submit() {

                $mdDialog.hide(vm.charges);

        }

        function cancel() {
            $mdDialog.cancel();
        }

        function removeLine(index) {
            vm.charges.splice((vm.query.page - 1) * vm.query.limit + index, 1);
        }

        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            if (vm.pricing.lines.length < ((vm.query.page - 1) * vm.query.limit)) {
                vm.query.page = 1;
            }
        }

        function addLine(ev) {
            vm.charges.push({'name':'', price:0});
        }
        function getTotal(ev) {
            return _.sumBy(vm.charges, 'price', 0)
        }

    }


})();
